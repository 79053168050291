import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 368.1 465' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M344.7,161.2h-8.8v-8.8c0-19.3-3.6-38.2-10.7-56.2V96l-0.4-0.8c-0.2-0.5-0.4-0.9-0.6-1.4c-0.2-0.6-0.4-1.1-0.7-1.7l-0.3-0.8
	c-0.3-0.7-0.6-1.4-1-2.2c-0.3-0.6-0.6-1.3-0.9-2l-0.4-1c-12.9-26.8-33.3-49.1-59-64.4l-0.6-0.4c-3.2-1.9-6.2-3.5-9.1-4.9l-1-0.5
	c-0.5-0.2-1-0.5-1.5-0.7c-1-0.5-2-1-3-1.4l-1.5-0.7c-1.1-0.5-2.2-1-3.3-1.4l-0.9-0.4h-0.5h-0.1c-0.4-0.1-0.7-0.3-1-0.4
	s-0.6-0.2-1-0.4l-0.7-0.3c-0.2-0.1-0.3-0.1-0.4-0.2l-0.5-0.2c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3-0.1-0.7-0.3-1-0.4l-1-0.4l-0.3-0.2
	l-0.7-0.2l-0.2-0.1c-0.2-0.1-0.4-0.2-0.7-0.2l-0.5-0.2c-11-3.7-22.5-6.1-34-7.2l-3.4-0.3h-0.8c-0.5,0-0.9-0.1-1.3-0.1
	c-0.4,0-0.8,0-1.2-0.1L190.4,0l-1,0.2c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9-0.1-1.4-0.1h-6.6c-0.7,0-1.6,0-2.5,0.1h-0.8
	c-1.1,0-2,0.1-2.9,0.2l-4.3,0.3c-11.5,1.1-23,3.5-34,7.2l-0.5,0.2c-0.2,0.1-0.4,0.1-0.7,0.2L135,8.7c-0.5,0.1-1.1,0.3-1.8,0.7
	L133,9.5c-0.3,0.1-0.7,0.2-1,0.4c-0.2,0.1-0.4,0.2-0.6,0.2l-0.2,0.1c-0.4,0.1-0.9,0.3-1.5,0.7l0,0c-0.3,0.1-0.6,0.3-1,0.4
	c-0.1,0-0.2,0.1-0.4,0.1c-0.9,0.1-1.6,0.4-1.9,0.6c-0.7,0.3-1.4,0.6-2,0.9c-0.7,0.3-1.3,0.6-2,0.8l-0.8,0.4
	c-0.4,0.2-0.8,0.4-1.3,0.6c-1,0.5-2.1,1-3.2,1.5l-0.9,0.5c-3.5,1.8-6.5,3.4-9,4.9l-0.7,0.4c-25.6,15.3-46,37.6-59,64.4l-0.4,0.9
	c-0.8,1.6-1.4,2.9-1.9,4.3l-0.3,0.8c-0.5,1.2-1,2.3-1.4,3.4v0.1c0,0.1-0.1,0.2-0.1,0.3v0.1c-7.2,18-10.8,36.9-10.8,56.3v8.8h-9.1
	C10.4,161.2,0,171.7,0,184.6v257C0,454.5,10.5,465,23.4,465h321.2c1.8,0,3.4-0.2,4.8-0.5s2.8-0.8,4.2-1.3l0.2-0.1
	c3.6-1.5,6.7-3.9,9-6.7c3.4-4.2,5.3-9.5,5.3-14.8v-257C368,171.7,357.5,161.2,344.7,161.2z M78.8,161.2v-8.8
	c0-13.4,2.5-26.4,7.3-38.7c0.2-0.3,0.2-0.6,0.3-0.8c2.8-7.1,6.5-13.9,11-20.3l0.4-0.5c0.6-0.9,1.3-1.9,2.1-2.9l0.7-0.9
	c0.5-0.6,0.9-1.2,1.4-1.7l0.7-0.8c0.6-0.7,1.2-1.5,2-2.3c0.2-0.2,0.3-0.4,0.5-0.5l0.4-0.4c0.5-0.6,0.9-1.1,1.4-1.4l1-1.1
	c0.4-0.4,0.7-0.8,1.1-1.2c0.3-0.4,0.7-0.7,1-1.1c0.1-0.1,0.2-0.2,0.3-0.3l0.3-0.3c0.4-0.4,0.9-0.8,1.3-1.2c0.3-0.3,0.5-0.5,0.8-0.8
	l3.1-2.7l0.5-0.5c1.6-1.4,3.4-2.8,5.5-4.3l0.4-0.2C136.8,56.9,154,50.2,172,48.1h0.3c2.3-0.3,4.8-0.4,7.3-0.5h0.9
	c2.5-0.1,4.5-0.1,7.2,0h0.9c2.6,0.1,5,0.3,7.3,0.5l2.6,0.3c16.9,2.2,33,8.7,46.9,18.7l0.4,0.4l0.4,0.2c1.6,1.2,3.5,2.7,5.3,4.2
	l0.6,0.5c0.8,0.7,1.6,1.3,2.3,2.1l0.7,0.6c0.6,0.6,1.2,1.1,1.7,1.6l0.2,0.2c0.3,0.3,0.6,0.6,1.1,1c0.7,0.7,1.3,1.3,1.9,1.9l0.6,0.7
	c0.5,0.6,1,1.2,1.7,1.8c0.3,0.5,0.7,0.8,1,1.1c0.3,0.4,0.6,0.7,0.9,1.1s0.7,0.8,1,1.2l0.4,0.5l0.2,0.3c0.3,0.3,0.5,0.6,0.8,1
	s0.7,0.9,1,1.3l0.3,0.3c0.7,1,1.4,2,2.1,2.9l0.2,0.3l0.2,0.2c4.3,6.1,8,13,11,20.5l0.1,0.2c0,0.1,0.1,0.2,0.1,0.4
	c4.9,12.3,7.4,25.3,7.4,38.9v8.8H78.8V161.2z M46.7,418.2V207.9h274.5v210.3H46.7z M207.9,246.8c-13.4-6.4-28.4-7.2-42.4-2.2
	c-28.9,10.3-44,42-33.8,70.8c4.9,14.1,15.4,25.5,28.9,31.8v30.2c0,12.9,10.5,23.4,23.4,23.4s23.4-10.5,23.4-23.4v-30.2
	c25.9-12,38.6-41.7,28.9-68.8C231.4,264.4,221.3,253.2,207.9,246.8z M184,288.3c4.9,0,8.8,3.9,8.8,8.8s-3.9,8.8-8.8,8.8
	c-4.9,0-8.8-3.9-8.8-8.8S179.1,288.3,184,288.3z'
    />
  </svg>
);

export default SVG;
