import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 90.5072 103.4023' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M90.4501,27.2732c-0.0095-0.064-0.0266-0.1265-0.0386-0.1902c-0.0435-0.233-0.1035-0.4604-0.179-0.683
	c-0.0234-0.0693-0.0442-0.1384-0.0708-0.207c-0.1057-0.2717-0.2336-0.5329-0.3843-0.7815
	c-0.0107-0.0178-0.0161-0.0374-0.0271-0.0551c-0.0215-0.0345-0.0508-0.0607-0.073-0.0945c-0.1411-0.2147-0.2976-0.418-0.4709-0.6094
	c-0.0396-0.044-0.0759-0.0912-0.1169-0.1336c-0.1948-0.2005-0.4084-0.3833-0.6375-0.5504c-0.063-0.0461-0.1287-0.0861-0.1936-0.129
	c-0.0876-0.0579-0.1702-0.1218-0.262-0.1746L47.9174,0.663c-1.5557-0.8916-3.4707-0.8828-5.0176,0.0234L2.4711,24.3857
	c-0.1013,0.0594-0.193,0.1304-0.2891,0.1962c-0.0554,0.0377-0.1116,0.0722-0.1655,0.1122c-0.23,0.1713-0.4432,0.3596-0.6381,0.5648
	c-0.0408,0.043-0.0773,0.0901-0.1166,0.1348c-0.175,0.1979-0.3335,0.4077-0.4746,0.6297c-0.019,0.0301-0.0447,0.0534-0.0632,0.084
	c-0.0081,0.0134-0.0117,0.0281-0.0198,0.0416c-0.1492,0.2512-0.2728,0.5159-0.3762,0.79c-0.0261,0.0692-0.0476,0.1384-0.0706,0.2083
	c-0.0773,0.236-0.1387,0.4774-0.1804,0.7248c-0.0076,0.0459-0.021,0.0909-0.0273,0.1369c-0.0316,0.2271-0.0498,0.4573-0.0498,0.6903
	v46.3525c0,1.7822,0.9492,3.4307,2.4912,4.3252l40.2534,23.3506c0.7754,0.4502,1.6426,0.6748,2.5088,0.6748
	c0.217,0,0.4341-0.0142,0.6497-0.0424c0.1411,0.012,0.2766,0.0424,0.4207,0.0424c1.7505,0,3.2855-0.9026,4.1787-2.2644
	l37.5137-21.761c1.542-0.8945,2.4912-2.543,2.4912-4.3252V28.0019C90.5072,27.7555,90.4853,27.5127,90.4501,27.2732z
	 M45.4545,10.7802l30.3164,17.3989l-9.4424,5.8862L35.9816,16.3334L45.4545,10.7802z M45.2162,47.2265L14.7655,28.7707
	l11.3208-6.6365L56.738,40.044L45.2162,47.2265z M9.9999,37.5765l31.3237,18.9847v33.7812L9.9999,72.1718V37.5765z M51.3236,89.1007
	V55.2038l29.1836-18.1927v35.1606L51.3236,89.1007z'
    />
  </svg>
);

export default SVG;
