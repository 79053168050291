import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '16', height = '16', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M12,16c-2.2056,0-4-1.7944-4-4s1.7944-4,4-4s4,1.7944,4,4S14.2056,16,12,16z M12,10c-1.103,0-2,0.897-2,2s0.897,2,2,2
	s2-0.897,2-2S13.103,10,12,10z M12.0801,24c-1.6543,0-3-1.3457-3-3c-0.0063-0.335-0.1733-0.5688-0.4253-0.6616
	c-0.3037-0.1318-0.585-0.0801-0.7749,0.1064c-0.6187,0.6191-1.3721,0.9316-2.1733,0.9321c-0.0005,0-0.001,0-0.0015,0
	c-0.8013,0-1.5537-0.312-2.1206-0.8779c-1.1714-1.1704-1.1724-3.0737-0.0034-4.2441c0.2412-0.2451,0.292-0.5269,0.1841-0.7715
	c-0.1064-0.2476-0.3398-0.4023-0.5991-0.4033H3c-1.6543,0-3-1.3457-3-3s1.3457-3,3-3c0.3354-0.0059,0.5688-0.1729,0.6616-0.4253
	C3.793,8.3521,3.7422,8.0703,3.5552,7.8799C2.333,6.6587,2.332,4.7554,3.501,3.585c1.1714-1.1719,3.0747-1.1719,4.2441-0.0039
	c0.2456,0.2407,0.5264,0.2915,0.771,0.1841c0.064-0.0278,0.1309-0.0493,0.1987-0.064C8.8906,3.5825,8.999,3.3833,9,3.166V3
	c0-1.6543,1.3457-3,3-3s3,1.3457,3,3c0.001,0.3452,0.1558,0.5786,0.394,0.6812c0.2554,0.1113,0.5361,0.0605,0.7261-0.126
	c1.2217-1.2227,3.1245-1.2236,4.2949-0.0542c1.1719,1.1709,1.1729,3.0742,0.0039,4.2441c-0.2412,0.2456-0.2925,0.5264-0.1846,0.7705
	c0.0283,0.0645,0.0498,0.1313,0.0645,0.1992C20.4175,8.8906,20.6167,8.999,20.834,9H21c1.6543,0,3,1.3457,3,3s-1.3457,3-3,3
	c-0.3452,0.001-0.5786,0.1558-0.6812,0.394c-0.0015,0.0034-0.0029,0.0068-0.0039,0.0098c-0.1079,0.2446-0.0571,0.5259,0.1299,0.7173
	c0.6187,0.6177,0.9312,1.3711,0.9316,2.1724s-0.311,1.5552-0.8774,2.1221c-1.1709,1.1709-3.0742,1.1724-4.2441,0.0034
	c-0.2451-0.2417-0.5269-0.293-0.771-0.1836c-0.2485,0.106-0.4028,0.3394-0.4038,0.5986V21C15.0801,22.6543,13.7344,24,12.0801,24z
	 M8.3467,18.2603c0.3579,0,0.7158,0.0742,1.0566,0.2246c0.9697,0.3545,1.6504,1.3066,1.6763,2.4014
	c0.0005,0.0083,0.0005,0.106,0.0005,0.1138c0,0.5513,0.4487,1,1,1s1-0.4487,1-1v-0.1699c0.0039-1.061,0.6343-2.0132,1.6064-2.4292
	c0.9868-0.4355,2.1343-0.228,2.9131,0.5342c0.457,0.4585,1.0928,0.4585,1.4829,0.0684c0.1904-0.1904,0.2939-0.4419,0.2939-0.709
	s-0.1045-0.5186-0.2935-0.707c-0.8286-0.8457-1.0371-1.9902-0.6006-2.9858c0.4175-0.9683,1.3682-1.5967,2.4233-1.6016
	C21.5513,13,22,12.5513,22,12s-0.4487-1-1-1h-0.1699c-1.0605-0.0039-2.0127-0.6343-2.4292-1.606
	c-0.0361-0.084-0.0605-0.1729-0.0723-0.2637c-0.335-0.9487-0.1069-2.0005,0.6064-2.73c0.4585-0.4585,0.458-1.0933,0.0679-1.4829
	c-0.3906-0.3916-1.0259-0.3911-1.4155-0.0005c-0.8481,0.8301-1.9956,1.0381-2.9912,0.5981C13.6343,5.1025,13.0039,4.1504,13,3.0938
	C13,2.4487,12.5513,2,12,2s-1,0.4487-1,1v0.1699c-0.0039,1.0605-0.6343,2.0127-1.606,2.4292
	c-0.084,0.0361-0.1729,0.0605-0.2637,0.0728c-0.9482,0.3359-2.0005,0.1064-2.73-0.6069c-0.459-0.459-1.0938-0.458-1.4829-0.0679
	c-0.3911,0.3916-0.3911,1.0259-0.001,1.4155c0.8301,0.8462,1.0381,1.9937,0.5986,2.9907c-0.355,0.9702-1.3071,1.6514-2.4023,1.6763
	C3.105,11.0801,3.0078,11.0801,3,11.0801c-0.5513,0-1,0.4487-1,1s0.4487,1,1,1h0.1699c1.0605,0.0039,2.0127,0.6343,2.4292,1.606
	c0.4355,0.9878,0.2275,2.1353-0.5342,2.9136c-0.4585,0.459-0.4585,1.0933-0.0684,1.4829C5.187,19.2729,5.438,19.377,5.7051,19.377
	h0.0005c0.2671-0.0005,0.5181-0.1045,0.707-0.2935C6.9697,18.5371,7.6577,18.2603,8.3467,18.2603z M19.3999,15h0.0098H19.3999z'
    />
  </svg>
);

export default SVG;
