import IconType from './type';

const SVG = ({ style = {}, fill = '#000', width = '16', height = '16', className = '', viewBox = '0 0 512 512' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''} fill`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M100,113c0-11,9-20,20-20h272c11,0,20,9,20,20s-9,20-20,20H120C109,133,100,124,100,113z M412,193c0-11-9-20-20-20H120
	c-11,0-20,9-20,20s9,20,20,20h272C403,213,412,204,412,193z M120,253c-11,0-20,9-20,20s9,20,20,20h92c11,0,20-9,20-20s-9-20-20-20
	H120z M494.4,409.6c23.4,23.4,23.4,61.4,0,84.8C482.7,506.1,467.4,512,452,512s-30.7-5.9-42.4-17.5L300.2,384.9
	c-2.4-2.4-4.2-5.5-5.1-8.8l-22.4-80.7c-1.9-7,0.1-14.6,5.3-19.7c5.2-5.1,12.8-6.9,19.8-4.8l78.7,23.9c3.2,1,6,2.7,8.3,5L494.4,409.6
	z M332.2,360.3l73.5,73.7l28.3-28.3l-73.9-74.1L321,319.7L332.2,360.3z M466.1,437.9l-3.9-3.9L434,462.3l3.9,3.9
	c7.8,7.8,20.5,7.8,28.3,0C473.9,458.3,473.9,445.7,466.1,437.9z M432,0H80C35.9,0,0,35.9,0,80v352c0,44.1,35.9,80,80,80h245
	c11,0,20-9,20-20s-9-20-20-20H80c-22.1,0-40-17.9-40-40V80c0-22.1,17.9-40,40-40h352c22.1,0,40,17.9,40,40v246c0,11,9,20,20,20
	s20-9,20-20V80C512,35.9,476.1,0,432,0z'
    />
  </svg>
);

export default SVG;
