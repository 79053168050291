/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * A helper function to parse the organisationId from the URL.
 */

import dayjs, { Dayjs } from 'dayjs';
import React, { ComponentType, LazyExoticComponent } from 'react';

export const parseOrgGUID = (url: string) => {
  const params = url.split('/');
  return params.length > 1 ? params[1] : '';
};

export const parsePresignedDataFromURL = (url: string) => {
  if (url === undefined || url === '') {
    return '';
  }

  const question = url.indexOf('?');
  return question > 0 ? url.substring(0, question) : '';
};

export const extractFileName = (url: string) => {
  return url ? url.substring(url.lastIndexOf('/') + 1) : undefined;
};

export const b64DecodeUnicode = (str: string) => {
  if (str === '' || str === undefined) {
    return '';
  }

  let decoded = '';

  try {
    decoded = atob(str)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('');
  } catch (error) {
    return error;
  }

  return decodeURIComponent(decoded);
};

export const b64EncodeUnicode = (str: string) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1: number) {
      const str2: unknown = `0x${p1}`;
      return String.fromCharCode(str2 as number);
    }),
  );
};

export const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const convertToHoursAndMinutes = (n: number) => {
  const hours = n / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  return `${rhours}h ${rminutes}m`;
};

export const formatDate = (date: number) => {
  return dayjs(date).format('DD.MM.YYYY');
};

export const unixToDate = (unix: number) => {
  return dayjs.unix(unix).format('DD.MM.YYYY');
};

export const epochToDate = (unix: number) => {
  return dayjs.unix(unix / 1000).format('DD.MM.YYYY');
};

export const base64StringtoFile = (base64String: string, filename: string) => {
  const arr = base64String.split(',');
  const arrMatch = arr[0].match(/:(.*?);/);
  const mime = Array.isArray(arrMatch) && arrMatch.length > 1 ? arrMatch[1] : '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // to be fixed
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const toggleValue = (array: unknown[], value: unknown) => {
  const index = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }

  return array;
};

/**
 * Convert a hex value to RGB.
 * Also works for shorthand hex codes.
 */
export const hexToRgb = (hex: string) => {
  if (hex === undefined || hex === '' || hex === null) return '';
  return hex
    ?.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    ?.substring(1)
    ?.match(/.{2}/g)
    ?.map((x) => parseInt(x, 16))
    ?.join();
};

export const currencyFormatter = (amount: number) => {
  const amountInPounds = amount / 100;
  return amountInPounds.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const adjustColour = (color: string, amount: number) => {
  return `#${color.replace(/^#/, '').replace(/../g, (c) => `0${Math.min(255, Math.max(0, parseInt(c, 16) + amount)).toString(16)}`.substr(-2))}`;
};

export function trimExtraSpace(strings: string[], ...expr: unknown[]) {
  return strings
    .reduce((acc, str, i) => {
      return acc + str + (expr[i] ?? '');
    }, '')
    .replace(/(?:\r\n|\r|\n)/g, ' ')
    .replace(/(?:\s\s+)/g, ' ')
    .replace(/^\s|\s$/g, '');
}

interface LazyComp extends LazyExoticComponent<ComponentType<any>> {
  preload?: () => Promise<{ default: ComponentType<any> }>;
}

export const lazyPreload = (comp: () => Promise<{ default: ComponentType<any> }>) => {
  const LazyComponent: LazyComp = React.lazy(/* webpackPrefetch: true */ comp);
  LazyComponent.preload = comp;
  return LazyComponent;
};

export const emailValidate = (email: string) => {
  return (email.includes('@') && email.includes('.') && email.length > 3 && email.length < 254) ?? false;
};

export const getGreetingTime = (date: Dayjs) => {
  if (!date || !date.isValid()) {
    return '';
  }
  const splitAfternoon = 12;
  const splitEvening = 17;
  const currentHour = parseFloat(date.format('HH'));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    return '👋 Good Afternoon';
  }
  if (currentHour >= splitEvening) {
    return '😴 Good Evening';
  }
  return '☀️ Good Morning';
};

export const CookieAcceptanceData = {
  smallText:
    'By clicking “Accept All Cookies”, you agree to cookies being stored on your device to improve site navigation, provide analytics, and improve our marketing.',
  largeText:
    'Visiting websites may store or retrieve information on your browser, mostly in the form of cookies. This data can be about you, your preferences or your device - most of the time it is used in the functionality of the website you are on. Normally, we cannot directly identify you from this information. At Huler, we respect your right to privacy, you can choose not to allow some types of cookies. Click on the headings below to see more information on each type of cookie, but please remember blocking cookies may impact your experience.',
  image: 'https://i.giphy.com/media/HGe4zsOVo7Jvy/giphy.webp',
  privacyPolicyURL: 'https://huler.io/privacy-policy',
  appName: 'HulerApp',
};

export const lightenDarkenColor = (col: string, amt: number) => {
  const num = parseInt(col, 16);
  const r = (num >> 16) + amt;
  const b = ((num >> 8) & 0x00ff) + amt;
  const g = (num & 0x0000ff) + amt;
  const newColor = g | (b << 8) | (r << 16);
  return newColor.toString(16);
};

export const isUrl = (string: string) => {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(string);
};

export const shortenString = (str: string, maxLength: number) => {
  return str?.length >= maxLength ? `${str.substring(0, maxLength)}...` : str;
};
