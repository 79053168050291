import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 127 127' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M123.99,52H91.26l23.14-23.14c1.18-1.18,1.18-3.09,0-4.26l-12-12c-1.18-1.18-3.09-1.18-4.26,0L75,35.74V3.01
	C75,1.35,73.65,0,71.99,0H55.01C53.35,0,52,1.35,52,3.01v32.72L28.86,12.6c-1.18-1.18-3.09-1.18-4.26,0l-12,12
	c-1.18,1.18-1.18,3.09,0,4.26L35.74,52H3.01C1.35,52,0,53.35,0,55.01v16.97C0,73.65,1.35,75,3.01,75h32.72L12.6,98.14
	c-1.18,1.18-1.18,3.09,0,4.26l12,12c1.18,1.18,3.09,1.18,4.26,0L52,91.26v32.72c0,1.67,1.35,3.01,3.01,3.01h16.97
	c1.66,0,3.01-1.35,3.01-3.01V91.26l23.14,23.14c1.18,1.18,3.09,1.18,4.26,0l12-12c1.18-1.18,1.18-3.09,0-4.26L91.26,75h32.72
	c1.66,0,3.01-1.35,3.01-3.01V55.01C127,53.35,125.65,52,123.99,52z'
    />
  </svg>
);

export default SVG;
