import IconType from './type';

const SVG = ({ fill = '', width = '13', height = '12', className = '', viewBox = '0 0 54 54.01' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Layer_2' data-name='Layer 2' fill={fill}>
      <g id='Layer_1-2' data-name='Layer 1'>
        <path
          className='cls-1'
          fill={fill}
          d='M37.07,16.92A14.07,14.07,0,0,0,27,12.78v4a10.24,10.24,0,1,1,0,20.48v4a14.07,14.07,0,0,0,10.07-4.15A14.26,14.26,0,0,0,37.07,16.92Z'
        />
        <path className='cls-1' d='M39.14,14.86a2,2,0,0,0,2.83,0l4.12-4.12a2,2,0,0,0-2.83-2.83L39.14,12A2,2,0,0,0,39.14,14.86Z' />
        <path className='cls-1' d='M46.09,43.27,42,39.14A2,2,0,0,0,39.14,42l4.12,4.13a2,2,0,0,0,2.83-2.83Z' />
        <path className='cls-1' d='M46.16,29H52a2,2,0,0,0,0-4H46.16a2,2,0,0,0,0,4Z' />
        <path className='cls-1' d='M27,54V44.17a2,2,0,0,1,2,2V52A2,2,0,0,1,27,54Z' />
        <path className='cls-1' d='M27,9.84V0a2,2,0,0,1,2,2V7.84A2,2,0,0,1,27,9.84Z' />
        <polyline className='cls-2' points='27 54.01 27 44.17 27 41.22 27 37.24 27 16.76 27 12.79 27 9.84 27 0' />
        <path className='cls-1' d='M27,16.76v-4a14.11,14.11,0,0,0-10.08,4.14,14.27,14.27,0,0,0,0,20.15A14.11,14.11,0,0,0,27,41.22v-4' />
        <path className='cls-1' d='M14.86,14.86a2,2,0,0,1-2.83,0L7.91,10.74a2,2,0,1,1,2.83-2.83L14.86,12A2,2,0,0,1,14.86,14.86Z' />
        <path className='cls-1' d='M7.91,43.27,12,39.14A2,2,0,1,1,14.86,42L10.73,46.1a2,2,0,0,1-2.82-2.83Z' />
        <path className='cls-1' d='M7.83,29H2a2,2,0,0,1,0-4H7.83a2,2,0,0,1,0,4Z' />
        <path className='cls-1' d='M27,54V44.17a2,2,0,0,0-2,2V52A2,2,0,0,0,27,54Z' />
        <path className='cls-1' d='M27,9.84V0a2,2,0,0,0-2,2V7.84A2,2,0,0,0,27,9.84Z' />
        <polyline className='cls-2' points='27 0 27 9.84 27 12.79 27 16.76 27 37.24 27 41.22 27 44.17 27 54.01' />
      </g>
    </g>
  </svg>
);

export default SVG;
