import styled from 'styled-components';

const SearchResultsPanelTitle = styled.div`
  display: flex;
  font-size: 23px;
  color: var(--foreground-color);
  margin-bottom: 30px;
  align-items: center;
  font-weight: 300;

  > svg {
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    opacity: 0.6;
    fill: var(--contrast);

    * {
      stroke: currentColor;
    }
  }

  > span {
    padding-right: 0.5rem;

    b {
      font-weight: bold;
    }
  }
`;

export default SearchResultsPanelTitle;
