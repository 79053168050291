import { Suspense, lazy, useState, useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Helmet } from 'react-helmet-async';
import { useAuth } from 'contexts/AuthContext';
import { OrgProvider } from 'contexts/OrgContext';
import CookieContextProvider from 'contexts/CookieContext';
import Loader from 'components/UI/Loader/Loader';
import ThemeWrapper from 'components/ThemeWrapper';
import GlobalStyles from 'theming/GlobalStyles/GlobalStyles';

const UnAuthenticatedApp = lazy(() => import('./UnAuthenticatedApp'));
const AuthenticatedApp = lazy(/* webpackPrefetch: true */ () => import('./AuthenticatedApp'));

const DelayedFallback = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  return <>{show && <Loader />}</>;
};

const App = () => {
  const { user } = useAuth();

  // Let's give this user a simple id that we can identify them by
  useEffect(() => {
    const generateFingerprint = async () => {
      const fpAgent = await FingerprintJS.load();
      const result = await fpAgent.get();
      const { visitorId } = result;
      return visitorId;
    };

    const checkBrowserIdentity = async () => {
      if (localStorage) {
        let browserFingerprint = localStorage.getItem('@browser-fingerprint');
        if (!browserFingerprint) {
          browserFingerprint = await generateFingerprint();
          localStorage.setItem('@browser-fingerprint', browserFingerprint);
        }
      }
    };

    checkBrowserIdentity();
  }, []);

  return (
    <Suspense fallback={<DelayedFallback />}>
      <ThemeWrapper>
        <Helmet>
          <link rel='stylesheet' href='https://use.typekit.net/oxe5smk.css' />
        </Helmet>
        <GlobalStyles />
        <CookieContextProvider>
          {user ? (
            <OrgProvider>
              <AuthenticatedApp />
            </OrgProvider>
          ) : (
            <UnAuthenticatedApp />
          )}
        </CookieContextProvider>
      </ThemeWrapper>
    </Suspense>
  );
};

export default App;
