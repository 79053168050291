import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '12', className = '', viewBox = '0 0 32.81 35.83' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M7.97,12.57c0.01,0,0.02,0,0.03-0.01l3.01-0.63c0.25-0.05,0.51,0,0.73,0.14c0.22,0.14,0.37,0.36,0.42,0.62s0,0.51-0.14,0.73
	c-0.14,0.22-0.36,0.37-0.62,0.42L8.4,14.46c-0.53,0.11-1.04-0.23-1.15-0.76C7.14,13.19,7.46,12.69,7.97,12.57z M14.44,3.76
	c7.96,0,14.44,6.48,14.44,14.44c0,2.46-0.64,4.89-1.84,7.04l-0.03,0.05l0.93,1.32c0.64,0.9,0.89,2,0.7,3.08l-0.34,1.99
	c-0.22,1.31-0.95,2.46-2.04,3.23c-0.85,0.6-1.84,0.92-2.88,0.92c-0.28,0-0.57-0.02-0.85-0.07l-4.66-0.8
	c-0.52-0.09-0.98-0.38-1.28-0.81c-0.31-0.43-0.43-0.96-0.34-1.48c0.01-0.03,0.01-0.04,0.01-0.05l0.02-0.12l-0.12,0.02
	c-0.66,0.08-1.21,0.12-1.74,0.12C6.48,32.63,0,26.16,0,18.19C0,10.23,6.48,3.76,14.44,3.76z M19.84,26.04
	c0.38,0.06,0.63,0.42,0.57,0.8c-0.06,0.38-0.42,0.63-0.8,0.56l-2.12-0.36c-0.33-0.06-0.64,0.16-0.69,0.49
	c-0.06,0.33,0.17,0.64,0.49,0.69l2.12,0.36c0.18,0.03,0.34,0.13,0.45,0.28c0.11,0.15,0.15,0.33,0.12,0.52
	c-0.06,0.37-0.42,0.63-0.8,0.56l-1.27-0.22c-0.16-0.03-0.32,0.01-0.45,0.1c-0.13,0.09-0.22,0.23-0.24,0.39
	c-0.06,0.33,0.17,0.64,0.49,0.69l1.27,0.22c0.38,0.06,0.63,0.42,0.56,0.8c-0.03,0.18-0.13,0.34-0.28,0.45
	c-0.15,0.11-0.34,0.15-0.51,0.12l-0.42-0.07c-0.16-0.03-0.32,0.01-0.45,0.1c-0.13,0.09-0.22,0.23-0.25,0.39
	c-0.03,0.16,0.01,0.32,0.1,0.45c0.09,0.13,0.23,0.22,0.39,0.24l4.66,0.8c0.21,0.04,0.41,0.05,0.61,0.05c0.74,0,1.46-0.23,2.08-0.67
	c0.79-0.56,1.31-1.39,1.47-2.34l0.34-1.99c0.12-0.72-0.04-1.45-0.47-2.05l-5.01-7.07c-0.06-0.08-0.16-0.09-0.24-0.04
	c-0.24,0.17-0.41,0.43-0.46,0.73c-0.04,0.24,0,0.49,0.11,0.7l1.25,2.43c0.12,0.23,0.1,0.52-0.06,0.73
	c-0.16,0.21-0.41,0.31-0.67,0.27l-8.05-1.37c-0.33-0.06-0.64,0.16-0.69,0.49c-0.06,0.33,0.17,0.64,0.49,0.69L19.84,26.04z
	 M14.44,31.06c0.43,0,0.87-0.03,1.39-0.09l0.11-0.01l-0.03-0.1c-0.09-0.31-0.11-0.6-0.06-0.87c0.04-0.26,0.14-0.5,0.28-0.72
	l0.04-0.07l-0.06-0.05c-0.53-0.45-0.79-1.16-0.67-1.85c0.03-0.16,0.08-0.32,0.14-0.47l0.05-0.11l-2.37-0.41
	c-0.52-0.09-0.98-0.38-1.28-0.81c-0.31-0.43-0.43-0.96-0.34-1.48c0.18-1.08,1.21-1.8,2.29-1.62l6.69,1.14l-0.62-1.2
	c-0.57-1.1-0.23-2.47,0.78-3.19c0.34-0.24,0.75-0.33,1.16-0.26c0.41,0.07,0.77,0.29,1,0.63l3.05,4.31l0.07-0.14
	c0.83-1.74,1.25-3.59,1.25-5.51c0-7.09-5.77-12.86-12.86-12.86c-7.09,0-12.86,5.77-12.86,12.86C1.58,25.29,7.35,31.06,14.44,31.06z
	 M23.06,11.12c0.22-0.18,0.34-0.45,0.33-0.73c-0.01-0.28-0.14-0.53-0.36-0.69c-0.72-0.53-1.9-1.16-3.44-1.23
	c-0.08,0-0.17-0.01-0.26-0.01c-0.28,0-0.54,0.12-0.72,0.33c-0.17,0.2-0.24,0.47-0.2,0.75l0,0c0.08,0.43,0.46,0.74,0.91,0.75
	c1.2,0.01,2.09,0.48,2.63,0.88C22.28,11.41,22.74,11.39,23.06,11.12z M21.83,0.77L21.79,2.9c-0.01,0.43,0.34,0.79,0.77,0.8
	c0.43,0.01,0.79-0.34,0.8-0.77L23.4,0.8c0.01-0.43-0.34-0.79-0.77-0.8C22.19-0.01,21.84,0.34,21.83,0.77z M29.96,3.12
	c-0.3-0.31-0.8-0.32-1.11-0.02l-1.58,1.53c-0.31,0.3-0.32,0.8-0.02,1.11c0.3,0.31,0.8,0.32,1.11,0.02l1.58-1.53
	C30.25,3.93,30.26,3.43,29.96,3.12z M32.59,10c-0.15-0.15-0.34-0.24-0.55-0.24l-2.13-0.04c-0.43-0.01-0.79,0.34-0.8,0.77
	c-0.01,0.43,0.34,0.79,0.77,0.8l2.13,0.04c0.43,0.01,0.79-0.34,0.8-0.77C32.82,10.34,32.74,10.15,32.59,10z M11.72,16.87
	c0-0.56-0.45-1.01-1.01-1.01c-0.56,0-1.01,0.45-1.01,1.01s0.45,1.01,1.01,1.01C11.27,17.88,11.72,17.43,11.72,16.87z M21.16,15.86
	c0-0.56-0.45-1.01-1.01-1.01s-1.01,0.45-1.01,1.01c0,0.56,0.45,1.01,1.01,1.01S21.16,16.42,21.16,15.86z M15.39,21.88
	c0.6-0.57,1.32-1.12,1.75-1.34c0.11,0.26,0.37,0.45,0.67,0.46c0.38-0.01,0.74-0.32,0.74-0.72c0.01-0.8-0.42-1.1-0.67-1.21
	c-0.76-0.33-1.93,0.27-3.5,1.77c-0.29,0.28-0.3,0.74-0.02,1.03c0.14,0.15,0.34,0.23,0.53,0.23C15.06,22.09,15.25,22.02,15.39,21.88z
	'
    />
  </svg>
);

export default SVG;
