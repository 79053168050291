import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 300.2 422.9' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M300.2,150.1C300.2,67.3,232.9,0,150.1,0S0,67.3,0,150.1c0,67.7,48.9,164.1,119.9,186.3l-24.4,32h40.9v40.9
	c0,7.5,6.1,13.6,13.6,13.6s13.6-6.1,13.6-13.6v-40.9h40.9l-24.4-32C251.4,314.2,300.2,217.8,300.2,150.1z M150.1,313.8
	c-65,0-122.8-91.6-122.8-163.7c0-67.7,55.1-122.8,122.8-122.8s122.8,55.1,122.8,122.8C272.9,222.3,215.1,313.8,150.1,313.8z'
    />
  </svg>
);

export default SVG;
