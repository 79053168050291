import styled from 'styled-components';
import binoculars from '../../../assets/img/search/binoculars.png';
import sphereTp from '../../../assets/img/search/sphere_1_tp.png';
import sphere1TpRotate from '../../../assets/img/search/sphere_1_tp_rotate.png';
import binocularsLight from '../../../assets/img/search/binoculars_light.png';
import searchPoly from '../../../assets/img/search/poly.png';

interface SearchResultsPageEmptyBubblesProps {
  className?: string;
}

const SearchResultsPageEmptyBubbles = ({ className }: SearchResultsPageEmptyBubblesProps) => (
  <div className={className}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <span />
    <span />
    <aside />
    <aside />
    <figure />
  </div>
);

const StyledSearchResultsPageEmptyBubbles = styled(SearchResultsPageEmptyBubbles)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    opacity: 0.5;
  }
  div {
    background-size: cover;
    position: absolute;
    animation: float 8s ease-in-out infinite;
    background-color: rgba(black, 0.1);
    border-radius: 100%;
    filter: contrast(3);
    @media (prefers-reduced-motion) {
      animation: none !important;
    }
    &:before,
    &:after {
      display: none;
    }
    &:first-of-type {
      left: 50%;
      top: 5%;
      width: 120px;
      height: 120px;
      z-index: 2;
      background-image: url(${sphereTp});
      opacity: 0.6;
      @media (max-width: ${(props) => props.theme.breakpoints.small}) {
        display: none;
      }
      &:before {
        content: '1';
      }
    }
    &:nth-of-type(2) {
      background-color: var(--background-color-inverse);
      opacity: 0.1;
      width: 65px;
      height: 65px;
      left: calc(50% + 100px);
      top: 10%;
      animation-duration: 12s;
      z-index: 1;
      &:after {
        position: absolute;
        content: '2';
        right: -20px;
      }
    }
    &:nth-of-type(3) {
      left: 35%;
      top: 16%;
      width: 150px;
      height: 150px;
      animation-duration: 10s;
      background-image: url(${sphere1TpRotate});
      opacity: 0.2;
      &:before {
        content: '3';
      }
    }
    &:nth-of-type(4) {
      right: 10%;
      bottom: 5%;
      width: 100px;
      height: 100px;
      z-index: 2;
      border-radius: 100%;
      backdrop-filter: blur(3px);
      background-image: url(${sphere1TpRotate});
      animation-duration: 6s;
      opacity: 0.4;
      @media (max-width: ${(props) => props.theme.breakpoints.small}) {
        display: none;
      }
      &:before {
        content: '4';
      }
    }
    &:nth-of-type(5) {
      left: 25%;
      bottom: -100px;
      width: 180px;
      height: 180px;
      z-index: 2;
      border-radius: 100%;
      backdrop-filter: blur(3px);
      background-image: url(${sphere1TpRotate});
      animation-duration: 10s;
      opacity: 0.5;
      @media (max-width: ${(props) => props.theme.breakpoints.small}) {
        display: none;
      }
      &:before {
        content: '5';
      }
    }
    &:nth-of-type(6) {
      left: -150px;
      top: 30%;
      width: 220px;
      height: 220px;
      z-index: 2;
      border-radius: 100%;
      backdrop-filter: blur(3px);
      animation-duration: 11s;
      background-image: url(${sphereTp});
      opacity: 0.7;
    }
  }

  span {
    border: 10px solid white;
    position: absolute;
    border-radius: 100%;
    opacity: 0.07;
    animation: float-2 6.5s ease-in-out infinite;
    @media (prefers-reduced-motion) {
      animation: none !important;
    }
    .theme--light & {
      border-color: black;
    }
    &:first-of-type {
      width: 105px;
      height: 80px;
      bottom: 15%;
      left: 5%;
      border-width: 8px;
    }
    &:nth-of-type(2) {
      width: 140px;
      height: 110px;
      top: 40%;
      right: 5%;
      border-width: 11px;
    }
  }

  aside {
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    animation: float 6s ease-in-out infinite;
    background-image: url(${searchPoly});
    width: 30px;
    height: 30px;
    @media (prefers-reduced-motion) {
      animation: none !important;
    }
    &:first-of-type {
      left: 41%;
      top: 28%;
      animation-duration: 6s;
    }
    &:nth-of-type(2) {
      right: 35%;
      bottom: 10%;
      animation-duration: 8s;
    }
  }

  figure {
    position: absolute;
    right: 15%;
    width: 40vw;
    height: 40vw;
    max-width: 650px;
    max-height: 650px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(${binoculars});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    z-index: 2;
    @media (max-width: ${(props) => props.theme.breakpoints.xLarge}) {
      width: 50vh;
      height: 50vh;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.large}) {
      bottom: 12%;
      top: auto;
      right: 5%;
      transform: none;
      width: 40vh;
      height: 40vh;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.small}) {
      width: 30vh;
      height: 30vh;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      bottom: 2%;
    }
    .theme--light & {
      background-image: url(${binocularsLight});
    }
  }
`;

export default StyledSearchResultsPageEmptyBubbles;
