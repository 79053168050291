import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 20 22' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M17,22H3a3,3,0,0,1-3-3V8a1,1,0,0,1,.39-.79l9-7a1,1,0,0,1,1.22,0l9,7A1,1,0,0,1,20,8V19A3,3,0,0,1,17,22ZM2,8.49V19a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V8.49L10,2.27Z'
    />
  </svg>
);

export default SVG;
