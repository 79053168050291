/**
 * Draws the main body of the search result page and dewals with the search logic.
 */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { performSearch, Hit } from '../../api/wrappers/SearchAPI';
import SearchResultsPage from './SearchResultsPage';
import Loader from '../../components/UI/Loader/Loader';
import SearchResultsBanner from '../../components/Search/SearchResultsBanner';
import { SearchHandleType } from '../../components/Search/searchMethods';

const SearchPhaseInProgress = 'in-progress';
const SearchPhaseFinished = 'search-finished';

interface SearchResultsProps {
  className?: string;
  searchQuery: string;
  searchHandle: SearchHandleType;
}

interface SearchResultsInterface {
  phase: string;
  results: Hit[];
}

// Displays the results of the current search...
const SearchResults = ({ className, searchQuery, searchHandle }: SearchResultsProps) => {
  const [searchResults, setSearchResults] = useState<SearchResultsInterface>({
    phase: SearchPhaseInProgress,
    results: [],
  });

  const searchCallBack = (data: Hit[]) => {
    setSearchResults({
      phase: SearchPhaseFinished,
      results: data,
    });
  };

  useEffect(() => {
    if (!searchQuery) return;
    performSearch(searchQuery, searchCallBack);
  }, [searchQuery]);

  return (
    <div className={className}>
      {searchResults.phase === SearchPhaseInProgress && <Loader />}
      {searchResults.phase === SearchPhaseFinished && (
        <SearchResultsPage query={searchQuery} hits={searchResults.results} searchHandle={searchHandle} />
      )}
      <SearchResultsBanner>search</SearchResultsBanner>
    </div>
  );
};

const StyledSearchResults = styled(SearchResults)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: var(--background-color);
  z-index: 400;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.globalDimensions.headerHeight} 0 ${(props) => props.theme.globalDimensions.footerHeight};
  overscroll-behavior: contain;

  ${Loader} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 70px 0 150px 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    padding-left: 0;
    padding-right: 0;
    padding-top: ${(props) => props.theme.globalDimensions.headerHeight};
  }
`;

export default StyledSearchResults;
