import { Amplify } from '@aws-amplify/core';
import Api from '@aws-amplify/api-rest';

import { parseOrgGUID } from '../helpers/Utils';

window.hulerSphere = {
  vision: process.env.REACT_APP_VISION_BASE_URL ?? 'http://localhost:3000',
};

const apiEndpoints = (organisationId) => ({
  API: {
    endpoints: [
      {
        name: 'CrazyDiamondAPI',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
      },
      {
        name: 'CrazyDiamondTileAPIEditTile',
        endpoint: `${process.env.REACT_APP_API_ENDPOINT}tile/edittile/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondLoadTileAPI',
        endpoint: `${process.env.REACT_APP_API_ENDPOINT}/tile/loadtile/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondViewMyTilesAPI',
        endpoint: `${process.env.REACT_APP_API_ENDPOINT}/tile/viewmytiles/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondTileOrderAPI',
        endpoint: `${process.env.REACT_APP_API_ENDPOINT}/tile/addtileorder/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondTileDeleteAPI',
        endpoint: `${process.env.REACT_APP_API_ENDPOINT}tile/deletetile/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondPresignAPI',
        endpoint: `${process.env.REACT_APP_API_ENDPOINT}/presign/${organisationId}/`,
      },
      {
        name: 'ScrapeAPI',
        endpoint: `${process.env.REACT_APP_API_ENDPOINT}/scrape/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondScratchVideoAPI',
        endpoint: process.env.REACT_APP_SCRATCH_VIDEO_ENDPOINT,
      },
      {
        name: 'CrazyDiamondVisionAPI',
        endpoint: process.env.REACT_APP_VISION_ENDPOINT,
      },
      {
        name: 'CrazyDiamondSearchAPI',
        endpoint: `${process.env.REACT_APP_SEARCH_ENDPOINT}/search/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondAudienceAPI',
        endpoint: `${process.env.REACT_APP_AUDIENCE_ENDPOINT}/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondAudienceAPIOrgID',
        endpoint: `${process.env.REACT_APP_AUDIENCE_ENDPOINT}/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondOrganisationAdminAPI',
        endpoint: `${process.env.REACT_APP_ORGANISATION_ADMIN_ENDPOINT}/${organisationId}/`,
      },
      {
        name: 'CrazyDiamondProfileAPI',
        endpoint: `${process.env.REACT_APP_PROFILE_ENDPOINT}/${organisationId}/`,
      },
      {
        name: 'ReportingAPI',
        endpoint: process.env.REACT_APP_REPORTING_ENDPOINT,
      },
      {
        name: 'UsersAPI',
        endpoint: process.env.REACT_APP_USERS_ENDPOINT,
      },
      // TODO: We need to move the whole USERS API to the new way, but for now I need this one endpoint for terms and conditions
      {
        name: 'UsersAPITandC',
        endpoint: `${process.env.REACT_APP_USERS_ENDPOINT}/users/${organisationId}/`,
      },
      {
        name: 'OrganisationAPINOORG',
        endpoint: `${process.env.REACT_APP_ORGANISATION_ENDPOINT}organisation/`,
      },
      {
        name: 'OrganisationAPI',
        endpoint: `${process.env.REACT_APP_ORGANISATION_ENDPOINT}organisation/${organisationId}/`,
      },
      {
        name: 'NotificationsAPI',
        endpoint: `${process.env.REACT_APP_NOTIFICATIONS_ENDPOINT}notifications/${organisationId}/`,
      },
      {
        name: 'ProgressAPI',
        endpoint: process.env.REACT_APP_PROGRESS_ENDPOINT,
      },
      {
        name: 'ReactionsAPI',
        endpoint: process.env.REACT_APP_REACTIONS_ENDPOINT,
      },
      {
        name: 'TickerAPI',
        endpoint: `${process.env.REACT_APP_TICKER_ENDPOINT}${organisationId}/`,
      },
      {
        name: 'TandCAPI',
        endpoint: `${process.env.REACT_APP_TANDC_ENDPOINT}${organisationId}/`,
      },
      {
        name: 'LibraryAPI',
        endpoint: `${process.env.REACT_APP_LIBRARY_ENDPOINT}${organisationId}/`,
      },
      {
        name: 'UserUploadAPI',
        endpoint: `${process.env.REACT_APP_USER_UPLOAD_ENDPOINT}${organisationId}/`,
      },
      {
        name: 'ConfigAPI',
        endpoint: `${process.env.REACT_APP_CONFIG_ENDPOINT_AUTHENTICATED}/${organisationId}/authenticatedparameterservice?organisationId=${organisationId}`,
      },
      {
        name: 'ProductAPI',
        endpoint: `${process.env.REACT_APP_PRODUCT_ENDPOINT}${organisationId}/`,
      },
      {
        name: 'BillingAPI',
        endpoint: `${process.env.REACT_APP_BILLING_ENDPOINT}${organisationId}/`,
      },
      {
        name: 'KudosAPI',
        endpoint: `${process.env.REACT_APP_KUDOS_ENDPOINT}${organisationId}/`,
      },
    ],
  },
});

const bootstrapConfig = async (selectedOrg) => {
  let org = selectedOrg;
  const guid = parseOrgGUID(window.location.pathname);
  if (!org) {
    org = guid.replace('join', '');
  }

  const config = {
    method: 'GET',
    url: `${process.env.REACT_APP_CONFIG_ENDPOINT}?organisationId=${org}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const api = {
    API: {
      endpoints: [
        {
          name: 'OrganisationAPINOORG',
          endpoint: `${process.env.REACT_APP_ORGANISATION_ENDPOINT}organisation/`,
        },
        {
          name: 'OrganisationAPI',
          endpoint: `${process.env.REACT_APP_ORGANISATION_ENDPOINT}organisation/`,
        },
      ],
    },
  };

  Api.configure(api);

  try {
    if (org) {
      const response = await fetch(config.url, config);
      const res = await response.json();
      if (!res.OrganisationId) {
        return {};
      }
      const apiConfig = apiEndpoints(res.OrganisationId);
      const oauth =
        res.SSO && res.SSO !== ''
          ? {
              domain: `${res.Auth.userPoolDomain}.auth.${res.Auth.region}.amazoncognito.com`,
              scope: ['email', 'profile', 'openid'],
              redirectSignIn: `${process.env.REACT_APP_BASE_URL}/${guid}/hub`,
              redirectSignOut: `${process.env.REACT_APP_BASE_URL}/${guid}/logout`,
              responseType: 'code',
              provider: res.SSO,
            }
          : null;

      const amplifyConfig = {
        Auth: {
          ...res.Auth,
          oauth,
        },
        Storage: {
          AWSS3: {
            bucket: process.env.REACT_APP_RESOURCES_BUCKET,
            region: 'eu-west-1',
            identityPoolId: `${res.Auth?.identityPoolId}`,
            customPrefix: {
              public: `public/${res.OrganisationId}/`,
              private: `private/${res.OrganisationId}/`,
            },
          },
        },
      };

      Amplify.configure(amplifyConfig);
      Api.configure(apiConfig);

      return {
        organisationId: res.OrganisationId,
        SSO: oauth,
        Active: res.Active,
      };
    }
    return {};
  } catch (e) {
    return e;
  }
};

export default bootstrapConfig;
