import { css } from 'styled-components';

const backgroundCover = css`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const backgroundContain = css`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const verticalCenter = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const lineUnderAnimation = css`
  @keyframes line-under-animation-in {
    0% {
      width: 0;
      left: 0;
      right: initial;
    }

    99% {
      width: 100%;
      left: 0;
      right: initial;
    }

    100% {
      width: 100%;
      left: initial;
      right: 0;
    }
  }

  @keyframes line-under-animation-out {
    0% {
      width: 100%;
      left: initial;
      right: 0;
    }

    99% {
      width: 0;
      right: 0;
      left: initial;
      opacity: 1;
    }

    100% {
      opacity: 0;
      width: 0;
      right: initial;
      left: 0;
    }
  }

  position: relative;
  padding-bottom: 2px;
  transition: 0.5s ease;

  &::before {
    content: '';
    width: 0;
    height: 2px;
    background-color: rgba(var(--primary-color), 1);
    position: absolute;
    bottom: -2px;
    z-index: -1;
    left: 0;
    right: initial;
    animation: line-under-animation-out 0.5s ease forwards;
  }

  &:hover::before,
  &.active::before {
    animation: line-under-animation-in 0.5s ease forwards;
  }
`;

const gradientOne = css`
  background: rgb(255, 175, 74);
  background: linear-gradient(150deg, #ffaf4a 0%, #fb6664 100%);
`;

const gradientTwo = css`
  background: rgb(255, 175, 74);
  background: linear-gradient(90deg, rgb(190, 76, 30) 0%, rgb(230, 195, 43) 100%);
`;

const gradientThree = css`
  background: rgb(255, 175, 74);
  background: linear-gradient(180deg, rgb(245, 123, 42) 0%, rgb(255, 80, 88) 100%);
`;

const gradientFour = css`
  background: rgb(255, 175, 74);
  background: linear-gradient(200deg, rgb(226, 208, 102) 0%, rgb(216, 139, 38) 100%);
`;

const scrollStyling = css`
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--foreground-color-2);
    border-radius: 0px;
  }
  //Firefox
  scrollbar-width: thin;
  scrollbar-color: var(--foreground-color-2) transparent;
`;

const offsetShadow = css`
  z-index: -1;
  content: '';
  width: 80%;
  height: 30%;
  background: var(--tile-shadow-color);
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0.5;
  filter: blur(40px);
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    display: none;
  }
`;

const landingCardShadow = css`
  box-shadow: -2.2px -1.3px 5px 0 #ffffff, 0px 4px 8px 0 rgba(41, 41, 48, 0.27);
`;

const kudosCardShadow = css`
  &:after {
    content: '';
    background: black;
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: -10px;
    left: 0;
    filter: blur(20px);
    opacity: 0.3;
    z-index: 1;
    transition: ${(props) => props.theme.transitions.default};
  }
`;

const pageBanner = css`
  position: fixed;
  user-select: none;
  bottom: ${(props) => props.theme.globalDimensions.footerHeight};
  color: var(--background-title);
  width: auto;
  white-space: nowrap;
  font-size: 25vw;
  font-weight: 800;
  line-height: 0.8;
  transition: opacity ${(props) => props.theme.transitions.default}, color ${(props) => props.theme.transitions.default};
`;

const filters = css`
  &:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    content: '';
    z-index: 1;
  }
  // colours
  &.lighten:before {
    background: rgba(255, 255, 255, 0.3);
  }
  &.darken:before {
    background: rgba(0, 0, 0, 0.55);
  }

  // Overlay
  &.overlay1:before {
    mix-blend-mode: overlay;
    background: rgba(117, 198, 209, 0.6);
  }
  &.overlay2:before {
    mix-blend-mode: overlay;
    background: rgba(246, 145, 134, 1);
  }
  &.overlay3:before {
    mix-blend-mode: overlay;
    background: rgba(246, 223, 134, 0.6);
  }

  // Color
  &.color1:before {
    mix-blend-mode: color;
    background: rgba(117, 198, 209, 0.6);
  }
  &.color2:before {
    mix-blend-mode: color;
    background: rgba(246, 145, 134, 0.6);
  }
  &.color3:before {
    mix-blend-mode: color;
    background: rgba(246, 223, 134, 0.6);
  }

  // exclusion
  &.exclusion1:before {
    background: rgba(117, 198, 209, 0.6);
    opacity: 0.8;
    mix-blend-mode: exclusion;
  }
  &.exclusion2:before {
    background: rgba(246, 145, 134, 0.6);
    opacity: 0.8;
    mix-blend-mode: exclusion;
  }
  &.exclusion3:before {
    background: rgba(246, 223, 134, 0.6);
    opacity: 0.8;
    mix-blend-mode: exclusion;
  }

  // textures
  &.texture1:before {
    background-image: url('../../img/filters/texture1.jpg');
    mix-blend-mode: overlay;
  }
  &.texture2:before {
    background-image: url('../../img/filters/texture2.jpg');
    mix-blend-mode: overlay;
  }
  &.texture3:before {
    background-image: url('../../img/filters/texture3.jpg');
    mix-blend-mode: overlay;
    opacity: 0.6;
  }
`;

export {
  backgroundCover,
  backgroundContain,
  verticalCenter,
  lineUnderAnimation,
  gradientOne,
  gradientTwo,
  gradientThree,
  gradientFour,
  scrollStyling,
  offsetShadow,
  landingCardShadow,
  pageBanner,
  filters,
  kudosCardShadow,
};
