import IconType from './type';

const SVG = ({ style = {}, fill = '#000', width = '156', height = '144', className = '', viewBox = '0 0 98 71' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_2-2' data-name='Layer 2'>
        <path
          fill={fill}
          d='M86,0H12A12,12,0,0,0,0,12V59A12,12,0,0,0,12,71H86A12,12,0,0,0,98,59V12A12,12,0,0,0,86,0Zm8,59a8,8,0,0,1-8,8H12a8,8,0,0,1-8-8V12a8,8,0,0,1,8-8H86a8,8,0,0,1,8,8Z'
        />
        <path
          fill={fill}
          d='M83,9H15a6,6,0,0,0-6,6V56a6,6,0,0,0,6,6H83a6,6,0,0,0,6-6V15A6,6,0,0,0,83,9Zm4,47a4,4,0,0,1-4,4H15a4,4,0,0,1-4-4V15a4,4,0,0,1,4-4H83a4,4,0,0,1,4,4Z'
        />
        <path fill={fill} d='M49,24A12,12,0,1,0,61,36,12,12,0,0,0,49,24Zm0,22A10,10,0,1,1,59,36,10,10,0,0,1,49,46Z' />
        <circle fill={fill} cx='49' cy='36' r='6' />
      </g>
    </g>
  </svg>
);

export default SVG;
