import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '16', height = '16', className = '', viewBox = '0 0 20 19.9676' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M8.9393,8.9393H0V0h8.9393V8.9393z M1.8462,7.0931h5.247v-5.247h-5.247V7.0931z M20,8.9555h-8.9393V0.0162H20V8.9555z
	 M12.9069,7.1093h5.247v-5.247h-5.247V7.1093z M8.9393,19.9514H0v-8.9393h8.9393V19.9514z M1.8462,18.1053h5.247v-5.247h-5.247
	V18.1053z M20,19.9676h-8.9393v-8.9393H20V19.9676z M12.9069,18.1215h5.247v-5.247h-5.247V18.1215z'
    />
  </svg>
);

export default SVG;
