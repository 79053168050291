import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '13', className = '', viewBox = '0 0 24.469 22.04' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g transform='translate(0 0.028)'>
      <g transform='translate(0 -0.026)'>
        <path d='M1.169,22.038A1.167,1.167,0,0,1,0,20.87V1.169a1.169,1.169,0,0,1,2.337,0V20.862A1.169,1.169,0,0,1,1.169,22.038Z' />
      </g>
      <g transform='translate(6.248 -0.026)'>
        <path
          d='M81.369,22.038A1.167,1.167,0,0,1,80.2,20.87V1.169a1.169,1.169,0,1,1,2.337,0V20.862A1.174,1.174,0,0,1,81.369,22.038Z'
          transform='translate(-80.2)'
        />
      </g>
      <g transform='translate(12.495 -0.026)'>
        <path
          d='M161.569,22.038A1.167,1.167,0,0,1,160.4,20.87V1.169a1.169,1.169,0,1,1,2.337,0V20.862A1.174,1.174,0,0,1,161.569,22.038Z'
          transform='translate(-160.4)'
        />
      </g>
      <g transform='translate(17.613 -0.028)'>
        <path
          d='M231.776,22.012a1.176,1.176,0,0,1-1.137-.927l-4.51-19.639A1.2,1.2,0,0,1,227,.005a1.169,1.169,0,0,1,1.41.9l4.51,19.639a1.2,1.2,0,0,1-.872,1.441A1.625,1.625,0,0,1,231.776,22.012Z'
          transform='translate(-226.097 0.028)'
        />
      </g>
    </g>
  </svg>
);

export default SVG;
