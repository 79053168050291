import styled from 'styled-components';
import { translate } from 'i18n/translate';

interface CollectionTileTextProps {
  className?: string;
  text: string;
  // eslint-disable-next-line react/no-unused-prop-types
  isCreate?: boolean;
  createdBy?: string | undefined;
}

const CollectionTileText = ({ className, text, createdBy }: CollectionTileTextProps) => (
  <div className={className}>
    <div>
      <h3>
        <span>{text}</span>
      </h3>
    </div>
    {createdBy && (
      <h4>
        {translate('By')} <b>{createdBy}</b>
      </h4>
    )}
  </div>
);

CollectionTileText.defaultProps = {
  className: '',
  isCreate: false,
};

const StyledCollectionTileText = styled(CollectionTileText)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1.1em;
  ${(props) => !props.isCreate && `background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));`}

  color: #fff !important;
  margin-bottom: 0;
  > div {
    display: flex;
    align-items: center;
  }
  h3,
  h4 {
    margin: 0;
    width: 100%;
    color: #fff !important;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: 14px;
    }
  }
  h4 {
    opacity: 0.6;
  }
  svg {
    margin-left: auto;
    fill: rgba(var(--primary-color), 1);
    transition: ${(props) => props.theme.transitions.default};
  }
  span {
    ${(props) =>
      !props.isCreate &&
      `
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
  }
`;

export default StyledCollectionTileText;
