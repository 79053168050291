import styled from 'styled-components';
import { pageBanner } from '../../theming/mixins';

const SearchResultsBanner = styled.div`
  ${pageBanner}
  font-size: 32vw;
  line-height: 32vw;
  bottom: calc(${(props) => props.theme.globalDimensions.footerHeight} - 50px);
  z-index: -1;
`;

export default SearchResultsBanner;
