import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '16', height = '16', className = '', viewBox = '0 0 219.894 204.3076' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M36,72c19.8506,0,36-16.1494,36-36S55.8506,0,36,0S0,16.1494,0,36S16.1494,72,36,72z M36,18c9.9253,0,18,8.0747,18,18
	s-8.0747,18-18,18s-18-8.0747-18-18S26.0747,18,36,18z M119,72c19.8501,0,35.9995-16.1494,35.9995-36S138.8501,0,119,0
	C99.1494,0,83,16.1494,83,36S99.1494,72,119,72z M119,18c9.9253,0,17.9995,8.0747,17.9995,18S128.9253,54,119,54s-18-8.0747-18-18
	S109.0747,18,119,18z M202.894,87.7056h-2.0195l-40.5322,19.1558V96.7056c0-7.7197-6.2803-14-14-14H14c-7.7197,0-14,6.2803-14,14
	v93.6021c0,7.7197,6.2803,14,14,14h132.3423c7.7197,0,14-6.2803,14-14v-12.3234l40.5322,19.1554h2.0195c9.374,0,17-7.626,17-17
	v-75.4341C219.894,95.3315,212.2681,87.7056,202.894,87.7056z M142.3423,186.3076H18v-85.6021h124.3423V186.3076z M201.894,177.7129
	l-41.5518-19.637v-31.306l41.5518-19.637V177.7129z'
    />
  </svg>
);

export default SVG;
