import IconType from './type';

const SVG = ({ style = {}, fill = '#000', width = '16', height = '16', className = '', viewBox = '0 0 512 512' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''} fill`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M487 220.4H25c-13.8 0-25 11.2-25 25 0 38 31 69 69 69h31.3c-.4.5-.7 1-1.1 1.6l-30.7 47H15c-8.3 0-15 6.7-15 15s6.7 15 15 15h61.6c5.1 0 9.8-2.5 12.5-6.8l35.3-53.8c7.2-11 19.4-17.6 32.6-17.6h100.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9h-41.4c-23.6 0-42.9 19.2-42.9 42.9 0 23.6 19.2 42.9 42.9 42.9h43.9c12.9 0 25.5-4.6 35.4-12.9l104.6-87.8c5-4.2 12.1-4.7 17.7-1.3 5.2 3.2 6.6 8.1 7 10.7.4 2.6.4 7.7-3.7 12.2l-101.3 113c-12.3 13.8-30 21.7-48.4 21.7H15c-8.3 0-15 6.7-15 15s6.7 15 15 15h256c27 0 52.8-11.5 70.8-31.6L443 367.3c8.9-10 12.9-23.3 11-36.5-.9-5.9-2.9-11.5-5.8-16.5 35.6-2.7 63.7-32.5 63.7-68.8.1-13.9-11.1-25.1-24.9-25.1zm-211.2 170c-4.5 3.8-10.2 5.9-16.1 5.9h-43.9c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h41.4c23.6 0 42.9-19.2 42.9-42.9 0-4.6-.7-9.1-2.1-13.3h68.4l-90.6 76.1zm167.2-106H69c-19.8 0-36.2-14.8-38.7-34h451.4c-2.5 19.1-18.9 34-38.7 34zM374.8 171.3c0 8.3 6.7 15 15 15H429c8.3 0 15-6.7 15-15s-6.7-15-15-15h-39.2c-8.3 0-15 6.7-15 15zM363.1 112.9c3.3 0 6.6-1.1 9.4-3.3l30.6-24.5c6.5-5.2 7.5-14.6 2.3-21.1-5.2-6.5-14.6-7.5-21.1-2.3l-30.6 24.5c-6.5 5.2-7.5 14.6-2.3 21.1 3 3.6 7.3 5.6 11.7 5.6zM292.1 66.6c1.5.5 3.1.7 4.6.7 6.3 0 12.2-4.1 14.3-10.4l12-37.3c2.5-7.9-1.8-16.3-9.7-18.9-7.9-2.5-16.3 1.8-18.9 9.7l-12 37.3c-2.5 7.9 1.8 16.3 9.7 18.9zM201 56.9c2 6.4 7.9 10.4 14.3 10.4 1.5 0 3.1-.2 4.6-.7 7.9-2.5 12.2-11 9.7-18.9l-12-37.3c-2.5-7.9-11-12.2-18.9-9.7-7.9 2.5-12.2 11-9.7 18.9l12 37.3zM108.9 85.1l30.6 24.5c2.8 2.2 6.1 3.3 9.4 3.3 4.4 0 8.8-1.9 11.7-5.6 5.2-6.5 4.1-15.9-2.3-21.1l-30.6-24.5c-6.5-5.2-15.9-4.1-21.1 2.3-5.2 6.5-4.2 15.9 2.3 21.1zM83.1 186.3h39.2c8.3 0 15-6.7 15-15s-6.7-15-15-15H83.1c-8.3 0-15 6.7-15 15s6.7 15 15 15z'
    />
  </svg>
);

export default SVG;
