import React from 'react';
import ArrowRight from './ArrowRight';
import ArrowLeft from './ArrowLeft';
import Eye from './Eye';
import Search from './Search';
import SearchThin from './SearchThin';
import Hub from './Hub';
import ChevronRight from './ChevronRight';
import Appearance from './Appearance';
import Question from './Question';
import Privacy from './Privacy';
import NavDots from './NavDots';
import AddToCollection from './AddToCollection';
import Boxset from './Boxset';
import Boxsets from './Boxsets';
import Star from './Star';
import Delete from './Delete';
import Balloon from './Balloon';
import Heart from './Heart';
import HighFive from './HighFive';
import Locked from './Locked';
import Mandatory from './Mandatory';
import Email from './Email';
import Phone from './Phone';
import CheckThis from './CheckThis';
import Curious from './Curious';
import LightBulb from './LightBulb';
import Tooltip from './Tooltip';
import Url from './Url';
import Download from './Download';
import Rocket from './Rocket';
import PaperPlane from './PaperPlane';
import PaintRoller from './PaintRoller';
import Close from './Close';
import Image from './Image';
import Crop from './Crop';
import Info from './Info';
import Error from './Error';
import Droplet from './Droplet';
import AdminDash from './AdminDash';
import Carousels from './Carousels';
import Recordings from './Recordings';
import Items from './Items';
import Cog from './Cog';
import User from './User';
import Audiences from './Audiences';
import Skills from './Skills';
import Chart from './Chart';
import Ellipsis from './Ellipsis';
import Binoculars from './Binoculars';
import SkipBack from './SkipBack';
import SkipForward from './SkipForward';
import VolumeSoft from './VolumeSoft';
import VolumeLoud from './VolumeLoud';
import VolumeMute from './VolumeMute';
import Pause from './Pause';
import Next from './Next';
import Captions from './Captions';
import Logout from './Logout';
import Plus from './Plus';
import Home from './Home';
import Library from './Library';
import Content from './Content';
import Warning from './Warning';
import Tile from './Tile';
import Book from './Book';
import Headphones from './Headphones';
import Play from './Play';
import Check from './Check';
import Edit from './Edit';
import Bell from './Bell';
import Hamster from './Hamster';
import Fireworks from './Fireworks';
import Telescope from './Telescope';
import Settings from './Settings';
import Lighthouse from './Lighthouse';
import FishBone from './FishBone';
import NotePad from './NotePad';
import Controls from './Controls';
import Details from './Details';
import Upload from './Upload';
import More from './More';
import Webcam from './Webcam';
import Screen from './Screen';
import Cowboy from './Cowboy';
import Ticker from './Ticker';
import Document from './Document';
import Employee from './Employee';
import Briefcase from './Briefcase';
import Globe from './Globe';
import Save from './Save';
import NoItemsCollection from './NoItemsCollection';
import Workplace from './Workplace';
import ChevronReactSelect from './ChevronReactSelect';
import InvertedCheck from './InvertedCheck';
import InvertedMinus from './InvertedMinus';
import InvertedPlus from './InvertedPlus';
import Move from './Move';
import CircleArrowDown from './CircleArrowDown';
import Bookmark from './Bookmark';
import Cookie from './Cookie';
import ChromeMoreInfo from './ChromeMoreInfo';
import Fact from './Fact';
import WarningFilled from './WarningFilled';
import Anchor from './Anchor';
import Internal from './Internal';
import External from './External';
import Disconnect from './Disconnect';
import Shared from './Shared';
import IconType from './type';
import Lightning from './Lightning';
import Trophy from './Trophy';

const Icon = ({ name, width, style, height, viewBox, className, fill }: IconType) => {
  switch (name) {
    case 'arrow-right':
      return <ArrowRight width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'arrow-left':
      return <ArrowLeft width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'eye':
      return <Eye width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'search':
      return <Search width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'search-thin':
      return <SearchThin width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'hub':
      return <Hub width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'chevron-right':
      return <ChevronRight width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'appearance':
      return <Appearance width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'question':
      return <Question width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'privacy':
      return <Privacy width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'nav-dots':
      return <NavDots width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'add-to-collection':
      return <AddToCollection width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'boxset':
      return <Boxset width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'boxsets':
      return <Boxsets width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'star':
      return <Star width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'balloon':
      return <Balloon width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'heart':
      return <Heart width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'high-five':
      return <HighFive width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'locked':
      return <Locked width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'mandatory':
      return <Mandatory width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'email':
      return <Email width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'phone':
      return <Phone width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'check-this':
      return <CheckThis width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'curious':
      return <Curious width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'lightBulb':
      return <LightBulb width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'tooltip':
      return <Tooltip width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'url':
      return <Url width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'download':
      return <Download width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'rocket':
      return <Rocket width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'paint-roller':
      return <PaintRoller width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'paper-plane':
      return <PaperPlane width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'close':
      return <Close width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'image':
      return <Image width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'crop':
      return <Crop width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'info':
      return <Info width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'error':
      return <Error width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'droplet':
      return <Droplet width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'admin-dash':
      return <AdminDash width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'carousels':
      return <Carousels width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'recordings':
      return <Recordings width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'items':
      return <Items width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'cog':
      return <Cog width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'user':
      return <User width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'audiences':
      return <Audiences width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'skills':
      return <Skills width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'chart':
      return <Chart width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'ellipsis':
      return <Ellipsis width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'binoculars':
      return <Binoculars width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'skip-back':
      return <SkipBack width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'skip-forward':
      return <SkipForward width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'volume-soft':
      return <VolumeSoft width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'volume-loud':
      return <VolumeLoud width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'volume-mute':
      return <VolumeMute width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'pause':
      return <Pause width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'next':
      return <Next width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'captions':
      return <Captions width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'logout':
      return <Logout width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'plus':
      return <Plus width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'home':
      return <Home width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'library':
      return <Library width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'content':
      return <Content width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'warning':
      return <Warning width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'tile':
      return <Tile width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'book':
      return <Book width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'headphones':
      return <Headphones width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'play':
      return <Play width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'check':
      return <Check width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'edit':
      return <Edit width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'delete':
      return <Delete width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'bell':
      return <Bell width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'hamster':
      return <Hamster width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'fireworks':
      return <Fireworks width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'telescope':
      return <Telescope width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'settings':
      return <Settings width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'lighthouse':
      return <Lighthouse width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'fishbone':
      return <FishBone width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'notepad':
      return <NotePad width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'controls':
      return <Controls width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'details':
      return <Details width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;

    case 'upload':
      return <Upload width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'more':
      return <More width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'webcam':
      return <Webcam width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'screen':
      return <Screen width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'cowboy':
      return <Cowboy width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'ticker':
      return <Ticker width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'document':
      return <Document width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'employee':
      return <Employee width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'briefcase':
      return <Briefcase width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'globe':
      return <Globe width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'save':
      return <Save width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'no-items-collection':
      return <NoItemsCollection width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'workplace':
      return <Workplace width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'chevron-react-select':
      return <ChevronReactSelect />;
    case 'inverted-check':
      return <InvertedCheck width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'inverted-plus':
      return <InvertedPlus width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'inverted-minus':
      return <InvertedMinus width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'move':
      return <Move width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'circle-arrow-down':
      return <CircleArrowDown width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'bookmark':
      return <Bookmark width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'cookie':
      return <Cookie width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'chrome-more-info':
      return <ChromeMoreInfo width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'fact':
      return <Fact width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'warning-filled':
      return <WarningFilled width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'anchor':
      return <Anchor width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'internal':
      return <Internal width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'external':
      return <External width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'disconnect':
      return <Disconnect width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'shared':
      return <Shared width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} />;
    case 'lightning':
      return <Lightning />;
    case 'trophy':
      return <Trophy />;
    default:
      return <div />;
  }
};
export default Icon;
