import IconType from './type';

const SVG = ({ fill = '', width = '16px', height = '16px', className = '', viewBox = '0 0 512 512' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <g>
        <path
          fill={fill}
          d='M472,433.632c0,20.953-17.271,38-38.5,38s-38.5-17.047-38.5-38v-1.535l-42.852-277.465H326v-68h19v-33.38L256,0.368
			l-89,52.885v33.38h19v68h-26.148L117,432.097v1.535c0,20.953-17.271,38-38.5,38s-38.5-17.047-38.5-38H0c0,43.01,35.215,78,78.5,78
			c23.218,0,44.114-10.069,58.499-26.042c14.385,15.974,35.281,26.042,58.501,26.042c24.319,0,46.09-11.045,60.5-28.351
			c14.41,17.306,36.181,28.351,60.5,28.351c23.219,0,44.116-10.069,58.501-26.042c14.385,15.974,35.281,26.042,58.499,26.042
			c43.285,0,78.5-34.99,78.5-78H472z M226,86.632h60v68h-60V86.632z M316.5,471.632c-21.229,0-38.5-17.047-38.5-38h-44
			c0,20.953-17.271,38-38.5,38c-20.763,0-37.74-16.307-38.475-36.627l37.123-240.373h123.703l37.123,240.373
			C354.24,455.325,337.263,471.632,316.5,471.632z'
        />
      </g>
    </g>
    <g>
      <g>
        <rect fill={fill} x='236' y='234.63' width='40' height='40' />
      </g>
    </g>
    <g>
      <g>
        <rect fill={fill} x='236' y='314.63' width='40' height='40' />
      </g>
    </g>
    <g>
      <g>
        <polygon fill={fill} points='512,42.521 365.333,102.152 365.333,135.78 512,195.41 512,152.231 430.182,118.966 512,85.701 		' />
      </g>
    </g>
  </svg>
);

export default SVG;
