import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 29.7887 28.4286' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M6.3072,28.4284c-0.207,0-0.4136-0.0645-0.5879-0.1914c-0.3076-0.2236-0.4619-0.6025-0.3979-0.9775
	l1.5513-9.0439l-6.5708-6.4053c-0.2725-0.2656-0.3706-0.6631-0.2529-1.0244c0.1177-0.3623,0.4307-0.626,0.8076-0.6807l9.0801-1.3193
	l4.0615-8.2285c0.168-0.3418,0.5156-0.5576,0.8965-0.5576l0,0c0.3809,0,0.7285,0.2158,0.897,0.5576l4.0605,8.2285l9.0801,1.3193
	c0.377,0.0547,0.6899,0.3184,0.8076,0.6807c0.1177,0.3613,0.0195,0.7588-0.2529,1.0244l-6.5708,6.4053l1.5513,9.0439
	c0.064,0.375-0.0903,0.7539-0.3979,0.9775c-0.3081,0.2246-0.7158,0.2539-1.0532,0.0762l-8.1216-4.2695l-8.1221,4.2695
	C6.626,28.3903,6.4663,28.4284,6.3072,28.4284z M3.1489,11.7927l5.4966,5.3584c0.2354,0.2295,0.3428,0.5605,0.2876,0.8848
	l-1.2979,7.5645l6.7939-3.5713c0.291-0.1543,0.6396-0.1543,0.9307,0l6.7935,3.5713l-1.2979-7.5645
	c-0.0552-0.3242,0.0522-0.6553,0.2876-0.8848l5.4966-5.3584l-7.5952-1.1035c-0.3262-0.0469-0.6074-0.252-0.7534-0.5469
	l-3.3965-6.8828l-3.3975,6.8828c-0.1455,0.2949-0.4272,0.5-0.7529,0.5469L3.1489,11.7927z'
    />
  </svg>
);

export default SVG;
