import React from 'react';

function Icon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 30.522'>
      <g opacity='0.997'>
        <path
          d='M230.137 112.17a3.194 3.194 0 00-1.937.011 3.12 3.12 0 00-1.558 1.124l-10.418 14.245a3 3 0 00.46 4.044 3.167 3.167 0 002.082.779h5.209v7.122a3.021 3.021 0 001.04 2.273 3.172 3.172 0 002.427.76 3.14 3.14 0 002.2-1.258l10.418-14.245a3 3 0 00.564-2.11 3.031 3.031 0 00-1.021-1.939 3.169 3.169 0 00-2.085-.779h-5.209v-7.122a3 3 0 00-.6-1.8 3.121 3.121 0 00-1.571-1.107zm7.381 13.08L227.1 139.495V129.32h-8.335l10.418-14.245v10.175z'
          data-name='Path 1007'
          transform='translate(-215.644 -112.025)'
        />
      </g>
    </svg>
  );
}

export default Icon;
