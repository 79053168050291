import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

/*
active (required) - determines if the placeholder should be shown 
thumbnail (optional) - shows a blurred low res thumbnail whilst loading
*/

interface ImagePlaceholderProps {
  className?: string;
  active?: boolean;
}

const ImagePlaceholder = ({ className, active }: ImagePlaceholderProps) => (
  <AnimatePresence>
    {active && (
      <motion.figure className={className} animate={{ opacity: 1 }} initial={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
        <figure />
      </motion.figure>
    )}
  </AnimatePresence>
);

interface StyledImagePlaceholderProps {
  thumbnail?: string;
}

const StyledImagePlaceholder = styled(ImagePlaceholder)<StyledImagePlaceholderProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background-color);
  z-index: z(overlay);
  overflow: hidden;

  > figure {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    // If the placeholder has an image, blur the thumbnail
    ${(props) =>
      props.thumbnail &&
      `
      filter: blur(20px);
      background-size: cover;
      background-position: center;
      background-image: url(${props.thumbnail});
    `}

    // If the placeholder has no image, display a subtle animation
    ${(props) =>
      !props.thumbnail &&
      `
      transform: translateX(-100%) translateY(-100%) rotate(45deg);
      animation: placeholder 1.5s infinite;
      animation-delay: 1s;
      &:before {
        content: '';
        position: absolute;
        height: 200%;
        width: 10%;
        background-color: var(--background-color-inverse);
        opacity: 0.25;
        left: 50%;
        top: 50%;
        filter: blur(30px);
        transform: translateX(-50%) translateY(-50%);
      }
    `}
  }

  @keyframes placeholder {
    0% {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
    100% {
      transform: translateX(150%) translateY(150%) rotate(45deg);
    }
  }
`;

export default StyledImagePlaceholder;
