import IconType from './type';

const SVG = ({ style = {}, className = '', viewBox = '0 0 95 67' }: IconType) => (
  <svg
    style={style}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <path
        d='M75.3,17.4c-1.1-1.1-2.9-1.1-4,0.1c-1.1,1.1-1.1,2.9,0.1,4c2.7,2.6,4.1,6,4.1,9.7s-1.5,7.1-4.1,9.7
			c-1.1,1.1-1.2,2.9-0.1,4c0.6,0.6,1.3,0.9,2,0.9s1.4-0.3,2-0.8c3.8-3.6,5.9-8.5,5.9-13.8C81.2,26,79.1,21.1,75.3,17.4z'
      />
      <path
        d='M84,6.6c-1.2-1-3-0.9-4,0.2c-1,1.2-0.9,3,0.2,4c5.8,5.2,9.1,12.6,9.1,20.3c0,7.8-3.3,15.2-9.1,20.4c-1.2,1-1.3,2.8-0.2,4
			c0.6,0.6,1.3,0.9,2.1,0.9c0.7,0,1.3-0.2,1.9-0.7c7-6.3,11-15.2,11-24.6C95,21.8,91,12.9,84,6.6z'
      />
      <path
        d='M60.7,0.3c-0.9-0.5-2.1-0.4-2.9,0.2L33.1,17.8H8.3c-4.6,0-8.3,3.7-8.3,8.3v10.4c0,4.6,3.7,8.3,8.3,8.3h1.2L12,64.5
			c0.2,1.4,1.4,2.5,2.8,2.5h12.9c0.8,0,1.6-0.3,2.1-1c0.5-0.6,0.8-1.4,0.7-2.2l-2.4-19.2h5l24.7,17.3c0.5,0.3,1.1,0.5,1.6,0.5
			c0.4,0,0.9-0.1,1.3-0.3c0.9-0.5,1.5-1.5,1.5-2.5V2.8C62.3,1.8,61.7,0.8,60.7,0.3z M5.7,36.4V26c0-1.4,1.2-2.6,2.6-2.6h22.9V39H8.3
			C6.8,39,5.7,37.8,5.7,36.4z M24.5,61.3h-7.2l-2.1-16.7h7.2L24.5,61.3z M56.6,54.2L36.8,40.4V22.1L56.6,8.3V54.2z'
      />
    </g>
  </svg>
);

export default SVG;
