import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '16', height = '16', className = '', viewBox = '0 0 531 437.0049' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M357.2197,138.2124l-60.4209,60.4209c-9.8486-4.2246-20.5371-6.4341-31.2988-6.4341
	c-43.8105,0-79.4531,35.6426-79.4531,79.4531s35.6426,79.4531,79.4531,79.4531s79.4531-35.6426,79.4531-79.4531
	c0-10.7617-2.21-21.4512-6.4346-31.2988l60.4219-60.4214c11.501-11.501,11.501-30.2148,0-41.7158
	C387.4404,126.7158,368.7256,126.7119,357.2197,138.2124z M265.5,251.2031c11.2783,0,20.4531,9.1748,20.4531,20.4531
	s-9.1748,20.4531-20.4531,20.4531c-11.2778,0-20.4531-9.1748-20.4531-20.4531S254.2222,251.2031,265.5,251.2031z M531,265.3828
	c0,58.2461-18.54,113.5439-53.6143,159.915c-4.7559,6.2891-11.6777,10.3477-19.4902,11.4248
	c-1.3682,0.1885-2.7354,0.2822-4.0928,0.2822c-6.3818,0-12.5615-2.0625-17.7402-5.9785
	c-12.9707-9.8135-15.543-28.3496-5.7344-41.3223C457.5908,353.667,472,310.6777,472,265.3828
	c0-55.1851-21.4756-107.0317-60.4707-145.9893C372.5469,80.4482,320.6855,59,265.5,59c-55.207,0-107.0781,21.4575-146.0586,60.4199
	C80.4653,158.3779,59,210.2153,59,265.3828c0,45.2939,14.4082,88.2822,41.6665,124.3203
	c9.8135,12.9707,7.2441,31.5088-5.7275,41.3223c-12.9731,9.8105-31.5107,7.2432-41.3262-5.7256
	C18.5381,378.9238,0,323.627,0,265.3828c0-70.959,27.6128-137.6274,77.752-187.7241C127.873,27.5796,194.5503,0,265.5,0
	c70.9707,0,137.6592,27.5903,187.7783,77.6895C503.3984,127.7876,531,194.4453,531,265.3828z'
    />
  </svg>
);

export default SVG;
