import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '12', className = '', viewBox = '0 0 261 211' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path d='M220.5,65H211V10.5A10.51,10.51,0,0,0,200.5,0h-65A10.51,10.51,0,0,0,125,10.5V28.8c0,11.14-8.27,20.64-18.44,21.17A19.49,19.49,0,0,1,86,30.5v-20A10.51,10.51,0,0,0,75.5,0h-65A10.51,10.51,0,0,0,0,10.5v150A50.55,50.55,0,0,0,50.5,211h150A10.51,10.51,0,0,0,211,200.5V146h9.5a40.5,40.5,0,0,0,0-81Zm0,60h-20A10.51,10.51,0,0,0,190,135.5V190H50.5A29.53,29.53,0,0,1,21,160.5V21H65v9.5A40.67,40.67,0,0,0,77.62,59.88a40.1,40.1,0,0,0,30,11.06C129.16,69.82,146,51.31,146,28.8V21h44V75.5A10.51,10.51,0,0,0,200.5,86h20a19.5,19.5,0,0,1,0,39Z' />
  </svg>
);

export default SVG;
