import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '22', height = '22', className = '', viewBox = '0 0 17 17' }: IconType) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''} fill`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <path
        fill={fill}
        d='M14.51,0H2.49C1.12,0,0,1.12,0,2.49v12.03C0,15.88,1.12,17,2.49,17h12.03c1.37,0,2.49-1.12,2.49-2.49V2.49
		C17,1.12,15.88,0,14.51,0z M1.56,2.49c0-0.51,0.42-0.93,0.93-0.93h12.03c0.51,0,0.93,0.42,0.93,0.93v6.76L12.4,6.29
		C12.1,6,11.61,6,11.31,6.3l-9.19,9.07c-0.33-0.14-0.57-0.47-0.57-0.86V2.49z M14.51,15.44H4.26l7.6-7.5l3.58,3.48v3.09
		C15.44,15.03,15.03,15.44,14.51,15.44z'
      />
      <path
        fill={fill}
        d='M5.44,7.63c1.2,0,2.17-0.97,2.17-2.17S6.64,3.29,5.44,3.29S3.27,4.26,3.27,5.46S4.24,7.63,5.44,7.63z M5.44,4.84
		c0.34,0,0.62,0.28,0.62,0.62c0,0.34-0.28,0.62-0.62,0.62c-0.34,0-0.62-0.28-0.62-0.62C4.83,5.12,5.1,4.84,5.44,4.84z'
      />
    </g>
  </svg>
);

export default SVG;
