import IconType from './type';

const SVG = ({ style = {}, width = '24', height = '24', className = '', viewBox = '0 0 492 492' }: IconType) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <g>
      <g>
        <path
          d='M484.1,226.9L306.5,49.2c-5.1-5.1-11.8-7.9-19-7.9c-7.2,0-14,2.8-19,7.9l-16.1,16.1c-5.1,5.1-7.9,11.8-7.9,19
			c0,7.2,2.8,14.2,7.9,19.3l103.7,103.9H26.6C11.7,207.5,0,219.1,0,234v22.8c0,14.9,11.7,27.6,26.6,27.6h330.5L252.2,388.9
			c-5.1,5.1-7.9,11.7-7.9,18.9c0,7.2,2.8,13.9,7.9,18.9l16.1,16.1c5.1,5.1,11.8,7.8,19,7.8c7.2,0,14-2.8,19-7.9l177.7-177.7
			c5.1-5.1,7.9-11.9,7.9-19.1C492,238.8,489.2,232,484.1,226.9z'
        />
      </g>
    </g>
  </svg>
);

export default SVG;
