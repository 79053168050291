import IconType from './type';

const SVG = ({ style = {}, width = '16', height = '16', className = '', viewBox = '0 0 16.324 16.325' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g transform='translate(104.765 117.119) rotate(180)'>
      <path
        id='Path_960'
        data-name='Path 960'
        d='M328.622,190.4a1.02,1.02,0,0,0-1.02,1.02v2.551a1.02,1.02,0,1,0,2.04,0V191.42a1.02,1.02,0,0,0-1.02-1.02Z'
        transform='translate(-232.019 -85.524)'
      />
      <path
        id='Path_961'
        data-name='Path 961'
        d='M329.644,325.822a1.02,1.02,0,1,1-1.02-1.02,1.02,1.02,0,0,1,1.02,1.02'
        transform='translate(-232.021 -213.805)'
      />
    </g>
  </svg>
);

export default SVG;
