import styled from 'styled-components';
import { motion } from 'framer-motion';

const ItemCardInfoPanel = styled(motion.figure)`
  position: absolute;
  top: 99%;
  background-color: var(--grey-3);
  width: 100%;
  height: auto;
  padding: 0 30px 50px 30px;
  left: 0;
  word-wrap: break-word;
  z-index: 4;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: relative;
    height: 170px;
  }
  p,
  a {
    font-size: 0.75rem;
    color: var(--grey-6);
    opacity: 0.5;
    line-height: 1.15rem;
  }
  a {
    transition: ${(props) => props?.theme?.transitions?.default};
    text-decoration: none;
    &:hover {
      color: rgba(var(--primary-color), 1);
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      max-width: 250px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  h4 {
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
`;

ItemCardInfoPanel.defaultProps = {
  initial: { opacity: 0, translateY: -30, width: '100%' },
  animate: { opacity: 1, translateY: 0 },
  exit: {
    opacity: 0,
    translateY: -30,
    transition: {
      type: 'tween',
      ease: 'easeIn',
      duration: 0.2,
    },
  },
};

export default ItemCardInfoPanel;
