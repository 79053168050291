import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '12', className = '', viewBox = '0 0 512 512' }: IconType) => (
  <svg
    id='Capa_1'
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={fill}
      d='m447.704 130.941-16.311-48.88c-4.297-12.878-16.307-21.53-29.884-21.53h-75.434v-17.533c0-23.709-19.289-42.998-42.998-42.998h-44.189c-23.709 0-42.998 19.289-42.998 42.998v17.533h-85.4c-13.577 0-25.586 8.652-29.884 21.53l-16.31 48.88c-1.526 4.575-.76 9.604 2.059 13.517s7.348 6.231 12.17 6.231h5.55l38.88 347.977c.848 7.592 7.268 13.334 14.907 13.334h234.434c7.64 0 14.059-5.742 14.907-13.334l38.881-347.977h7.39c4.822 0 9.351-2.318 12.17-6.231 2.82-3.912 3.586-8.941 2.06-13.517zm-221.814-87.943c0-7.167 5.831-12.998 12.998-12.998h44.189c7.167 0 12.998 5.831 12.998 12.998v17.533h-70.185zm132.989 439.002h-207.598l-37.018-331.311h281.636zm-259.535-361.311 9.72-29.132c.205-.614.778-1.026 1.427-1.026h291.019c.648 0 1.222.412 1.426 1.026l9.721 29.132z'
    />
  </svg>
);

export default SVG;
