import IconType from './type';

const SVG = ({ style = {}, width = '22', height = '22', className = '', viewBox = '0 0 12.1 12.1' }: IconType) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''} fill`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path d='M0,0v12.1h12.1V0H0z M5.3,1.5v3.8H1.5V1.5H5.3z M1.5,6.8h3.8v3.8H1.5V6.8z M10.6,10.6H6.8V1.5h3.8V10.6z' />
  </svg>
);

export default SVG;
