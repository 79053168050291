import { TileProps } from 'types/tile';
import { postData } from '../../APIHandler';
import { Collection } from '../../types/library';
import { reformatCollectionItems } from './LibraryAPI';

export interface Hit {
  _score: number;
  _index: string;
  _type: string;
  _id: string;
  _seq_no: null;
  _primary_term: null;
  highlight: Highlight;
  _source: Source;
}

export interface Highlight {
  'collection.items.name': string[];
  'collection.items.description': string[];
}

export interface Source {
  type: string;
  audiences: string[];
  organisationId: string;
  tile?: TileProps;
  collection?: Collection;
  version: string;
}

const performSearch = (searchQuery: string, callBack: (results: Hit[]) => void) => {
  const baseSpec = {
    searchTerm: searchQuery,
  };

  const searchSpecs = [
    { ...baseSpec, indexPrefix: 'tile', indexName: 'tile_index' },
    { ...baseSpec, indexPrefix: 'collection', indexName: 'collection_index' },
  ];

  // Bind the first two parameters to the POST call. Only the search spec changes
  const issueSearch = postData.bind(this, 'CrazyDiamondSearchAPI', '');

  // The searches are run concurrently.
  Promise.all(searchSpecs.map(issueSearch)).then((networkResults) => {
    const results = networkResults.flatMap(({ status, queryResponse }) => {
      if (!queryResponse) return [];

      if (status !== 'ok') {
        return [];
      }

      if (queryResponse === null) {
        return [];
      }
      const { hits = { hits: [] } } = queryResponse;
      return hits.hits || [];
    });

    const formattedResults = results.map((result: Hit) => {
      if (result._source.collection) {
        return {
          ...result,
          _source: {
            ...result._source,
            collection: {
              ...result._source.collection,
              items: result._source.collection.items ? reformatCollectionItems(result._source.collection.items) : [],
            },
          },
        };
      }
      return result;
    });

    // Once all of the searches have run then call-back to the search display
    callBack(formattedResults);
  });
};

export { performSearch };
