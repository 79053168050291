import IconType from './type';

const SVG = ({ fill = '', width = '16', height = '16', className = '', viewBox = '0 0 17.82 18.18' }: IconType) => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    width={width}
    height={height}
    className={`svg-icon ${className || ''}`}
    xmlns='http://www.w3.org/2000/svg'
    viewBox={viewBox}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      className='fill'
      d='M2.81,2.81H0V0H2.81ZM10.32,0H7.5V2.81h2.82Zm7.5,0H15V2.81h2.81Zm-15,7.76H0v2.81H2.81Zm7.51,0H7.5v2.81h2.82Zm7.5,0H15v2.81h2.81Zm-15,7.61H0v2.81H2.81Zm7.51,0H7.5v2.81h2.82Zm7.5,0H15v2.81h2.81Z'
    />
  </svg>
);

export default SVG;
