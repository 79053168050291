import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 497.3115 498' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M14.7431,217.3l0.1-0.1l222.2-98.3l4-5.1c8-10.2,8-10.2,8.3-10.5c58.2-59.2,136.5-96,220.4-103.3h0.2h0.2
	c9.1,0.3,16.8,3.5,21.6,9.1c4.5,5.2,6.3,12.3,5.3,20.3c-9.6,79.3-45.5,154.1-101.3,210.9c-0.6,0.6-13.3,10.7-19.6,15.7l-98.4,227
	l-0.1,0.2c-4.7,9.3-12.9,14.8-22.1,14.8l0,0c-9.3,0-17.9-5.7-22.5-14.8l-0.1-0.2l-32.8-86.3c-6.8,5.5-11.2,9-12,9.6
	c-6.4,5.9-20,7.7-32.9-0.7l-0.4-0.2l-63.9-63.9c-8.8-8.8-9.9-22.4-2.4-32.4c0,0,4.3-5.6,11.6-14.7l-83.8-32.2l-0.1-0.1
	c-9.9-4.2-15.9-11.9-16.2-21.2C-0.5569,231.4,5.1431,222.3,14.7431,217.3z M239.7431,365l16.8,43.3l37.8-86.4l-15.7,11.9
	c-6.3,5.1-12.6,10.1-18.7,15C253.0431,354.4,246.2431,359.8,239.7431,365z M87.5431,238.3l43.1,16.7c13-16.6,27.7-35.5,42.3-54.2
	L87.5431,238.3z M142.9431,321.6l32.5,32.4l186.7-148.8c39.8-40.8,68.8-94.4,81.9-151.4c-59,11.1-114.7,40.5-157.5,83.2
	L142.9431,321.6z M74.2431,384.7c9.9-9.9,24.8-9.9,34.7-0.1c4.6,4.6,7.2,10.7,7.2,17.3c0,6.7-2.7,13.2-7.4,17.9l-31.2,31.2
	c-4.7,4.7-10.9,7.2-17.4,7.2c-6.6,0-12.8-2.6-17.4-7.2c-9.6-9.6-9.6-25.2,0-34.9L74.2431,384.7z'
    />
  </svg>
);

export default SVG;
