import CardActions, { Action } from 'components/CardActions/CardActions';
import CardActionsToggle from 'components/CardActions/CardActionsToggle';
import CardActionsButtons from 'components/CardActions/CardActionsButtons';
import { ReactNode } from 'react';
import styled from 'styled-components';
import LinkTileShadow from './TileShadow';
import { tileTitleHoverState } from './TileTitle';

const TileWrapperInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.zLayers.default};
`;

interface TileWrapperProps {
  className?: string;
  cardActions?: Action[];
  colorMode?: 'light' | 'dark';
  hideShadow?: boolean;
  children: ReactNode;
}

const TileWrapper = ({ className, children, cardActions = [], colorMode = 'light', hideShadow = false }: TileWrapperProps) => (
  <div className={className}>
    <CardActions actions={cardActions} colorMode={colorMode} />
    <TileWrapperInner>{children}</TileWrapperInner>
    {!hideShadow && <LinkTileShadow />}
  </div>
);

interface StyledTileWrapperProps {
  isHighlighted?: boolean;
  noPointerEvents?: boolean;
  // Temp solution remove once replaced
  invertMenuColors?: boolean;
}

const StyledTileWrapper = styled(TileWrapper)<StyledTileWrapperProps>`
  position: relative;
  width: 100%;
  height: 100%;

  ${(props) => props.noPointerEvents && `pointer-events: none;`}

  ${CardActionsToggle} {
    position: absolute;
    top: 0.8em;
    left: 1em;
    z-index: ${(props) => props.theme.zLayers.overlay};
  }

  ${CardActionsButtons} {
    position: absolute;
    top: 2.6em;
    left: 1em;
    z-index: ${(props) => props.theme.zLayers.overlay};
  }

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -6px;
    left: -6px;
    content: '';
    border: 6px solid rgba(var(--primary-color));
    border-radius: 21px;
    transform: scale(0.95);
    animation-delay: 0.4s;
    opacity: 0;
    transition: 0.25s ease-in;
    pointer-events: none;
    z-index: ${(props) => props.theme.zLayers.behind};

    // Highlight the tile when clicked from notifcations
    ${(props) =>
      props.isHighlighted &&
      `
      opacity: 1;
      transform: scale(1);
  `}
  }

  &:hover {
    ${LinkTileShadow} {
      opacity: 0.8;
    }

    &:hover {
      /* Makes any tile with a tile title show the hover state - so we don't need to rewrite for every single one */
      ${tileTitleHoverState}
    }
  }

  ${(props) =>
    props.invertMenuColors &&
    `
     ${CardActionsToggle} {
      svg {
        * {
          fill: #ffffff;
        }
      }
    }
    ${CardActionsButtons} {
      button {
        &:hover {
          box-shadow: 0 0 8px 0 rgb(255 255 255 / 30%);
        }
      }
    }
  `}
`;

export default StyledTileWrapper;
