import styled from 'styled-components';
import Button from '../../UI/Button/Button';
import SearchResultsHeader from '../SearchResultsHeader';
import SearchResultsPageEmptyBubbles from './SearchResultsPageEmptyBubbles';

interface SearchResultsPageEmptyProps {
  className?: string;
  query: string;
  closeSearch: () => void;
}

const SearchResultsPageEmpty = ({ className, query, closeSearch }: SearchResultsPageEmptyProps) => (
  <div className={className} data-testid='emptySearch'>
    <SearchResultsPageEmptyBubbles />
    <SearchResultsHeader query={query} />
    <section>
      <h1>
        What, <br /> no results?
      </h1>
      <h6>Try changing up your search term.</h6>
      <div>
        <Button size='l' primaryOutline lightText onClick={closeSearch} text='Go back' />
      </div>
    </section>
  </div>
);

const StyledSearchResultsPageEmpty = styled(SearchResultsPageEmpty)`
  padding: 30px 20px;
  border-radius: 20px;
  color: var(--grey-6);
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  section {
    max-width: 1500px;
    width: 100%;
    position: relative;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 100px;
    font-size: 70px;
    > h1 {
      font-size: 1em;
      line-height: 1em;
      font-weight: bold;
      margin-bottom: 0.6em;
    }

    > h6 {
      font-size: 0.3em;
      font-weight: normal;
      opacity: 0.4;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.xLarge}) {
      font-size: 60px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
      padding-top: 100px;
      padding-left: 50px;
      justify-content: flex-start;
      padding-right: 0;
      font-size: 50px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.small}) {
      padding-left: 20px;
      font-size: 40px;
    }
  }

  button {
    margin-top: 40px;
    margin-left: 0;
  }

  ${SearchResultsHeader} {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;

    background-color: transparent;
    margin-bottom: 24px;
    padding-top: 11px;

    > span {
      font-weight: bold;
    }
  }
`;

export default StyledSearchResultsPageEmpty;
