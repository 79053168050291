import styled from 'styled-components';
import ItemCardInfo from './ItemCardInfo';
import ItemCardInner from './ItemCardInner';

interface StyledItemCardProps {
  isExpanded: boolean;
  isSearch: boolean;
}

const StyledItemCard = styled.div<StyledItemCardProps>`
  transition: ${(props) => props?.theme?.transitions?.default};
  position: relative;
  padding: 40px 20px;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 2rem);
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 1px;
    transition: ${(props) => props?.theme?.transitions?.default};
    background: var(--grey-4);
  }

  ${(props) =>
    props.isExpanded &&
    `
    z-index: ${props?.theme?.zLayers?.overlay};
    background-color: var(--grey-3);
    &:after {
      width: 0;
    }
  `}

  @media only screen and (max-width: ${(props) => props?.theme?.breakpoints?.mobile}) {
    cursor: pointer;
    padding: 40px 0;
    padding-left: 10px;

    &:after {
      width: auto;
    }
  }

  .menu {
    top: 35px;
    left: 15px;
    z-index: ${(props) => props?.theme?.zLayers?.modal};
    button:hover {
      width: 6em;
    }
    @media only screen and (max-width: ${(props) => props?.theme?.breakpoints?.mobile}) {
      left: 10px !important;
      top: 44px;
    }
  }

  .options {
    top: 5px;
    left: 15px;
    z-index: 2;
    @media only screen and (max-width: ${(props) => props?.theme?.breakpoints?.mobile}) {
      z-index: z(overlay);
      left: 10px;
      top: 10px;
      border-radius: 2em;
      width: 1.8em;
      height: 1.8em;
    }
  }

  .actions {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${(props) =>
    props.isSearch &&
    `
      padding: 0;
      margin-right: 50px;
      margin-top: 0;
      width: auto;
      height: auto;
      &:after{
        display: none;
      }
      ${ItemCardInner} {
        position: relative;
        transform: initial: 
        left: 0;
        top: 0;
      }
      > button {
        display: none;
      }
      ${ItemCardInfo} {
        max-width: 300px;

        h3 {
            max-width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 0;
        }
      }
  `}
`;

export default StyledItemCard;
