import IconType from './type';

const SVG = ({ style = {}, width = '16', height = '16', className = '', viewBox = '0 0 22 22', fill = 'currentColor' }: IconType) => (
  <svg
    className={`svg-icon ${className || ''}`}
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
  >
    <g transform='translate(1 1)'>
      <path
        d='M12 14c-1.8 0-3.6-.8-4.8-2.4-.3-.4-.2-1.1.2-1.4.4-.3 1.1-.2 1.4.2 1.3 1.8 3.8 2.1 5.6.8.2-.1.3-.2.4-.4l3-3c1.6-1.6 1.6-4.1 0-5.7C17.1 1.4 16.1 1 15 1c-1 0-2 .4-2.8 1.1l-1.7 1.7c-.4.4-1 .4-1.4 0s-.4-1 0-1.4L10.8.7C11.9-.4 13.5-1 15.1-1c1.6 0 3.1.6 4.2 1.8 2.3 2.4 2.3 6.2 0 8.5l-3 3-.6.6c-1.2.7-2.4 1.1-3.7 1.1z'
        className='st0'
      />
      <path
        d='M5 21c-1.6 0-3.1-.6-4.3-1.8C-.4 18.1-1 16.6-1 15s.6-3.1 1.8-4.2l3-3c.2-.3.4-.4.6-.6 2.6-2 6.4-1.4 8.4 1.2.3.4.2 1.1-.2 1.4-.4.3-1.1.2-1.4-.2-1.3-1.8-3.8-2.1-5.6-.8-.1.1-.3.2-.4.4l-3 3C1.4 12.9 1 13.9 1 15c0 1.1.4 2.1 1.2 2.8.7.8 1.7 1.2 2.8 1.2 1 0 2-.4 2.8-1.2l1.7-1.7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-1.7 1.7C8.1 20.4 6.6 21 5 21z'
        className='st0'
      />
    </g>
  </svg>
);

export default SVG;
