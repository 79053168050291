import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 28 36' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <rect fill={fill} y='23.96' width='4.27' height='12.04' rx='1.5' />
    <rect fill={fill} x='11.87' y='11.38' width='4.27' height='24.62' rx='1.5' />
    <rect fill={fill} x='23.73' width='4.27' height='36' rx='1.5' />
  </svg>
);

export default SVG;
