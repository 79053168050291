import IconType from './type';

const SVG = ({ fill = '', width = '16px', height = '16px', className = '', viewBox = '0 0 512.522 512.522' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <path
        fill={fill}
        d='m196.458 133.611-8.918 51.996c-.965 5.627 1.348 11.315 5.967 14.671s10.744 3.798 15.797 1.142l46.696-24.55 46.696 24.549c2.194 1.154 4.591 1.723 6.979 1.723 3.11 0 6.205-.966 8.818-2.865 4.619-3.356 6.933-9.043 5.967-14.671l-8.918-51.996 37.777-36.824c4.088-3.985 5.56-9.946 3.795-15.376-1.764-5.43-6.458-9.388-12.108-10.209l-52.208-7.586-23.348-47.308c-2.527-5.12-7.742-8.361-13.451-8.361s-10.924 3.242-13.451 8.361l-23.348 47.308-52.207 7.586c-5.65.821-10.344 4.779-12.108 10.209-1.765 5.43-.293 11.391 3.795 15.376zm34.861-41.441c4.885-.71 9.109-3.779 11.294-8.206l13.387-27.125 13.387 27.125c2.185 4.427 6.409 7.496 11.294 8.206l29.934 4.35-21.66 21.114c-3.535 3.446-5.148 8.411-4.314 13.277l5.113 29.813-26.774-14.076c-2.185-1.149-4.583-1.723-6.98-1.723s-4.795.574-6.98 1.723l-26.773 14.076 5.113-29.813c.834-4.866-.779-9.831-4.314-13.277l-21.66-21.114z'
      />
      <path fill={fill} d='m256 214.682c-8.284 0-15 6.716-15 15v259.894c0 8.284 6.716 15 15 15s15-6.716 15-15v-259.894c0-8.284-6.716-15-15-15z' />
      <circle fill={fill} cx='93.17' cy='156.268' r='14.734' />
      <circle fill={fill} cx='93.535' cy='24.526' r='14.734' />
      <circle fill={fill} cx='15.259' cy='90.397' r='14.734' />
      <path
        fill={fill}
        d='m496.931 441.051h-16.818v-17.441c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.441h-18.346c-8.284 0-15 6.716-15 15s6.716 15 15 15h18.346v17.723c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.723h16.818c8.284 0 15-6.716 15-15s-6.716-15-15-15z'
      />
      <path
        fill={fill}
        d='m466.15 359.679c5.052 2.656 11.176 2.214 15.797-1.142 4.619-3.356 6.933-9.043 5.967-14.671l-6.205-36.173 26.282-25.619c4.088-3.985 5.56-9.946 3.795-15.376-1.764-5.43-6.458-9.388-12.108-10.209l-36.32-5.277-16.243-32.912c-2.527-5.12-7.742-8.361-13.451-8.361s-10.924 3.242-13.451 8.361l-16.243 32.912-36.32 5.277c-5.65.821-10.345 4.779-12.109 10.209-1.765 5.43-.293 11.391 3.795 15.376l26.282 25.619-6.204 36.173c-.965 5.627 1.348 11.315 5.967 14.671 2.613 1.898 5.708 2.865 8.818 2.865 2.388 0 4.784-.569 6.979-1.723l32.486-17.079zm-52.03-40.697 2.399-13.99c.834-4.866-.779-9.831-4.314-13.277l-10.165-9.908 14.047-2.041c4.886-.71 9.109-3.779 11.294-8.206l6.282-12.729 6.282 12.729c2.185 4.427 6.409 7.496 11.294 8.206l14.046 2.041-10.165 9.908c-3.535 3.446-5.148 8.411-4.314 13.277l2.399 13.99-12.564-6.605c-2.185-1.149-4.583-1.723-6.98-1.723s-4.795.574-6.98 1.723z'
      />
      <path
        fill={fill}
        d='m133.42 343.867-6.204-36.173 26.282-25.619c4.088-3.985 5.56-9.946 3.795-15.376-1.764-5.43-6.458-9.388-12.109-10.209l-36.32-5.277-16.243-32.913c-2.527-5.12-7.742-8.361-13.451-8.361s-10.924 3.241-13.451 8.361l-16.243 32.912-36.32 5.277c-5.65.821-10.344 4.779-12.108 10.209-1.765 5.43-.293 11.391 3.795 15.376l26.282 25.619-6.205 36.173c-.965 5.627 1.348 11.315 5.967 14.671 4.62 3.356 10.744 3.798 15.797 1.142l32.486-17.079 32.486 17.079c2.195 1.154 4.591 1.723 6.979 1.723 3.11 0 6.205-.966 8.818-2.865 4.619-3.355 6.932-9.043 5.967-14.67zm-32.792-52.151c-3.535 3.446-5.148 8.411-4.314 13.277l2.399 13.99-12.564-6.605c-4.369-2.298-9.59-2.298-13.96 0l-12.564 6.605 2.399-13.99c.834-4.866-.779-9.831-4.314-13.277l-10.165-9.908 14.046-2.041c4.886-.71 9.109-3.778 11.294-8.206l6.282-12.729 6.282 12.729c2.185 4.427 6.409 7.496 11.294 8.206l14.047 2.041z'
      />
      <path
        fill={fill}
        d='m157.224 384.487c-6.55-5.046-15.931-3.827-20.995 2.708-5.064 6.536-3.869 15.95 2.653 21.032.423.33 42.347 33.543 43.715 81.407.232 8.135 6.899 14.571 14.986 14.571.145 0 .291-.002.437-.006 8.281-.236 14.802-7.141 14.565-15.422-1.781-62.308-53.174-102.605-55.361-104.29z'
      />
      <path
        fill={fill}
        d='m354.189 383.791c-2.187 1.685-53.581 41.983-55.361 104.29-.236 8.281 6.285 15.186 14.566 15.422.146.004.291.006.436.006 8.086 0 14.754-6.437 14.986-14.572 1.367-47.854 43.276-81.065 43.715-81.407 6.534-5.062 7.743-14.461 2.695-21.012-5.057-6.562-14.475-7.782-21.037-2.727z'
      />
      <circle fill={fill} cx='419.294' cy='24.526' r='14.734' />
      <circle fill={fill} cx='418.929' cy='156.268' r='14.734' />
      <circle fill={fill} cx='497.205' cy='90.397' r='14.734' />
      <path
        fill={fill}
        d='m80.164 441.692h-16.818v-17.441c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.441h-18.346c-8.284 0-15 6.716-15 15s6.716 15 15 15h18.346v17.723c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.723h16.818c8.284 0 15-6.716 15-15s-6.716-15-15-15z'
      />
    </g>
  </svg>
);

export default SVG;
