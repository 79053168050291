import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon stroke ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path fill='none' stroke={fill} d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z' />
    <path fill='none' stroke={fill} d='M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z' />
  </svg>
);

export default SVG;
