import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 23.6533 23.8587' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M11.8267,23.859c-0.1245,0-0.249-0.0293-0.3604-0.0859L0.4375,18.19C0.168,18.0533,0,17.7808,0,17.4781
	s0.1675-0.5752,0.4375-0.7129l4.0933-2.0684l-4.0933-2.0723C0.168,12.4879,0,12.2154,0,11.9117
	c0-0.3008,0.1733-0.5811,0.4424-0.7139l4.0981-2.0615L0.438,7.0592C0.168,6.9224,0,6.65,0,6.3463
	c0-0.3027,0.168-0.5762,0.4385-0.7129l11.0293-5.5479c0.2256-0.1133,0.4927-0.1123,0.7163-0.001l11.0298,5.5488
	c0.2715,0.1367,0.4395,0.4102,0.4395,0.7129c0,0.3037-0.168,0.5762-0.4385,0.7129l-4.0815,2.0664l4.0835,2.0752
	c0.269,0.1357,0.4365,0.4092,0.4365,0.7119c0,0.3018-0.1675,0.5742-0.4375,0.7119l-4.0933,2.0713l4.0928,2.0693
	c0.2705,0.1377,0.438,0.4102,0.438,0.7129c0,0.3018-0.1675,0.5742-0.4375,0.7119l-11.0283,5.583
	C12.0757,23.8297,11.9512,23.859,11.8267,23.859z M2.5659,17.4781l9.2607,4.6875l9.2603-4.6875l-3.7319-1.8867l-5.1675,2.6162
	c-0.2236,0.1133-0.4985,0.1133-0.7212,0l-5.1675-2.6162L2.5659,17.4781z M2.5698,11.9137l9.2568,4.6865l9.2637-4.6895
	l-3.7222-1.8916l-5.1812,2.6221c-0.2227,0.1133-0.498,0.1133-0.7207,0l-5.1548-2.6094L2.5698,11.9137z M0.8911,12.0914
	l-0.0024,0.001C0.8897,12.0924,0.8902,12.0924,0.8911,12.0914z M2.5698,6.3482l9.2568,4.6865l9.2568-4.6865L11.8267,1.692
	L2.5698,6.3482z'
    />
  </svg>
);

export default SVG;
