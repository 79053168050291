import IconType from './type';

const SVG = ({ style = {}, width = '24', height = '24', className = '', viewBox = '0 0 63.5 100' }: IconType) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <g>
      <g>
        <path d='M7,0h1c3.9,0,7,3.1,7,7v86c0,3.9-3.1,7-7,7H7c-3.9,0-7-3.1-7-7V7C0,3.1,3.1,0,7,0z' />
        <path d='M55.5,0h1c3.9,0,7,3.1,7,7v86c0,3.9-3.1,7-7,7h-1c-3.9,0-7-3.1-7-7V7C48.5,3.1,51.6,0,55.5,0z' />
      </g>
    </g>
  </svg>
);

export default SVG;
