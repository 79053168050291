import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '12', className = '', viewBox = '0 0 22.157 27.916' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g transform='translate(-127.922 -13.042)'>
      <path
        d='M25.157,28.916l-10.078-7.2L5,28.916V5.88A2.88,2.88,0,0,1,7.88,3h14.4a2.88,2.88,0,0,1,2.88,2.88Z'
        transform='translate(123.922 11.042)'
      />
      <path d='M3019.842,491.156l-3.9,3.9,3.9,3.9' transform='translate(634.058 -2992.892) rotate(90)' />
    </g>
  </svg>
);

export default SVG;
