import { useState } from 'react';
import styled from 'styled-components';
import ImagePlaceholder from '../../UI/ImagePlaceholder/ImagePlaceholder';
import FailedImage from './FailedImage';
import { Item } from '../../../types/library';

interface CollectionTileImageProps {
  className?: string;
  item: Item;
}

const CollectionTileImage = ({ className, item }: CollectionTileImageProps) => {
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const handleError = () => {
    setImageIsLoaded(true);
    setImageLoadFailed(true);
  };

  const handleSuccess = () => {
    setImageIsLoaded(true);
    setImageLoadFailed(false);
  };

  return (
    <figure key={item.id} className={className} style={item.colour ? { backgroundColor: item.colour } : {}}>
      {item.imageUrl !== '' && (
        <>
          <ImagePlaceholder active={!imageIsLoaded} thumbnail={undefined} />
          <img onError={() => handleError()} onLoad={() => handleSuccess()} alt={item.name} src={item.imageUrl} />
          {imageLoadFailed && <FailedImage name={item.name || ''} />}
        </>
      )}
    </figure>
  );
};

CollectionTileImage.defaultProps = {
  className: '',
};

const StyledCollectionTileImage = styled(CollectionTileImage)`
  padding-top: 100%;
  width: 100%;
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 0;
  > img {
    max-height: 100%;
    min-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    object-fit: cover;
    transform: translateY(-50%) translateX(-50%);
  }
`;

export default StyledCollectionTileImage;
