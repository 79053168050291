import styled from 'styled-components';

const CollectionTileInner = styled.div`
  height: 0;
  width: 100%;
  padding-top: 100%;
  box-shadow: var(--soft-button-shadow);
  cursor: pointer;
  transition: ${(props) => props.theme.transitions.default};
  position: relative;
`;

export default CollectionTileInner;
