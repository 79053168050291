import styled from 'styled-components';
import HulerLink from 'components/UI/HulerLink/HulerLink';
import TileWrapper from 'components/Tiles/TileWrapper';
import { Collection } from 'types/library';
import CollectionTileImageGrid from './CollectionTileImageGrid';
import CollectionTileText from './CollectionTileText';
import CollectionTileInner from './CollectionTileInner';
import CollectionTileSharedLabel from './CollectionTileSharedLabel';
import TileTitle from '../TileTitle';

interface CollectionTileProps {
  className?: string;
  collection: Collection;
  hideName?: boolean;
  disableLink?: boolean;
  hideMenu?: boolean;
  hideShadow?: boolean;
  highlight?: boolean;
  isExternallyShared?: boolean;
  onEdit?: (collection: Collection) => void;
  onDelete?: (confirmation: string[]) => void;
  onShare?: (collection: Collection) => void;
}

const CollectionTile = ({
  className,
  collection,
  highlight,
  hideName = false,
  hideMenu,
  hideShadow,
  isExternallyShared,
  disableLink,
  onEdit,
  onDelete,
  onShare,
}: CollectionTileProps) => {
  const actions = [];

  if (onEdit) {
    actions.push({
      onClick: () => onEdit(collection),
      text: 'Edit',
      icon: 'edit',
    });
  }
  if (onShare) {
    actions.push({
      onClick: () => onShare(collection),
      text: 'Share',
      icon: 'paper-plane',
    });
  }

  if (onDelete) {
    actions.push({
      onClick: () => onDelete([collection.id]),
      text: collection.shared ? 'Remove' : 'Delete',
      icon: 'delete',
    });
  }
  return (
    <TileWrapper cardActions={hideMenu ? [] : actions} noPointerEvents={disableLink} hideShadow={hideShadow} isHighlighted={highlight}>
      <button className={className} type='button'>
        <HulerLink to={`/library/collection/${collection.id}`}>
          <CollectionTileInner>
            <CollectionTileImageGrid items={collection.items} />
            {isExternallyShared && <CollectionTileSharedLabel />}
          </CollectionTileInner>
          {!hideName && (
            <TileTitle title={collection.name} bottomTitle={collection?.shared ? `By ${collection.createdByName}` : undefined} textShadow />
          )}
          {/* !hideName && <CollectionTileText text={collection.name} createdBy={collection?.shared ? collection.createdByName : undefined} /> */}
        </HulerLink>
      </button>
    </TileWrapper>
  );
};

interface StyledCollectionTileProps {
  small?: boolean;
  hideTopGradient?: boolean;
}

const StyledCollectionTile = styled(CollectionTile)<StyledCollectionTileProps>`
  position: relative;
  width: 100%;
  height: 100%;
  font-size: inherit;

  a {
    text-decoration: none;
  }

  &:hover {
    ${CollectionTileText} svg {
      transform: scale(1.15) translateX(5px);
    }
  }

  ${(props) =>
    !props.hideTopGradient &&
    `
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      z-index: 1;
      pointer-events: none;
    }
  `}
`;

export default StyledCollectionTile;
