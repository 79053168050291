import React from 'react';
import SearchResults from '../Search/SearchResults';
import './Errors.scss';

const ErrorTemplate = (props) => {
  const { search, searchHandle, children, image } = props;
  return (
    <>
      {search?.text !== null && <SearchResults searchQuery={search?.text} searchHandle={searchHandle} />}

      <div className='ErrorContainer'>
        <div className='ErrorBubbles'>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <span />
          <span />
          <aside />
          <aside />
        </div>

        <section>
          <div className='ErrorContainer__text'>{children}</div>

          <div className='ErrorContainer__display errorImage'>
            <img className='ErrorContainer__img ErrorContainer__img--dark' src={`/img/errors/error-${image}-dark.png`} alt='' />
            <img className='ErrorContainer__img ErrorContainer__img--light' src={`/img/errors/error-${image}-light.png`} alt='' />
          </div>
        </section>
      </div>
    </>
  );
};

export default ErrorTemplate;
