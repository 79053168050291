import styled from 'styled-components';

interface SearchResultsRowCarouselProps {
  tall?: boolean;
}

const SearchResultsRowCarousel = styled.div<SearchResultsRowCarouselProps>`
  margin-bottom: 60px;
  position: relative;
  padding-right: 50px;
  .flickity-enabled {
    outline: none;
  }

  .flickity-button {
    width: 32px;
    height: 32px;
    position: absolute;
    border-radius: 32px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: ${(props) => props.theme.transitions.default};
    background: rgba(var(--primary-color), 1);
    box-shadow: 0 1.7em 1.3em -1.5em rgba(0, 0, 0, 0.5) !important;
    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
    &:active {
      transform: translateY(-50%) scale(0.9);
    }

    &.previous {
      left: -15px;
    }
    &.next {
      right: -15px;
    }
    &[disabled] {
      opacity: 0;
      pointer-events: none;
    }
    svg {
      width: 35%;
      height: auto;
      fill: var(--contrast);
    }
  }

  .tile--search {
    width: 230px;
    height: 230px;
    margin-right: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: ${(props) => props.theme.breakpoints.small}) {
      width: 48%;
      margin: 0 0 4% 0;
      height: auto;
      position: relative !important;
      left: 0 !important;
      transform: initial !important;
      &:nth-child(2n + 1) {
        margin-right: 4%;
      }
      &:after {
        content: '';
        padding-bottom: 100%;
        display: block;
      }
    }
  }

  ${(props) =>
    props.tall &&
    `
    .tile--search {
      height: 480px;
    }
  `}

  .collection--search {
    width: 230px;
    height: auto;
    margin-right: 80px;
    margin-top: 20px;
    margin-bottom: 30px;
    @media (max-width: ${(props) => props.theme.breakpoints.small}) {
      width: 48%;
      margin: 0 0 6% 0;
      height: auto;
      position: relative !important;
      left: 0 !important;
      transform: initial !important;
      &:nth-child(2n + 1) {
        margin-right: 4%;
      }
      &:after {
        content: '';
        display: block;
      }
    }
  }
  .flickity-viewport {
    overflow: hidden;
  }
  .flickity-button {
    svg {
      transition: 0.25s ease-in-out;
      height: 18px !important;
      opacity: 0.8 !important;
      path {
        fill: white;
        transition: 0.25s ease-in;
      }
    }
    &:hover {
      transform: translateY(-50%) !important;

      svg {
        transform: scale(1.2) !important;
      }
    }
  }

  .flickity-slider {
    @media (max-width: ${(props) => props.theme.breakpoints.small}) {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

export default SearchResultsRowCarousel;
