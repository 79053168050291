import Auth from '@aws-amplify/auth';
import Api from '@aws-amplify/api-rest';

/**
 * A helper function used to make a get call via the Amplify library.
 * @param {*} apiName
 * @param {*} path
 */

const getOrganisationID = () => {
  const parts = window.location.pathname.split('/');

  if (parts.length > 2) {
    return parts[1];
  }

  return '';
};

export const handleRefreshTimeOut = () => {
  const organisationId = getOrganisationID();

  if (organisationId !== '') {
    Auth.signOut();
    const location = `/${organisationId}/hub`;
    window.location = location;
  }
};

const retryAttempts = 5;

const wait = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(ms);
    }, ms);
  });
};

const getExponentialBackoff = (attempts) => {
  let localAttempts = attempts;
  const timeout = Math.max((localAttempts *= 2), 1) * 1000;
  return timeout;
};

const get = async (apiName, path, opts = {}) => {
  const op = {
    bypassAuth: false,
    ...opts,
  };

  try {
    let init = {};
    if (!op.bypassAuth) {
      init = {
        headers: {
          Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      };
    }

    return await Api.get(apiName, path, init);
  } catch (e) {
    if (e.message === 'Refresh Token has expired') {
      handleRefreshTimeOut();
      return false;
    }

    if (e === 'No current user') {
      handleRefreshTimeOut();
      return false;
    }

    if (e.message === 'Network Error') {
      return false;
    }

    if (e.response && e.response.status > 500) {
      return false;
    }

    throw e;
  }
};

export const postData = async (apiName, path, message, opts = {}) => {
  const op = {
    bypassAuth: false,
    ...opts,
  };

  try {
    const init = {
      body: message,
    };

    if (!op.bypassAuth) {
      init.headers = {
        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      };
    }

    return await Api.post(apiName, path, init);
  } catch (e) {
    if (e.message === 'Refresh Token has expired') {
      handleRefreshTimeOut();
      return false;
    }

    if (e === 'No current user') {
      handleRefreshTimeOut();
    }

    if (e.message === 'Network Error') {
      return false;
    }
    if (e.response && e.response.status > 500) {
      return false;
    }
    throw e;
  }
};

export const getData = async (apiName, path, opts = {}, attempts = 0) => {
  let localAttempts = attempts;
  const response = await get(apiName, path, opts);
  if (!response && localAttempts < retryAttempts) {
    localAttempts += 1;
    await wait(getExponentialBackoff(localAttempts));
    return getData(apiName, path, opts, localAttempts);
  }
  return response;
};

export const patchData = async (apiName, path, message) => {
  const init = {
    headers: {
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: message,
  };

  return Api.patch(apiName, path, init);
};

export const putData = async (apiName, path, message) => {
  const init = {
    headers: {
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: message,
  };

  return Api.put(apiName, path, init);
};

export const deleteData = async (apiName, path, message) => {
  const init = {
    headers: {
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: message,
  };

  return Api.del(apiName, path, init);
};
