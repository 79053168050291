import * as React from 'react';
import Auth from '@aws-amplify/auth';
import jwt from 'jsonwebtoken';
import { useAsync } from '../helpers/useAsyncHook';
import Loader from '../components/UI/Loader/Loader';

import { postData } from '../APIHandler';

const parseHulerUser = async (cognitoUser) => {
  const token = cognitoUser.signInUserSession.idToken.jwtToken;
  const decodedToken = jwt.decode(token);
  const organisationId = decodedToken['custom:organisation_id'] ? decodedToken['custom:organisation_id'] : 'MCG';
  const claims = await JSON.parse(decodedToken.encodedClaims);
  const hubAdminRoles = ['owner', 'hub-admin'];
  const isOwner = await claims?.capabilities?.some((claim) => claim === 'owner');
  const isHubAdmin = hubAdminRoles.some((role) => claims?.capabilities?.includes(role));

  const user = {
    ...cognitoUser,
    isOwner,
    isHubAdmin,
    userName: claims.attr.email,
    name: claims.attr.name,
    language: decodedToken['custom:language'] || 'EN',
    phone_number: decodedToken.phone_number,
    email: decodedToken.email,
    organisationId,
    userId: decodedToken.sub,
    attributes: claims.attr,
    hulerTerms: claims.hulerTerms,
    orgTerms: claims.orgTerms,
    capabilities: claims.capabilities,
    hasAcceptedTermsAndConditions: isOwner ? claims.hulerTerms : claims.hulerTerms && claims.orgTerms,
  };
  return user;
};

async function bootstrapAuthData() {
  let user = null;
  try {
    if (Auth.userPool) {
      user = await Auth.currentAuthenticatedUser();
      if (user) {
        const hulerUser = await parseHulerUser(user);
        return hulerUser;
      }
    }
    return user;
  } catch (error) {
    return user;
  }
}

const AuthContext = React.createContext();

function AuthProvider(props) {
  const { data: user, status, error, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync();

  React.useEffect(() => {
    const authDataPromise = bootstrapAuthData();
    run(authDataPromise);
  }, [run]);

  const login = React.useCallback(
    async (authUser) => {
      const hulerUser = await parseHulerUser(authUser);
      // Set the org in local storage so we can prefill the org search input
      localStorage.setItem('orgId', hulerUser.organisationId);
      setData(hulerUser);
    },
    [setData],
  );
  const logout = React.useCallback(
    (organisationId) =>
      Auth.signOut().then(() => {
        window.location = `${organisationId}/login`;
      }),
    [],
  );
  const logoutAndReset = React.useCallback(
    (organisationId) =>
      Auth.signOut().then(() => {
        setData(null);
        window.location = `${organisationId}/passwordreset`;
      }),
    [setData],
  );
  const acceptTermsAndConditions = React.useCallback(
    async (hulerTermsAndConditionsVersionId, orgTermsAndConditionsVersionId) => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();

        const data = {
          hulerTermsVersionId: hulerTermsAndConditionsVersionId,
          orgTermsVersionId: orgTermsAndConditionsVersionId,
        };

        await postData('UsersAPITandC', `updatetandc`, data);

        return cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          const { idToken } = session;
          setData({ ...user, JWT: idToken, hulerTerms: true, orgTerms: true, hasAcceptedTermsAndConditions: true });
        });
      } catch (e) {
        return e;
      }
    },
    [setData, user],
  );

  const setTermsNotAccepted = React.useCallback(
    (organisationId) =>
      Auth.signOut().then(() => {
        setData(null);
        window.location = `${organisationId}/login`;
      }),
    [setData],
  );

  const setLanguage = React.useCallback((locale) => setData({ ...user, language: locale }), [setData, user]);

  const value = React.useMemo(
    () => ({ user, login, logout, logoutAndReset, acceptTermsAndConditions, setTermsNotAccepted, setLanguage }),
    [user, login, logout, logoutAndReset, acceptTermsAndConditions, setTermsNotAccepted, setLanguage],
  );

  if (isLoading || isIdle) {
    return <Loader fullscreen style={{ width: '80px', height: '80px' }} />;
  }

  if (isError) {
    return <div>Error Occurred {error.message} </div>;
  }

  if (isSuccess) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an Auth Provider`);
  }

  return context;
}

export { AuthProvider, useAuth };
