/**
 * A Module to organise statTypes and send stats to the back-end.
 */

import { postData } from '../../APIHandler';
import { TileProps } from '../../types/tile';

export const statTypes = {
  TileClick: 'tileClick',
};

export const sendStats = (data: TileProps, dataType: 'tileClick', organisationId: string) => {
  // Make localcopy - as the larger data structure contains many fields that are not needed and ar enot URLencoded (so get blocked by the WAF)
  const localData = {
    pk: data.pk,
    sk: data.sk,
    name: data.name,
    audienceTileId: data.audienceTileId,
    tileId: data.tileId,
    clickUrl: data.clickUrl,
  };

  postData('CrazyDiamondAPI', `stats/${organisationId}/`, {
    ClickStatType: dataType,
    ClickData: localData,
  });
};
