import { motion } from 'framer-motion';
import { Hit } from '../../api/wrappers/SearchAPI';
import CollectionTile from '../Tiles/CollectionTile/CollectionTile';
import ItemCard from '../Library/ItemCard/ItemCard';
import Tile from '../Tiles/LinkTile/LinkTile';

export type SearchHandleType = (query: string | null) => void;

const checkMatches = (arr: string[], val: string) => {
  const newVal = val.replace(/<[^>]+>/g, '').trim();
  return arr.some((arrVal) => {
    return newVal.includes(arrVal.replace(/<[^>]+>/g, '').trim());
  });
};

const itemToVisualComponent = (hit: Hit, i: number) => {
  const {
    _source: { tile },
  } = hit;

  return tile ? (
    <div className='tile--search'>
      <Tile tile={tile} key={`hit.${i}`} hideShadow />
    </div>
  ) : (
    <div />
  );
};

// Transform a search hit for a collection into a Tile
const collectionToVisualComponent = (hit: Hit, i: number) => {
  const {
    _source: { collection = null },
  } = hit;

  if (collection) {
    collection.type = 'LIBRARY';
  }

  return collection ? (
    <motion.div whileHover={{ scale: 1.02, transition: { duration: 0.2, delay: 0 } }} className='collection--search'>
      <CollectionTile collection={collection} key={`hit.${i}`} hideMenu hideTopGradient hideShadow />
    </motion.div>
  ) : (
    <div />
  );
};

const collectionItemsToVisualComponent = (hit: Hit): JSX.Element[] | JSX.Element => {
  const {
    _source: { collection = null },
  } = hit;

  if (collection) {
    collection.type = 'LIBRARY';

    const stripedHtml: string[] = [];

    if (hit.highlight && hit.highlight['collection.items.name']) {
      hit.highlight['collection.items.name'].forEach((highlight) => stripedHtml.push(highlight.replace(/<[^>]+>/g, '')));
    }

    if (hit.highlight && hit.highlight['collection.items.description']) {
      hit.highlight['collection.items.description'].forEach((highlight) => stripedHtml.push(highlight.replace(/<[^>]+>/g, '')));
    }

    const resItems = collection?.items?.map((collItem) => {
      if (checkMatches(stripedHtml, collItem?.name) || checkMatches(stripedHtml, collItem?.description)) {
        return <ItemCard item={collItem} isSearch />;
      }
      return <div />;
    });

    return resItems ?? <div />;
  }

  return <div />;
};

const getHitCount = (hit: Hit) => {
  // Counts the number of hits by name if there are any.
  if (hit.highlight['collection.items.name']) {
    return hit.highlight['collection.items.name'].length;
  }

  // Checks the number of hits by description but only if there is not hit in the name.
  if (hit.highlight['collection.items.description'] && !hit.highlight['collection.items.name']) {
    return hit.highlight['collection.items.description'].length;
  }

  // If no hits are found.
  return 0;
};

// Takes the hit counts and uses a reducer to sum up the hits starting from 0.
const calculateTotalHitCount = (hits: Hit[]) => hits.reduce((total, current) => total + getHitCount(current), 0);

export { checkMatches, itemToVisualComponent, collectionToVisualComponent, collectionItemsToVisualComponent, calculateTotalHitCount };
