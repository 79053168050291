import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './AuthContext';
import { ConfigProvider } from './ConfigContext';

function AppProviders({ children }) {
  return (
    <HelmetProvider>
      <ConfigProvider>
        <AuthProvider>{children}</AuthProvider>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default AppProviders;
