import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '12', className = '', viewBox = '0 0 34.16 28.46' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path d='M12.28,14.11a9.54,9.54,0,0,1,1.52.13,7.86,7.86,0,0,1-.9-2.76,4.34,4.34,0,0,1-.62.06,4.49,4.49,0,0,1,0-9,4.44,4.44,0,0,1,3.44,1.65A8.07,8.07,0,0,1,18,2.9,7,7,0,1,0,7.71,12.42,12.23,12.23,0,0,0,0,23.82a1.29,1.29,0,0,0,1.29,1.29H7.76a13.65,13.65,0,0,1,.66-2.57H2.66A9.75,9.75,0,0,1,12.28,14.11Z' />
    <path d='M34.15,27a12.26,12.26,0,0,0-7.71-11.26,7.06,7.06,0,1,0-9.14,0,12.23,12.23,0,0,0-7.71,11.4,1.29,1.29,0,0,0,1.29,1.29h22a1.29,1.29,0,0,0,1.29-1.29A1.13,1.13,0,0,0,34.15,27ZM21.87,5.92a4.49,4.49,0,1,1-4.48,4.49A4.49,4.49,0,0,1,21.87,5.92Zm-9.62,20a9.71,9.71,0,0,1,19.24,0Z' />
  </svg>
);

export default SVG;
