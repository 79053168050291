import React from 'react';

function Icon() {
  return (
    <svg id='Kudos-Trophy' xmlns='http://www.w3.org/2000/svg' width='24' height='26.182' viewBox='0 0 24 26.182'>
      <path
        id='Path_1009'
        data-name='Path 1009'
        d='M122.912,22.108h1.413a3.273,3.273,0,0,0,3.273-3.273V15.563a1.091,1.091,0,0,0-1.091-1.091h-3.273V12.29a1.091,1.091,0,0,0-1.091-1.091H109.052a1.091,1.091,0,0,0-1.091,1.091v2.182h-3.273a1.091,1.091,0,0,0-1.091,1.091v3.273a3.273,3.273,0,0,0,3.273,3.273h1.413a7.636,7.636,0,0,0,6.224,5.367v3.36h-2.182a3.273,3.273,0,0,0-3.273,3.273V36.29h0a1.091,1.091,0,0,0,1.091,1.091h10.909a1.091,1.091,0,0,0,1.091-1.091V34.108a3.273,3.273,0,0,0-3.273-3.273h-2.182v-3.36a7.636,7.636,0,0,0,6.224-5.367Zm-4.042,10.909a1.091,1.091,0,0,1,1.091,1.091V35.2h-8.727V34.108a1.091,1.091,0,0,1,1.091-1.091Zm6.545-16.364v2.182a1.091,1.091,0,0,1-1.091,1.091h-1.091V16.654Zm-18.545,3.273a1.091,1.091,0,0,1-1.091-1.091V16.654h2.182v3.273Zm3.273,0V13.381h10.909v6.545a5.455,5.455,0,1,1-10.909,0Z'
        transform='translate(-103.598 -11.199)'
      />
    </svg>
  );
}

export default Icon;
