/**
 * Build a cache of filenames to their presignedURL equivalents.
 *
 * The max session time in huler is 4 hours.  After that the refresh token expires and the user is logged out.
 *
 * The expiry time for a presigned URL read is 4 hours. For write it is one minute.
 *
 * This module solves the problem of caching the images for tiles generated via a presigned URL.  Each presignedURL has an
 * expiry length which cannot be deterministically set. This means that the URL for each image changes every time a presign
 * is generated, which in turn means that images can't be cached by the browser as the URL is different.
 *
 * The purpose of this module is to implement a session cache for presigned URLS.  When a users tiles are loaded, the presigned
 * URL for each tile variant (cropped, portrait, landscape... ) is stored in the cache against its file name.
 *
 * This means that we can re-use the presignedURL which prevents the browser from downloading the picture each time.
 *
 * We chose to use the session cache, rather than local storage because we didn't want to accidentally cache data for too long.
 *
 * The session cache expires when a user closes their browser so seemed to fit quite nicely with our use-case.
 */

import { b64DecodeUnicode } from './Utils';

const store = (key: string, value: string, expiry: number) => {
  const data = {
    value,
    expiry,
  };
  sessionStorage.setItem(key, JSON.stringify(data));
};

const get = (key: string) => {
  const jsonValue = sessionStorage.getItem(key);
  if (!jsonValue) return null;

  try {
    const data = JSON.parse(jsonValue);
    const now = Date.now();
    if (now > data.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }

    return data.value;
  } catch (error) {
    sessionStorage.removeItem(key);
    return null;
  }
};

const clear = () => sessionStorage.clear();

// If the URL is encoded, decode the data first.
const decodeURL = (url: string) => {
  if (!url) {
    return '';
  }

  if (!url.startsWith('http')) {
    return b64DecodeUnicode(url);
  }

  return url;
};

const isStringValid = (str: string) => str !== '';

const cacheFileName = (presign: string) => {
  if (isStringValid(presign)) {
    const split = presign.split('?')?.[0];
    const cachedValue = get(split);

    // Only update if the filename is not in the cache.
    if (!cachedValue) {
      const expiry = Date.now() + 3.5 * 60 * 60 * 1000;

      store(split, presign, expiry);
    }
  }
};

const cacheTile = (url: string) => cacheFileName(decodeURL(url));

const getPresignFromCache = (url: string) => {
  if (isStringValid(url)) {
    const presign = decodeURL(url);
    const split = presign.split('?')?.[0];

    if (split) {
      const cachedValue = get(split);

      return cachedValue || presign;
    }
  }

  // The reason for returning an empty object is to satisfy our
  // consistent returns check for eslint.
  return {};
};

export { clear, cacheTile, getPresignFromCache };
