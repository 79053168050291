import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 92.2407 58.5205' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M71.0278,16.0957h-7.7939C60.9136,6.7822,52.4214,0,42.6479,0H21.2124C9.5156,0,0,9.5156,0,21.2129
	c0,11.6963,9.5156,21.2119,21.2124,21.2119h7.7939c2.3203,9.3135,10.8125,16.0957,20.5859,16.0957h21.4355
	c11.6973,0,21.2129-9.5156,21.2129-21.2129C92.2407,25.6113,82.7251,16.0957,71.0278,16.0957z M21.2124,10.4951h21.4355
	c4.4033,0,8.1943,2.6689,9.8408,6.4746c-0.8301-0.5527-1.8262-0.874-2.8965-0.874c-9.6348,0-18.1016,6.6719-20.5186,15.834h-7.8613
	c-5.9097,0-10.7173-4.8076-10.7173-10.7178C10.4951,15.3027,15.3027,10.4951,21.2124,10.4951z M49.5923,26.5908
	c0.999,0,1.9326-0.2803,2.7285-0.7666c-1.7266,3.6074-5.4141,6.1055-9.6729,6.1055c-0.999,0-1.9336,0.2803-2.7285,0.7666
	C41.646,29.0889,45.3335,26.5908,49.5923,26.5908z M42.6479,42.4248c9.6357,0,18.1025-6.6719,20.5195-15.834h7.8604
	c5.9102,0,10.7178,4.8076,10.7178,10.7168c0,5.9102-4.8076,10.7178-10.7178,10.7178H49.5923c-4.4023,0-8.1943-2.6689-9.8408-6.4746
	C40.5825,42.1035,41.5786,42.4248,42.6479,42.4248z'
    />
  </svg>
);

export default SVG;
