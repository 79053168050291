import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 477.867 477.867' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <g>
        <circle fill={fill} cx='68.267' cy='238.933' r='68.267' />
      </g>
    </g>
    <g>
      <g>
        <circle fill={fill} cx='238.933' cy='238.933' r='68.267' />
      </g>
    </g>
    <g>
      <g>
        <circle fill={fill} cx='409.6' cy='238.933' r='68.267' />
      </g>
    </g>
  </svg>
);

export default SVG;
