import IconType from './type';

const SVG = ({ fill = '', width = '12', height = '12', className = '', viewBox = '0 0 512 512' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Layer_2' fill={fill} data-name='Layer 2'>
      <g id='Capa_1' data-name='Capa 1'>
        <circle cx='364' cy='140' r='32' />
        <path d='M506.48,165.87c-10.68-27.19-30.27-66.43-62.92-98.93s-71.35-51.4-98.19-61.66a80.4,80.4,0,0,0-85.3,18.08l-78.53,78.17a80,80,0,0,0-16.7,88.72c4.12,9.27,8.6,18.34,13.39,27.1L5.86,389.73A20,20,0,0,0,0,403.87v88a20,20,0,0,0,20,20h88a20,20,0,0,0,20-20v-36h36a20,20,0,0,0,20-20v-36h36a20,20,0,0,0,0-40H164a20,20,0,0,0-20,20v36H108a20,20,0,0,0-20,20v36H40V412.15L217.35,234.8a20,20,0,0,0,3-24.52A322.83,322.83,0,0,1,201.39,174a39.8,39.8,0,0,1,8.37-44.12l78.53-78.17a40.35,40.35,0,0,1,42.8-9.07c23.22,8.88,56.69,25.22,84.26,52.65,27.73,27.61,44.62,61.57,53.9,85.2a39.72,39.72,0,0,1-9,42.69L380.8,302.29a40.28,40.28,0,0,1-44,8.58,258.68,258.68,0,0,1-36-18.37,20,20,0,1,0-20.92,34.1,299.58,299.58,0,0,0,41.56,21.2A80.15,80.15,0,0,0,409,330.64l79.49-79.11A79.71,79.71,0,0,0,506.48,165.87Z' />
      </g>
    </g>
  </svg>
);

export default SVG;
