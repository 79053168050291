import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import AppProviders from './contexts';
import './assets/styles/index.scss';
import App from './App';
import ErrorGeneral from './pages/Errors/ErrorGeneral';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : process.env.NODE_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={ErrorGeneral}>
    <React.StrictMode>
      <AppProviders>
        <App />
      </AppProviders>
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
