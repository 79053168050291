import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 29.0174 22.7231' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M17.0173,1v20.6772c0,0.5523-0.4478,1-1,1c-0.5522,0-1-0.4477-1-1V1c0-0.5522,0.4478-1,1-1
	C16.5695,0,17.0173,0.4477,17.0173,1z M22.0173,0.0459c-0.5522,0-1,0.4478-1,1v20.6772c0,0.5523,0.4478,1,1,1s1-0.4477,1-1V1.0459
	C23.0173,0.4937,22.5695,0.0459,22.0173,0.0459z M28.0173,0c-0.5522,0-1,0.4478-1,1v20.6772c0,0.5523,0.4478,1,1,1s1-0.4477,1-1V1
	C29.0173,0.4477,28.5695,0,28.0173,0z M5.9655,6.4186h-2v3.9658h-3.9658v2h3.9658v3.9663h2v-3.9663h3.9668v-2H5.9655V6.4186z'
    />
  </svg>
);

export default SVG;
