import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 122.79 88' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M113.04,0H9.75C4.37,0,0,4.37,0,9.75v68.5C0,83.63,4.37,88,9.75,88h103.29c5.38,0,9.75-4.37,9.75-9.75V9.75
	C122.79,4.37,118.42,0,113.04,0z M87.21,42.12l25.58-25.34v53.28L87.21,42.12z M61.39,53.63L17.36,10h88.06L61.39,53.63z
	 M42.48,48.97l11.98,11.87c1.85,1.83,4.31,2.84,6.93,2.84s5.08-1.01,6.93-2.84l11.78-11.67L106.5,78H16.2L42.48,48.97z M35.37,41.92
	L10,69.95V16.78L35.37,41.92z'
    />
  </svg>
);

export default SVG;
