import styled from 'styled-components';
import Icon from 'components/Icons';

interface CollectionTileSharedLabelProps {
  className?: string;
}

const CollectionTileSharedLabel = ({ className }: CollectionTileSharedLabelProps) => {
  return (
    <div className={className}>
      <p>External</p>
      <Icon name='shared' />
    </div>
  );
};

CollectionTileSharedLabel.defaultProps = {
  className: '',
};

const StyledCollectionTileSharedLabel = styled(CollectionTileSharedLabel)`
  position: absolute;
  right: 0.85em;
  bottom: 0.85em;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--primary-color), 1);
  overflow: hidden;
  transition: width 0.25s ease;
  z-index: 10;
  opacity: 0.75;
  transition: ${(props) => props.theme.transitions.default};
  width: 32px;
  height: 32px;

  &:hover {
    width: 100px;
    opacity: 1;

    p {
      opacity: 1;
    }
  }

  > svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(50%, -50%);
    fill: white;
    width: 1rem;
  }

  p {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    color: white;
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.25s ease;
    right: 2.2rem;
  }
`;

export default StyledCollectionTileSharedLabel;
