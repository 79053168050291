import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 6.1284 23.1325' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path fill={fill} d='M0,0v23.1325h0.6256v-1.1142c0-5.046,5.4615-4.0765,5.5028-10.4176v-0.0717C6.087,5.188,0.6255,6.1575,0.6255,1.1116V0H0z' />
  </svg>
);

export default SVG;
