import IconType from './type';

const SVG = ({ fill = '', width = '16px', height = '16px', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <line fill={fill} x1='4' y1='21' x2='4' y2='14' />
    <line fill={fill} x1='4' y1='10' x2='4' y2='3' />
    <line fill={fill} x1='12' y1='21' x2='12' y2='12' />
    <line fill={fill} x1='12' y1='8' x2='12' y2='3' />
    <line fill={fill} x1='20' y1='21' x2='20' y2='16' />
    <line fill={fill} x1='20' y1='12' x2='20' y2='3' />
    <line fill={fill} x1='1' y1='14' x2='7' y2='14' />
    <line fill={fill} x1='9' y1='8' x2='15' y2='8' />
    <line fill={fill} x1='17' y1='16' x2='23' y2='16' />
  </svg>
);

export default SVG;
