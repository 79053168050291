import HulerLink from '../../components/UI/HulerLink/HulerLink';
import ErrorTemplate from './ErrorTemplate';

interface ErrorGeneralProps {
  search?: string;
  searchHandle?: string;
}

const ErrorGeneral = ({ search, searchHandle }: ErrorGeneralProps) => (
  <>
    <ErrorTemplate image='general' searchHandle={searchHandle} search={search}>
      <>
        <h1>So...this happened, huh?</h1>
        <h2>
          <span aria-hidden role='img'>
            😬
          </span>{' '}
          Embarrassing...
        </h2>
        <h3>Let&apos;s sort this out for you.</h3>
        <HulerLink to='/hub' className='button button--primary-outline button--m-alt'>
          Take Me Back
        </HulerLink>
      </>
    </ErrorTemplate>
  </>
);

export default ErrorGeneral;
