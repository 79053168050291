import IconType from './type';

const SVG = ({ fill = '', width = '16px', height = '16px', className = '', viewBox = '0 0 512 512' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='m509.988 174.473-96.401-166.973c-4.146-7.182-13.328-9.624-20.49-5.49l-55.657 32.134c-7.175 4.142-9.633 13.315-5.49 20.49l1.184 2.05-279.155 196.841c-6.34 4.471-8.225 13.039-4.346 19.759l.534.925-42.664 24.633c-7.174 4.142-9.633 13.315-5.49 20.49l32.134 55.662c4.12 7.139 13.281 9.653 20.49 5.49l42.663-24.631.533.923c3.904 6.762 12.286 9.351 19.285 6.115l86.221-39.865c4.134 6.219 9.342 11.663 15.352 16.076l-57.074 152.64c-2.901 7.76 1.037 16.402 8.796 19.304 7.76 2.903 16.402-1.037 19.304-8.796l56.703-151.647c3.125.478 6.323.731 9.58.731s6.457-.248 9.582-.726l56.701 151.642c2.9 7.759 11.54 11.698 19.304 8.796 7.759-2.901 11.697-11.544 8.796-19.304l-57.073-152.639c15.684-11.511 25.891-30.067 25.891-50.969 0-6.079-.88-11.952-2.489-17.519l110.456-51.057 1.183 2.049c4.114 7.125 13.267 9.662 20.49 5.49l55.657-32.134c7.174-4.142 9.632-13.315 5.49-20.49zm-457.36 172.531-17.135-29.682 29.675-17.133 17.135 29.681zm203.372-25.671c-18.307 0-33.2-14.894-33.2-33.199 0-18.307 14.894-33.2 33.2-33.2s33.2 14.894 33.2 33.2-14.893 33.199-33.2 33.199zm45.67-76.817c-11.515-12.052-27.725-19.582-45.67-19.582-34.849 0-63.2 28.352-63.2 63.2 0 2.214.117 4.401.34 6.556l-76.176 35.22-34.501-59.762 265.734-187.377 63.91 110.697zm145.16-50.9-81.4-140.992 29.677-17.134 81.4 140.992z'
    />
  </svg>
);

export default SVG;
