import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 36.7 41.7' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M36.2,21.5c-0.7-1-2.1-1.2-3.5-0.6c-0.2,0.1-0.6,0.2-1,0.4l2.3-6.2c0.2-0.6,0.2-1.3-0.1-1.9c-0.3-0.6-0.8-1-1.4-1.3
	c-0.3-0.1-0.7-0.2-1.1-0.1l0.2-0.5c0.2-0.6,0.2-1.3-0.1-1.9c-0.3-0.6-0.8-1-1.4-1.3c-1.2-0.4-2.4,0.1-3,1.2
	c-0.3-0.3-0.6-0.5-0.9-0.6c-1.2-0.4-2.6,0.2-3.1,1.5L22.7,11c-0.3-0.3-0.6-0.5-0.9-0.6c-1.3-0.5-2.6,0.2-3.1,1.5l-1.2,3.3l-0.4-1.1
	c-0.2-0.6-0.7-1.1-1.3-1.4c-0.6-0.3-1.2-0.3-1.8,0c-0.3,0.1-0.6,0.3-0.9,0.6l-0.3-0.8c-0.2-0.6-0.7-1.1-1.3-1.4
	c-0.6-0.3-1.2-0.3-1.8,0c-0.3,0.1-0.6,0.3-0.9,0.6c-0.3-0.5-0.7-0.8-1.2-1c-0.6-0.3-1.2-0.3-1.8,0c-1.2,0.5-1.8,1.9-1.3,3.2l0.2,0.5
	c-0.4,0-0.7,0-1,0.1c-1.2,0.5-1.8,1.9-1.3,3.2l2.7,6.9l-1.3-0.8c-0.6-0.4-1.2-0.5-1.7-0.4c-0.6,0.1-1.2,0.5-1.6,1
	C-0.3,25.4-0.1,27,1,27.8c0.4,0.4,5.2,4.2,6.9,5.5c1.3,0.9,2.9,1.4,4.5,1.4c1.1,0,2.2-0.2,3.2-0.6c1.6-0.7,3-1.8,3.9-3.2
	c0.4,0.3,0.9,0.5,1.5,0.7c1,0.3,2,0.5,3,0.5c1.8,0,3.5-0.6,4.9-1.6c1.8-1.3,6.6-5.3,6.8-5.5c0.5-0.4,0.8-1,0.9-1.6
	C36.8,22.6,36.6,22,36.2,21.5z M20.3,29.4c0.9-2.1,1-4.4,0.2-6.6l-2.2-5.6l1.7-4.8c0.2-0.6,0.8-0.9,1.3-0.7c0.3,0.1,0.5,0.3,0.6,0.5
	c0.1,0.3,0.1,0.6,0,0.8l-1.5,4.2c-0.1,0.3,0.1,0.7,0.4,0.9l0.1,0h0c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.7-0.5l1.5-4.2l1.1-2.9
	c0.2-0.6,0.8-0.9,1.3-0.7c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.3,0.1,0.6,0,0.9l-2.1,6.1c-0.1,0.4,0.1,0.7,0.4,0.9l0.1,0h0
	c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.7-0.5l2.2-6.1l0.6-1.7c0.2-0.6,0.8-0.9,1.3-0.7c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.3,0.1,0.6,0,0.8
	l-0.9,2.5c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0.1l-1.9,5.3c-0.1,0.3,0.1,0.7,0.4,0.9l0.1,0h0c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.7-0.5
	l1.9-5.4c0.2-0.5,0.9-0.8,1.3-0.6c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.3,0.1,0.6,0,0.8L29.9,22c-0.4,0.1-0.7,0.3-0.9,0.4
	c-2.6-1-4.1,0.2-5.2,1.1l-0.1,0c-0.1,0.1-0.2,0.3-0.3,0.5c0,0.2,0,0.4,0.1,0.5c0.2,0.3,0.7,0.3,1,0.1c1.1-0.9,2.1-1.7,4.1-0.8
	c0.2,0.1,0.3,0.1,0.5,0c0.1,0,3-1.2,4-1.6c0.9-0.3,1.5-0.3,1.9,0.2c0.2,0.2,0.3,0.5,0.2,0.8c0,0.3-0.2,0.5-0.4,0.7l0,0
	c-0.2,0.1-5.1,4.2-6.7,5.5c-1.8,1.4-4.3,1.7-6.6,0.9c-0.4-0.1-0.8-0.3-1.3-0.6C20.3,29.5,20.3,29.5,20.3,29.4z M7.1,26.9
	c0.2-0.2,0.3-0.5,0.2-0.8l-3.5-8.9c-0.2-0.6,0-1.2,0.5-1.4c0.2-0.1,0.4-0.1,0.7,0c0.2,0.1,0.5,0.3,0.6,0.6l1.9,4.8
	c0.1,0.3,0.4,0.5,0.6,0.5c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.3-0.2,0.4-0.4s0.1-0.4,0-0.5l-1.8-4.6c0-0.1,0-0.1,0-0.1l-1-2.6
	c-0.2-0.6,0-1.2,0.6-1.4c0.2-0.1,0.5-0.1,0.8,0c0.3,0.1,0.5,0.3,0.6,0.6l2.8,7.3c0.1,0.3,0.4,0.5,0.6,0.5c0.1,0,0.2,0,0.3-0.1
	c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.2,0.1-0.4,0-0.6L9.7,14l0-0.1c-0.2-0.6,0-1.2,0.6-1.4c0.2-0.1,0.5-0.1,0.8,0
	c0.3,0.1,0.5,0.3,0.6,0.6l1.1,2.8c0,0.1,0,0.1,0.1,0.1l1.5,3.9c0.1,0.4,0.6,0.5,0.9,0.4c0.2-0.1,0.3-0.2,0.4-0.3
	c0.1-0.2,0.1-0.4,0-0.6L14,15.5c-0.2-0.6,0-1.2,0.6-1.4c0.2-0.1,0.5-0.1,0.8,0c0.3,0.1,0.5,0.3,0.6,0.6l3.3,8.6
	c1.3,3.8-0.5,7.9-4.1,9.4c-2.2,0.9-4.6,0.7-6.5-0.6C7,30.9,2.1,26.9,2,26.8c-0.5-0.4-0.6-1-0.2-1.5c0.1-0.2,0.4-0.4,0.7-0.4
	c0,0,0.1,0,0.1,0c0.2,0,0.4,0.1,0.6,0.2l3.2,1.9C6.5,27.1,6.8,27.1,7.1,26.9z M22.8,2.6c0.3,0.3,0.4,0.8,0.1,1.1l-1.4,1.6
	c-0.3,0.3-0.8,0.4-1.1,0.1c-0.2-0.1-0.3-0.3-0.3-0.5s0.1-0.4,0.2-0.6l1.4-1.6c0.1-0.2,0.3-0.3,0.5-0.3S22.6,2.4,22.8,2.6z M10.8,5
	c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.3-0.8,0.4-1.1,0.1L8.1,4.8C7.8,4.5,7.8,4,8.1,3.7c0.1-0.2,0.3-0.3,0.5-0.3
	c0.2,0,0.4,0.1,0.6,0.2L10.8,5z M16,0.7l0.2,2.2c0,0.4-0.3,0.8-0.7,0.8c-0.4,0-0.8-0.3-0.8-0.7l-0.2-2.2c0-0.4,0.3-0.8,0.7-0.8
	C15.6,0,16,0.3,16,0.7z M15.9,40.9c-0.4-0.2-0.5-0.7-0.3-1.1l1-1.9c0.2-0.4,0.7-0.5,1.1-0.3c0.2,0.1,0.3,0.3,0.4,0.5
	c0.1,0.2,0,0.4-0.1,0.6l-1,1.9c-0.1,0.2-0.3,0.3-0.5,0.4C16.3,41,16.1,41,15.9,40.9z M27.1,35.9c-0.2-0.1-0.3-0.3-0.4-0.5
	c-0.1-0.2,0-0.4,0.1-0.6c0.2-0.4,0.7-0.5,1.1-0.3l1.9,1c0.4,0.2,0.5,0.7,0.3,1.1c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0-0.6-0.1
	L27.1,35.9z M22.9,41.2l-0.7-2.1c-0.1-0.4,0.1-0.9,0.5-1c0.4-0.1,0.9,0.1,1,0.5l0.7,2.1c0.1,0.4-0.1,0.9-0.5,1
	C23.5,41.8,23,41.6,22.9,41.2z'
    />
  </svg>
);

export default SVG;
