import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '12', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''} stroke`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <circle className='stroke' cx='12' cy='12' r='10' />
    <line className='stroke' x1='2' y1='12' x2='22' y2='12' />
    <path className='stroke' d='M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z' />
  </svg>
);

export default SVG;
