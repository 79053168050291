import IconType from './type';

const SVG = ({ fill = '', width = '16px', height = '16px', className = '', viewBox = '0 0 511.939 511.939' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <path
        fill={fill}
        d='m496.266 13.851c-17.354-16.292-51.8-15.123-77.645-11.275-35.476 5.284-75.389 18.795-112.385 38.045-5.753 2.994-8.931 9.346-7.877 15.745 5.035 30.558 21.384 62.801 46.208 89.642l-20.567 20.567-73.394-73.394c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l73.394 73.394-26.787 26.787-65.394-65.394c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l65.394 65.394-26.787 26.787-49.394-49.394c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l49.394 49.394-26.787 26.787-25.394-25.394c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l25.394 25.394-6.537 6.537c-15.239 3.369-71.214 11.373-134.308-1.246-9.251-1.857-17.942 5.228-17.942 14.709 0 46.583 20.757 87 66.036 93.964 6.968 45.306 47.418 66.036 93.964 66.036 9.447 0 16.566-8.651 14.709-17.942-12.622-63.099-4.616-119.072-1.246-134.308l6.537-6.538 25.394 25.394c2.929 2.929 6.768 4.394 10.606 4.394 13.242 0 20.085-16.129 10.606-25.606l-25.394-25.394 26.788-26.788 49.394 49.394c2.929 2.929 6.768 4.394 10.606 4.394 13.242 0 20.085-16.129 10.606-25.606l-49.394-49.394 26.788-26.788 65.394 65.394c2.929 2.929 6.768 4.394 10.606 4.394 13.242 0 20.085-16.129 10.606-25.606l-65.394-65.394 26.788-26.788 73.394 73.394c2.929 2.929 6.768 4.394 10.606 4.394 13.242 0 20.085-16.129 10.606-25.606l-73.394-73.394 21.316-21.316c26.364 21.117 59.009 36.271 95.869 40.23 6.603.712 12.924-3.017 15.472-9.202 14.928-36.247 25.908-72.658 30.916-102.524 6.766-40.332 2.67-66.868-12.519-81.125zm-382.803 456.052c-12.424-8.489-18.463-20.957-18.463-38.115 0-8.284-6.716-15-15-15-17.158 0-29.626-6.039-38.115-18.463-5.091-7.451-8.663-17.247-10.487-28.493 35.974 5.24 74.481 5.534 109.852.707-4.811 35.229-4.551 73.746.707 109.852-11.247-1.825-21.044-5.397-28.494-10.488zm365.737-379.889c-4.157 24.788-12.854 54.692-24.729 85.222-63.304-11.843-111.111-64.429-124.198-113.11 31.124-15.029 63.688-25.545 92.769-29.876 33.441-4.983 49.092.095 52.693 3.475 5.249 4.928 8.917 21.777 3.465 54.289z'
      />
      <path
        fill={fill}
        d='m406.7 76.857c-1.586-8.38-10.284-14.038-19.061-11.42-8.494 2.768-12.231 11.167-9.99 18.71 2.843 8.695 11.36 12.182 18.71 9.99 8.115-2.644 11.758-10.455 10.341-17.28z'
      />
    </g>
  </svg>
);

export default SVG;
