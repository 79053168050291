import styled from 'styled-components';

const ItemCardInner = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: ${(props) => props?.theme?.transitions?.default};
  padding: 1.5rem 0;
  a {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: ${(props) => props.theme.zLayers.default};
    span {
      display: none;
    }
  }
  @media only screen and (max-width: ${(props) => props?.theme?.breakpoints?.mobile}) {
    align-items: center;
  }
`;

export default ItemCardInner;
