import IconType from './type';

const Icon = ({ style = {}, width = '20', height = '20', className = '', viewBox = '0 0 20 20' }: IconType) => (
  <svg className={`svg-icon ${className || ''}`} style={style} width={width} height={height} viewBox={viewBox} xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.734 16.381l-7.5-15a2.5 2.5 0 00-4.472 0l-7.5 15A2.5 2.5 0 002.5 20h15a2.5 2.5 0 002.236-3.618zm-8.486.493a.624.624 0 01-.625.625H9.374a.624.624 0 01-.625-.625v-1.25A.624.624 0 019.374 15h1.25a.624.624 0 01.625.625zm0-5a.624.624 0 01-.625.625H9.374a.624.624 0 01-.625-.625V5.625A.625.625 0 019.374 5h1.25a.625.625 0 01.625.625z'
      data-name='Path 488'
      transform='translate(-.001)'
    />
  </svg>
);

export default Icon;
