import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon stroke ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path fill='none' stroke={fill} d='M4.3,3.1H9c0.4,0,0.7,0.9,0.7,2v14c0,1.1-0.3,2-0.7,2H4.3c-0.4,0-0.7-0.9-0.7-2v-14C3.6,4,3.9,3.1,4.3,3.1z' />
    <path
      fill='none'
      stroke={fill}
      d='M13.6,3h5.9c0.5,0,0.8,0.5,0.8,1.1v7.4c0,0.6-0.4,1.1-0.8,1.1h-5.9c-0.5,0-0.8-0.5-0.8-1.1V4.1
	C12.8,3.5,13.1,3,13.6,3z'
    />
    <path
      fill='none'
      stroke={fill}
      d='M13.6,15.6h5.9c0.5,0,0.8,0.3,0.8,0.6v4.2c0,0.3-0.4,0.6-0.8,0.6h-5.9c-0.5,0-0.8-0.3-0.8-0.6v-4.2
	C12.8,15.9,13.1,15.6,13.6,15.6z'
    />
  </svg>
);

export default SVG;
