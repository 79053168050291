import IconType from './type';

const SVG = ({ style = {}, width = '31.1', height = '33.1', className = '', viewBox = '0 0 31.1 33.1' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path d='M10.4 30.4c-2.1 0-4.2-.8-5.8-2.4-3.2-3.2-3.2-8.3 0-11.5l2.7-2.7c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-2.7 2.7c-2 2-2 5.3 0 7.3s5.3 2 7.3 0c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1-1.5 1.6-3.6 2.4-5.7 2.4zM23.1 20.5c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1l2.7-2.7c2-2 2-5.3 0-7.3s-5.3-2-7.3 0c-.6.6-1.5.6-2.1 0-.6-.6-.6-1.5 0-2.1 3.2-3.2 8.3-3.2 11.5 0 3.2 3.2 3.2 8.3 0 11.5L24.2 20c-.3.3-.7.5-1.1.5zM20.7 30c-.6 0-1.1-.3-1.4-.9L9.5 5.9c-.3-.8 0-1.6.8-2 .8-.3 1.6 0 2 .8l9.8 23.2c.3.8 0 1.6-.8 2-.2.1-.4.1-.6.1z' />
  </svg>
);

export default SVG;
