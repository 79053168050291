import IconType from './type';

const SVG = ({ width = '16px', height = '16px', className = '', viewBox = '0 0 80.24 40.89' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g transform='translate(-922 -757)'>
      <path
        d='M14.2,50.1A3,3,0,0,1,12,45c9.42-9.82,23.73-15.46,39.25-15.46S81.06,35.18,90.48,45a3,3,0,1,1-4.32,4.16C78,40.64,64.95,35.57,51.28,35.57S24.53,40.66,16.36,49.18a3,3,0,0,1-2.16.92Z'
        transform='translate(910.858 727.46)'
      />
      <path
        d='M51.26,70.43A14.74,14.74,0,1,1,66,55.69,14.74,14.74,0,0,1,51.26,70.43Zm0-23.49A8.74,8.74,0,1,0,60,55.69a8.74,8.74,0,0,0-8.74-8.75Z'
        transform='translate(910.858 727.46)'
      />
    </g>
  </svg>
);

export default SVG;
