/**
 * Draws the rows where items within collections are listed.
 */
import styled from 'styled-components';
import Flickity from 'react-flickity-component';
import { Hit } from '../../../api/wrappers/SearchAPI';
import SearchResultsRowCarousel from './SearchResultsRowCarousel';
import { calculateTotalHitCount } from '../searchMethods';

const options = {
  cellAlign: 'left',
  pageDots: false,
  prevNextButtons: true,
  initialIndex: 0,
  draggable: false,
  contain: true,
  groupCells: '100%',
};

export interface SearchResultsRowProps {
  className?: string;
  title: string;
  hits: Hit[];
  makeChild: (hit: Hit, i: number) => JSX.Element | JSX.Element[];
  tall?: boolean;
  customHitNumberReducer?: boolean;
  testId?: string;
}

const SearchResultsRow = ({ className, title, hits, makeChild, tall = false, customHitNumberReducer, testId }: SearchResultsRowProps) => (
  <section className={className} data-testid={testId}>
    <h2>
      {title} ({customHitNumberReducer ? calculateTotalHitCount(hits) : hits.length})
    </h2>
    <SearchResultsRowCarousel tall={tall}>
      <Flickity options={options}>{hits.map(makeChild)}</Flickity>
    </SearchResultsRowCarousel>
  </section>
);

interface StyledSearchResultsRowProps {
  isCollections?: boolean;
}

const StyledSearchResultsRow = styled(SearchResultsRow)<StyledSearchResultsRowProps>`
  margin-bottom: 10px;
  position: relative;

  ${(props) =>
    props.isCollections &&
    `
    > div > div > div > div > div > div {
      height: 230px;
      width: 230px;
    }
  `}

  &:not(:last-of-type) {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: -25px;
      left: 0;
      height: 1px;
      background: var(--contrast);
      opacity: 0.1;
      @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
        width: calc(100% - 50px);
      }

      @media (max-width: ${(props) => props.theme.breakpoints.small}) {
        width: calc(100% - 30px);
      }
    }
  }

  > h2 {
    color: var(--grey-7);
    font-size: 17px;
    margin-bottom: 0;
    @media (max-width: ${(props) => props.theme.breakpoints.small}) {
      margin-bottom: 10px;
    }
  }
`;

export default StyledSearchResultsRow;
