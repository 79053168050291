import styled from 'styled-components';
import { Item } from '../../../types/library';
import CollectionTileImage from './CollectionTileImage';

interface CollectionTileImageGridProps {
  className?: string;
  items?: Item[];
}

const CollectionTileImageGrid = ({ className, items }: CollectionTileImageGridProps) => (
  <div className={className}>
    {[...Array(4)].map((arrEl: number, i: number) => {
      if (items && items[i]) return <CollectionTileImage item={items[i]} key={arrEl} />;
      return <figure />;
    })}
  </div>
);

CollectionTileImageGrid.defaultProps = {
  className: '',
};

interface StyledCollectionTileImageGridProps {
  small?: boolean;
}

const StyledCollectionTileImageGrid = styled(CollectionTileImageGrid)<StyledCollectionTileImageGridProps>`
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.small &&
    `
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  figure {
    &:nth-child(1) {
      background-color: var(--grey-2);
    }
    &:nth-child(2) {
      background-color: var(--grey-3);
    }
    &:nth-child(3) {
      background-color: var(--grey-4);
    }
    &:nth-child(4) {
      background-color: var(--grey-5);
    }
  }
`;

export default StyledCollectionTileImageGrid;
