import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 91.7422 100.1865' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M91.7422,95.1865c0,2.7617-2.2383,5-5,5H5c-2.7617,0-5-2.2383-5-5s2.2383-5,5-5h81.7422
	C89.5039,90.1865,91.7422,92.4248,91.7422,95.1865z M42.3232,79.6973c0.9385,0.9453,2.2158,1.4766,3.5479,1.4766
	s2.6094-0.5312,3.5479-1.4766l29.0176-29.2188c1.9453-1.96,1.9346-5.125-0.0244-7.0713c-1.959-1.9453-5.124-1.9365-7.0713,0.0244
	L50.8711,64.0434V5c0-2.7617-2.2383-5-5-5s-5,2.2383-5,5v59.0436l-20.4707-20.612c-1.9463-1.9609-5.1113-1.9697-7.0713-0.0244
	c-1.959,1.9463-1.9697,5.1123-0.0244,7.0713L42.3232,79.6973z'
    />
  </svg>
);

export default SVG;
