import IconType from './type';

const SVG = ({ style = {}, fill = '#fff', width = '13', height = '12', className = '', viewBox = '0 0 28.1 28.2' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      className='fill'
      d='M26.3,11.8H1.8c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h24.5c0.8,0,1.5,0.7,1.5,1.5v0
        C27.8,11.1,27.1,11.8,26.3,11.8z'
    />
    <path
      fill={fill}
      className='fill'
      d='M26.3,19.4H1.8c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h24.5c0.8,0,1.5,0.7,1.5,1.5v0
        C27.8,18.8,27.1,19.4,26.3,19.4z'
    />
  </svg>
);

export default SVG;
