import IconType from './type';

const SVG = ({ style = {}, width = '24', height = '24', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g fill='none' stroke='currentColor' strokeLinecap='round' strokeWidth='2.5' data-name='Group 92' transform='translate(-520.871 -303.094)'>
      <path
        strokeMiterlimit='10'
        d='M543.368 311.114v10.633a3.844 3.844 0 01-3.844 3.844h-13.559a3.844 3.844 0 01-3.844-3.844v-13.559a3.844 3.844 0 013.844-3.844H537.2'
        data-name='Path 38'
      />
      <path strokeMiterlimit='10' d='M11.257 0L0 11.257' data-name='Line 12' transform='translate(531.176 305.83)' />
      <path strokeLinejoin='round' d='M536.9 317.857h-6.716v-6.716' data-name='Path 39' />
    </g>
  </svg>
);

export default SVG;
