import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 332 448.75' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <path
        fill={fill}
        d='M261 0l0 36 71 0 0 87 -143 71 0 56 25 0 0 109c-23,0 -46,0 -69,0l0 -109 24 0 0 -68 143 -71 0 -55 -51 0 0 35 -261 0c0,-30 0,-61 0,-91l261 0zm-96 270l0 69 29 0 0 -69 -29 0zm76 -250l-221 0 0 51 221 0 0 -51z'
      />
    </g>
  </svg>
);

export default SVG;
