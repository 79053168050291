import ImagePlaceholder from 'components/UI/ImagePlaceholder/ImagePlaceholder';
import { getPresignFromCache } from 'helpers/cache';
import { b64DecodeUnicode } from 'helpers/Utils';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { filters } from 'theming/mixins';
import { TileProps } from 'types/tile';

interface LinkTileImageProps {
  className?: string;
  tile: TileProps;
}

const LinkTileImage = ({ className, tile }: LinkTileImageProps) => {
  const isColorTile = tile.colour !== '';
  const src = tile.thumbnailUrlCroppedSquare || tile.thumbnailUrl;
  const [imageUrl, setImageUrl] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!src.startsWith('http') && src !== '') {
      setImageUrl(getPresignFromCache(b64DecodeUnicode(src)));
    } else {
      setImageUrl(src);
    }
  }, [src]);
  return (
    <div className={`${className} ${(isColorTile || isLoaded) && tile.filter}`}>
      {!isColorTile && imageUrl !== '' && (
        <>
          <ImagePlaceholder active={!isLoaded} />
          <img src={imageUrl} alt='' onLoad={() => setIsLoaded(true)} />
        </>
      )}
    </div>
  );
};

const StyledLinkTileImage = styled(LinkTileImage)`
  ${filters}
  pointer-events: none;
  height: 100%;
  width: 100%;
  background: var(--grey-2);
  ${(props) => props.tile.colour && `background: ${props.tile.colour};`}

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export default StyledLinkTileImage;
