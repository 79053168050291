import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '12', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''} stroke`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path className='stroke' d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2' />
    <circle className='stroke' cx='12' cy='7' r='4' />
  </svg>
);

export default SVG;
