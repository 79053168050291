import IconType from './type';

const SVG = ({ style = {}, width = '16', height = '16', className = '', viewBox = '0 0 22 22' }: IconType) => (
  <svg
    className={`svg-icon ${className || ''}`}
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
  >
    <path
      d='M21 5h-2.4c.2-.5.4-1 .4-1.5C19 1.6 17.4 0 15.5 0c-2.3 0-3.7 1.6-4.5 3.1C10.2 1.6 8.8 0 6.5 0 4.6 0 3 1.6 3 3.5c0 .5.1 1 .3 1.5H1c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1h1v9c0 .6.4 1 1 1h16c.6 0 1-.4 1-1v-9h1c.6 0 1-.4 1-1V6c0-.6-.5-1-1-1zm-5.5-3c.8 0 1.5.7 1.5 1.5S16.3 5 15.5 5h-3.2c.5-1.2 1.5-3 3.2-3zM5 3.5C5 2.7 5.7 2 6.5 2c1.7 0 2.7 1.8 3.2 3H6.5C5.7 5 5 4.3 5 3.5zM2 7h8v3H2V7zm2 5h6v8H4v-8zm14 8h-6v-8h6v8zm2-10h-8V7h8v3z'
      className='st0'
    />
  </svg>
);

export default SVG;
