import IconType from './type';

const SVG = ({ fill = '', width = '16px', height = '16px', className = '', viewBox = '0 0 456.556 456.556' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <path
        fill={fill}
        d='M97.564,79.443H13.428C6.011,79.443,0,73.428,0,66.015s6.011-13.428,13.428-13.428h84.136
		c7.417,0,13.428,6.015,13.428,13.428S104.981,79.443,97.564,79.443z'
      />
      <path
        fill={fill}
        d='M145.643,127.518c-33.916,0-61.507-27.591-61.507-61.504c0-33.92,27.591-61.511,61.507-61.511s61.507,27.591,61.507,61.511
		C207.15,99.928,179.559,127.518,145.643,127.518z M145.643,31.36c-19.107,0-34.651,15.547-34.651,34.654
		s15.544,34.647,34.651,34.647s34.651-15.54,34.651-34.647S164.75,31.36,145.643,31.36z'
      />
      <path
        fill={fill}
        d='M443.128,79.443H242.433c-7.417,0-13.428-6.015-13.428-13.428s6.011-13.428,13.428-13.428h200.694
		c7.417,0,13.428,6.015,13.428,13.428S450.545,79.443,443.128,79.443z'
      />
      <path
        fill={fill}
        d='M325.936,289.782c-33.917,0-61.507-27.591-61.507-61.504s27.591-61.504,61.507-61.504s61.507,27.591,61.507,61.504
		S359.853,289.782,325.936,289.782z M325.936,193.631c-19.107,0-34.651,15.54-34.651,34.647s15.544,34.647,34.651,34.647
		s34.651-15.54,34.651-34.647S345.043,193.631,325.936,193.631z'
      />
      <path
        fill={fill}
        d='M229.149,241.706H13.428C6.011,241.706,0,235.691,0,228.278s6.011-13.428,13.428-13.428h215.721
		c7.417,0,13.428,6.015,13.428,13.428S236.566,241.706,229.149,241.706z'
      />
      <path
        fill={fill}
        d='M443.128,241.706h-69.113c-7.417,0-13.428-6.015-13.428-13.428s6.011-13.428,13.428-13.428h69.113
		c7.417,0,13.428,6.015,13.428,13.428S450.545,241.706,443.128,241.706z'
      />
      <path
        fill={fill}
        d='M130.62,452.052c-33.916,0-61.507-27.591-61.507-61.511c0-33.913,27.591-61.504,61.507-61.504s61.507,27.591,61.507,61.504
		C192.127,424.461,164.536,452.052,130.62,452.052z M130.62,355.894c-19.107,0-34.651,15.54-34.651,34.647
		s15.544,34.654,34.651,34.654s34.651-15.547,34.651-34.654S149.727,355.894,130.62,355.894z'
      />
      <path
        fill={fill}
        d='M443.128,403.97H227.407c-7.417,0-13.428-6.015-13.428-13.428c0-7.413,6.011-13.428,13.428-13.428h215.721
		c7.417,0,13.428,6.015,13.428,13.428C456.556,397.955,450.545,403.97,443.128,403.97z'
      />
      <path
        fill={fill}
        d='M82.541,403.97H13.428C6.011,403.97,0,397.955,0,390.541c0-7.413,6.011-13.428,13.428-13.428h69.113
		c7.417,0,13.428,6.015,13.428,13.428C95.969,397.955,89.958,403.97,82.541,403.97z'
      />
    </g>
  </svg>
);

export default SVG;
