import IconType from './type';

const SVG = ({ style = {}, width = '24', height = '24', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className}`}
    xmlns='http://www.w3.org/2000/svg'
    fillRule='evenodd'
    clipRule='evenodd'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <g>
      <path d='M19,4l-10,8l10,8l0,-16Z' />
      <path d='M5,5l0,14' />
    </g>
  </svg>
);

export default SVG;
