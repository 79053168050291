import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '16', height = '16', className = '', viewBox = '0 0 34 24.5447' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M33.9997,1.9694h-6.8271V0.0001H6.8279v1.9692h-6.8281v20.606h6.8281v1.9692h20.3447v-1.9692h6.8271V1.9694z
	 M2.9997,19.5753V4.9694h3.8281v14.606H2.9997z M24.1726,21.5446H9.8279V3.0001h14.3447V21.5446z M30.9997,19.5753h-3.8271V4.9694
	h3.8271V19.5753z'
    />
  </svg>
);

export default SVG;
