import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 24.57 25.11' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M1526.9,576.92a12.28,12.28,0,0,0-7.71-11.26,7.05,7.05,0,0,0-4.57-12.42,7.07,7.07,0,0,0-7.06,7.06,7,7,0,0,0,2.49,5.36,12.23,12.23,0,0,0-7.71,11.4,1.28,1.28,0,0,0,1.28,1.29h22a1.29,1.29,0,0,0,1.29-1.29A1.13,1.13,0,0,0,1526.9,576.92Zm-2.66-1.14H1505a9.71,9.71,0,0,1,19.25,0Zm-14.11-15.48a4.49,4.49,0,1,1,4.49,4.48A4.49,4.49,0,0,1,1510.13,560.3Z'
      transform='translate(-1502.34 -553.24)'
    />
  </svg>
);

export default SVG;
