import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 45.38 45.38' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M45.38,10.63c0-0.38-0.15-0.73-0.42-1l-9.22-9.21C35.48,0.15,35.12,0,34.74,0c-0.37,0-0.74,0.15-1,0.42l-5.81,5.81
		c0,0,0,0-0.01,0c0,0,0,0,0,0L3.71,30.46c-0.13,0.13-0.23,0.28-0.31,0.45c-0.01,0.03-0.02,0.05-0.03,0.08
		c-0.02,0.04-0.04,0.08-0.05,0.13L0.05,43.61c-0.13,0.49,0.01,1.01,0.37,1.36c0.35,0.35,0.88,0.5,1.36,0.37l12.49-3.27
		c0.05-0.01,0.09-0.04,0.13-0.05c0.03-0.01,0.06-0.02,0.09-0.04c0.16-0.07,0.3-0.16,0.42-0.28l30.05-30.06
		C45.23,11.37,45.38,11.01,45.38,10.63z M34.74,3.42l7.21,7.21l-3.81,3.81l-7.21-7.21L34.74,3.42z M13.9,38.69l-7.21-7.21
		L28.93,9.24l7.21,7.21L13.9,38.69z M5.43,34.23l0.65,0.65l5.08,5.08L3.4,41.98L5.43,34.23z'
    />
  </svg>
);

export default SVG;
