import IconType from './type';

const SVG = ({ width = '32', height = '32', className = '', viewBox = '0 0 512 512' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <g>
        <path d='M506.121,308.276c-0.86-2.494-57.202-150.742-57.47-151.372c-7.743-19.95-23.274-35.546-42.276-43.829    c-0.216-0.559-7.655-19.864-7.898-20.412C389.815,73.125,370.3,60.5,348.76,60.5c-24.152,0-45.614,16.206-52.191,39.409    C296.191,101.24,296,102.617,296,104v67.5h-80V104c0-1.383-0.191-2.76-0.568-4.091C208.854,76.706,187.392,60.5,163.24,60.5    c-23.824,0-43.328,15.536-49.998,32.841l-7.618,19.736c-19.905,8.678-35.112,24.927-42.494,44.37    C2.074,318.145,6.779,305.666,5.88,308.273C1.978,319.585,0,331.438,0,343.5c0,59.551,48.449,108,108,108    c53.419,0,97.897-38.987,106.492-90h83.017c8.595,51.013,53.073,90,106.492,90c59.551,0,108-48.449,108-108    C512,331.437,510.022,319.585,506.121,308.276z M420.815,168.101c0.063,0.168,0.13,0.334,0.2,0.499l29.395,77.368    c-41.478-19.793-91.744-11.221-124.41,22.91V185.5c0-27.019,21.981-49,49-49C395.231,136.5,413.644,149.2,420.815,168.101z     M296,289.5h-80v-88h80V289.5z M216,319.5h80v12h-80V319.5z M348.76,90.5c9.454,0,18.768,5.243,22.952,16.083    c-17.227,0.71-33.037,6.963-45.712,17.018v-17.258C329.47,96.929,338.579,90.5,348.76,90.5z M163.24,90.5    c10.181,0,19.29,6.429,22.76,15.844v17.258c-12.675-10.055-28.486-16.308-45.712-17.018C144.397,95.936,153.567,90.5,163.24,90.5z     M91.185,168.101C98.356,149.2,116.769,136.5,137,136.5c27.019,0,49,21.981,49,49v83.377    c-32.662-34.126-82.929-42.704-124.41-22.91C105.363,130.758,85.705,182.489,91.185,168.101z M108,421.5    c-43.009,0-78-34.991-78-78c0-43.911,35.986-78,78-78c43.009,0,78,34.991,78,78S151.009,421.5,108,421.5z M404,421.5    c-43.009,0-78-34.991-78-78s34.991-78,78-78c42.255,0,78,34.271,78,78C482,386.509,447.009,421.5,404,421.5z' />
      </g>
    </g>
  </svg>
);

export default SVG;
