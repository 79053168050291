import React from 'react';
import styled from 'styled-components';

// TODO: Remove classNameOptions prop and reference the component directly in the styles

interface CardActionsButtonsProps {
  // eslint-disable-next-line react/no-unused-prop-types
  position?: 'left' | 'right';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/no-unused-prop-types
  innerRef?: any;
  classNameMenu?: string;
  children?: React.ReactNode;
  className?: string;
}

const CardActionsButtons = ({ className, classNameMenu, children }: CardActionsButtonsProps) => (
  <div className={`${className} ${classNameMenu}`}>{children}</div>
);

CardActionsButtons.defaultProps = {
  className: '',
  position: 'left',
  innerRef: '',
  classNameMenu: 'menu',
  children: null,
};

const StyledCardActionsButtons = styled(CardActionsButtons)`
  position: absolute;
  opacity: 1;
  height: 100%;
  list-style: none;
  z-index: 1;
  flex-direction: column;
  display: flex;
  width: 2.2em;
  ${(props) =>
    props.position === 'right' &&
    `
    left: initial;
    right: 5px;
  `}
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 55%;
    right: 0;
    left: initial;
  }
`;

export default StyledCardActionsButtons;
