import IconType from './type';

const SVG = ({ fill = '', width = '16px', height = '16px', className = '', viewBox = '0 0 51.77 59.24' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='UsHzpt_1_'>
      <g>
        <path
          fill={fill}
          d='M23.45,2.23c0.57-1.64,1.18-2.22,2.34-2.23c1.19-0.01,1.77,0.52,2.41,2.25c1,0.17,2.02,0.32,3.02,0.53
			c4.3,0.87,8.09,2.78,11.43,5.61c0.55,0.46,0.63,0.97,0.27,1.4c-0.34,0.4-0.91,0.42-1.44-0.02c-2.22-1.86-4.65-3.38-7.41-4.27
			c-1.74-0.56-3.56-0.87-5.35-1.29c-0.16-0.04-0.33,0.01-0.55,0.02c0,0.44,0,0.84,0,1.34c0.76,0.15,1.51,0.3,2.27,0.44
			c3.66,0.71,6.9,2.32,9.78,4.68c0.61,0.5,0.72,1,0.34,1.44c-0.36,0.43-0.9,0.44-1.5-0.04c-2.9-2.35-6.17-3.87-9.86-4.47
			c-0.31-0.05-0.63-0.07-1.02-0.12c0,5.61,0,11.16,0,16.72c2.91,2.02,2.79,5.87-0.03,7.81c0,2.01,0,4.04,0,6.01
			c1.66-0.88,3.32-1.76,5.08-2.69c-0.12-1.28,0.29-2.46,1.73-3.05c1.29-0.53,2.34-0.03,3.26,0.95c2.58-0.92,5.09-0.83,7.6,0.79
			c0.23-1.13,0.51-2.17,0.63-3.22c0.66-5.63-0.69-10.74-4.1-15.3c-0.19-0.26-0.4-0.5-0.56-0.78c-0.27-0.47-0.22-0.91,0.24-1.23
			c0.46-0.31,0.93-0.2,1.23,0.23c0.82,1.16,1.71,2.29,2.38,3.53c4.66,8.75,3.46,18.71-3.13,26.11c-2.13,2.39-4.7,4.21-7.67,5.46
			c-0.29,0.12-0.57,0.27-0.94,0.44c0.15,0.46,0.29,0.89,0.44,1.36c2.46-0.9,4.62-2.14,6.57-3.72c4.29-3.47,7.11-7.9,8.35-13.25
			c1.39-5.96,0.64-11.7-2.32-17.09c-0.75-1.37-1.75-2.6-2.6-3.91c-0.19-0.29-0.34-0.68-0.31-1c0.03-0.24,0.38-0.6,0.6-0.61
			c0.34-0.01,0.82,0.13,1.01,0.38c0.96,1.27,1.95,2.54,2.73,3.91c2.23,3.91,3.37,8.14,3.36,12.65c-0.02,7.05-2.49,13.12-7.36,18.21
			c-2.51,2.62-5.47,4.6-8.85,5.93c-0.2,0.08-0.39,0.19-0.66,0.33c0.33,1.09,0.65,2.16,0.97,3.23c0.1,0.33,0.2,0.66,0.26,0.99
			c0.24,1.31-0.63,2.41-1.96,2.47c-0.78,0.04-1.57,0.01-2.35,0.01c-4.59,0-9.17-0.05-13.76,0.03c-1.79,0.03-2.96-1.36-2.29-3.18
			c0.4-1.08,0.67-2.2,0.99-3.31c0.02-0.07,0-0.14,0-0.13c-12.01-5.67-18-14.86-16.54-28.26C1.22,15.16,9.27,3.71,23.45,2.23z
			 M23.49,7.56c-0.18-0.01-0.31-0.04-0.42-0.02c-0.75,0.13-1.5,0.24-2.24,0.42c-5.19,1.3-9.3,4.18-12.29,8.61
			c-1.9,2.82-2.98,5.94-3.36,9.31c-0.09,0.77-0.49,1.15-1.04,1.08c-0.57-0.06-0.83-0.5-0.77-1.28c0.01-0.12,0.02-0.24,0.04-0.37
			c0.58-4.36,2.22-8.27,5.04-11.65c3.18-3.81,7.19-6.31,12.01-7.5c0.99-0.24,2-0.41,3.02-0.61c0-0.47,0-0.9,0-1.33
			C14.63,4.73,3.3,12.06,1.87,25.77C0.6,37.95,8.59,47.71,17.42,50.62c0.12-0.43,0.24-0.86,0.36-1.31c-0.34-0.16-0.6-0.28-0.87-0.4
			c-0.29-0.13-0.59-0.24-0.88-0.38C9.74,45.36,5.67,40.38,3.89,33.55c-0.29-1.13-0.42-2.31-0.58-3.48C3.23,29.52,3.49,29.09,4.07,29
			c0.54-0.08,0.89,0.2,1,0.74c0.05,0.24,0.09,0.48,0.12,0.72c0.91,7.02,4.46,12.28,10.54,15.85c0.81,0.47,1.69,0.81,2.59,1.23
			c0.43-1.37,0.79-2.61,1.2-3.85c0.46-1.42,1.1-1.87,2.6-1.88c0.45,0,0.91,0,1.4,0c0-3.36,0-6.59,0-9.79
			c-2.65-1.82-3.07-5.55-0.04-7.78C23.49,18.69,23.49,13.14,23.49,7.56z M30.31,43.72c-2.98,0-5.89,0-8.89,0
			c-1.36,4.51-2.71,9-4.1,13.6c5.76,0,11.35,0,17.12,0C33.05,52.74,31.69,48.28,30.31,43.72z M45.08,36.03
			c-0.91-1.45-4.21-2.1-6.39-0.89c-0.62,2.02-1.71,2.63-3.77,2.2c-0.53-0.11-1.12,0.06-1.67,0.11c0,0.22-0.01,0.27,0,0.31
			c0.04,0.14,0.08,0.28,0.12,0.42c0.67,2.13,0.68,4.29-0.2,6.33c-0.48,1.11-0.16,1.96,0.24,2.99
			C38.92,45.24,42.77,41.42,45.08,36.03z M25.88,30.78c1.44-0.01,2.61-1.17,2.62-2.61c0.02-1.44-1.2-2.67-2.65-2.67
			c-1.47,0-2.66,1.19-2.66,2.66C23.2,29.6,24.41,30.79,25.88,30.78z M26.21,23.63c0-7.27,0-14.46,0-21.63
			c-0.73-0.2-0.83-0.11-0.83,0.73c0,6.74,0.01,13.48,0.01,20.22c0,0.21,0.03,0.43,0.04,0.68C25.71,23.63,25.94,23.63,26.21,23.63z
			 M31.41,38.11c-1.19,0.97-2.26,1.83-3.28,2.65c0.02,0.44,0.04,0.72,0.05,1.02c1.27,0.14,2.59-0.28,3.62,0.9
			C32.16,41.08,31.98,39.71,31.41,38.11z M26.25,41.77c0-3.07,0-6.07,0-9.14c-0.31,0.02-0.56,0.03-0.81,0.04c0,3.08,0,6.08,0,9.1
			c0.17,0.02,0.29,0.05,0.41,0.05C25.96,41.82,26.08,41.79,26.25,41.77z M35.08,34.72c-0.05,0.48,0.3,0.92,0.8,0.94
			c0.51,0.03,0.84-0.22,0.92-0.73c0.08-0.47-0.28-0.93-0.75-1C35.61,33.86,35.13,34.25,35.08,34.72z'
        />
        <path
          fill={fill}
          d='M23.75,50.06c-0.59,0-1.17,0.02-1.76-0.01c-0.66-0.03-1.05-0.37-1.06-0.89c-0.01-0.54,0.4-0.91,1.09-0.92
			c1.2-0.02,2.4-0.02,3.59,0c0.65,0.01,1.05,0.36,1.06,0.87c0.01,0.51-0.38,0.91-1.02,0.94C25.02,50.09,24.39,50.06,23.75,50.06
			C23.75,50.06,23.75,50.06,23.75,50.06z'
        />
        <path
          fill={fill}
          d='M22.39,55.69c-0.59,0-1.17,0.01-1.76,0c-0.7-0.02-1.09-0.36-1.08-0.92c0.01-0.54,0.38-0.89,1.03-0.9
			c1.22-0.02,2.44-0.02,3.67,0.01c0.65,0.01,1.03,0.38,1.02,0.91c-0.01,0.52-0.4,0.88-1.04,0.9C23.62,55.7,23,55.69,22.39,55.69
			C22.39,55.69,22.39,55.69,22.39,55.69z'
        />
        <path
          fill={fill}
          d='M23.14,51.05c0.59,0,1.18-0.01,1.76,0c0.67,0.02,1.04,0.34,1.05,0.88c0.01,0.54-0.35,0.92-1,0.93
			c-1.22,0.03-2.45,0.03-3.67,0.01c-0.65-0.01-1.01-0.38-1.01-0.92c0-0.55,0.36-0.87,1.03-0.89C21.91,51.05,22.52,51.06,23.14,51.05
			C23.14,51.06,23.14,51.06,23.14,51.05z'
        />
        <path
          fill={fill}
          d='M40.32,37.59c-0.52,0.01-0.89-0.35-0.9-0.86c-0.01-0.48,0.4-0.91,0.87-0.92c0.46-0.01,0.9,0.41,0.92,0.88
			C41.21,37.18,40.81,37.59,40.32,37.59z'
        />
      </g>
    </g>
  </svg>
);

export default SVG;
