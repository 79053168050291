import IconType from './type';

const SVG = ({ fill = '', width = '16px', height = '16px', className = '', viewBox = '0 0 21.4 21.4' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <path
        fill={fill}
        d='M10.7,13.9c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.5,0.4,1,1,1c0.3,0,0.5-0.1,0.7-0.3l2.6-2.6c0.2-0.2,0.3-0.4,0.3-0.7
		c0-0.3-0.1-0.5-0.3-0.7l-2.6-2.6c-0.2-0.2-0.4-0.3-0.7-0.3c-0.5,0-1,0.4-1,1c0,0.3,0.1,0.5,0.3,0.7l0.9,0.9H2.4
		c-0.3,0-0.5-0.2-0.5-0.5V2.4c0-0.3,0.2-0.5,0.5-0.5H12c0.5,0,1-0.4,1-1s-0.4-1-1-1H2.1C0.9,0,0,0.9,0,2.1v8.8C0,12,0.9,13,2.1,13
		h9.5L10.7,13.9z'
      />
      <path
        fill={fill}
        d='M19.5,2.6h-15c-1.1,0-1.9,0.9-1.9,1.9v4.9c0,0.5,0.4,1,1,1s1-0.4,1-1V4.8c0-0.2,0.1-0.3,0.3-0.3h14.4
		c0.2,0,0.3,0.1,0.3,0.3v14.4c0,0.2-0.1,0.3-0.3,0.3H4.8c-0.2,0-0.3-0.1-0.3-0.3v-4.6c0-0.5-0.4-1-1-1s-1,0.4-1,1v4.9
		c0,1.1,0.9,1.9,1.9,1.9h15c1.1,0,1.9-0.9,1.9-1.9v-15C21.4,3.5,20.6,2.6,19.5,2.6z'
      />
    </g>
  </svg>
);

export default SVG;
