/* eslint-disable no-shadow */
import * as React from 'react';

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false);
  React.useLayoutEffect(() => {
    mounted.current = true;
    // eslint-disable-next-line no-return-assign
    return () => (mounted.current = false);
  }, []);
  // eslint-disable-next-line no-void
  return React.useCallback((...args) => (mounted.current ? dispatch(...args) : void 0), [dispatch]);
}

const defaultInitialState = { status: 'idle', data: null, error: null };

export function useAsync(initialState) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });
  const [{ status, data, error }, setState] = React.useReducer((state, action) => ({ ...state, ...action }), initialStateRef.current);

  const safeSetState = useSafeDispatch(setState);

  const setData = React.useCallback((newData) => safeSetState({ data: newData, status: 'resolved' }), [safeSetState]);
  const setError = React.useCallback((newError) => safeSetState({ error: newError, status: 'rejected' }), [safeSetState]);
  const reset = React.useCallback(() => safeSetState(initialStateRef.current), [safeSetState]);

  const run = React.useCallback(
    (promise) => {
      if (!promise || !promise.then) {
        throw new Error('The argument passed to useAsync().run must be a promise');
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        (newData) => {
          setData(newData);
          return newData;
        },
        (newError) => {
          setError(newError);
          return Promise.reject(newError);
        },
      );
    },
    [safeSetState, setData, setError],
  );

  return {
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
}

export default { useAsync };
