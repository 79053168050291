import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '13', className = '', viewBox = '0 0 23.718 23.718' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path d='M22.455,10.6H13.114V1.263a1.263,1.263,0,0,0-2.526,0V10.6H1.263a1.263,1.263,0,1,0,0,2.526H10.6v9.333a1.263,1.263,0,1,0,2.526,0V13.114h9.333a1.259,1.259,0,1,0,0-2.517Z' />
  </svg>
);

export default SVG;
