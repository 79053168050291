import styled from 'styled-components';

interface ItemCardExpandButtonProps {
  active: boolean;
}

const ItemCardExpandButton = styled.button<ItemCardExpandButtonProps>`
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  opacity: 0.2;
  cursor: pointer;
  transition: ${(props) => props?.theme?.transitions?.default};
  border: none;
  padding: 0;
  background: none;
  fill: var(--body-text-color);
  z-index: ${(props) => props?.theme?.zLayers?.overlay};
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
  svg {
    transition: ${(props) => props?.theme?.transitions?.default};
    height: 1rem;
    width: auto;
    transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  &:after {
    content: '';
    position: absolute;
    width: 250%;
    height: 200%;
    top: -50%;
    left: -75%;
  }
`;

export default ItemCardExpandButton;
