import IconType from './type';

const SVG = ({ style = {}, width = '512', height = '512', className = '', viewBox = '0 0 512 512' }: IconType) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g>
      <path
        d='M509.3,427.2L289.4,46.3C282.4,34.2,270,27,256,27c-14,0-26.4,7.2-33.4,19.2L2.7,427.2c-7,12.1-7,26.5,0,38.5
		c6.9,12.1,19.4,19.3,33.4,19.3h439.8c14,0,26.4-7.2,33.4-19.2C516.2,453.7,516.2,439.3,509.3,427.2z M253,63.8
		c0.7-1.2,1.7-1.7,3-1.7c1.3,0,2.4,0.6,3,1.8L479,444.8c0.2,0.4,0.9,1.6-0.1,3.4h0c-0.3,0.5-1.1,1.7-3,1.7H36.1
		c-1.9,0-2.7-1.2-3-1.7c-1-1.9-0.3-3.1,0-3.6L253,63.8z'
      />
      <path
        d='M250.5,328.6h11c6.6,0,12-5.4,12-12V175.8c0-6.6-5.4-12-12-12h-11c-6.6,0-12,5.4-12,12v140.9
		C238.5,323.2,243.9,328.6,250.5,328.6z'
      />
      <circle cx='256' cy='376.4' r='22.5' />
    </g>
  </svg>
);

export default SVG;
