/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import styled from 'styled-components';
import Loader from '../Loader/Loader';
import Icon from '../../Icons';

interface ButtonProps {
  className?: string;
  text: string;
  icon?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  primary?: boolean;
  primaryAlt?: boolean;
  primaryOutline?: boolean;
  secondary?: boolean;
  secondaryOutline?: boolean;
  white?: boolean;
  whiteOutline?: boolean;
  dangerOutline?: boolean;
  muted?: boolean;
  cancel?: boolean;
  capital?: boolean;
  darkText?: boolean;
  lightText?: boolean;
  hyperlink?: boolean;
  saving?: boolean;
  testId?: string;
  id?: string;
  bold?: boolean;
  hasIcon?: boolean;
  noIconFill?: boolean;
  iconOnly?: boolean;
  underlined?: boolean;
  noWrap?: boolean;
  greyedOut?: boolean;
}

interface StyledButtonProps {
  size?: 's' | 'm' | 'l';
  renderIconFirst?: boolean;
}

const HulerButton = ({
  className,
  text,
  icon,
  type = 'button',
  onClick,
  saving = false,
  primary = true,
  disabled = false,
  testId = '',
  id = '',
}: ButtonProps) => (
  // eslint-disable-next-line react/button-has-type
  <button className={className} type={type} onClick={onClick} disabled={disabled || saving} data-testid={testId} id={id || ''}>
    {saving ? <Loader hasBg={primary} style={{ width: '24px', height: '24px' }} /> : text}
    {!saving && icon && <Icon name={icon} />}
  </button>
);

HulerButton.defaultProps = {
  className: '',
  type: 'button',
  icon: '',
  active: false,
  disabled: false,
  primary: false,
  primaryAlt: false,
  primaryOutline: false,
  secondary: false,
  secondaryOutline: false,
  white: false,
  whiteOutline: false,
  dangerOutline: false,
  muted: false,
  cancel: false,
  capital: false,
  darkText: false,
  lightText: false,
  hyperlink: false,
  onClick: () => undefined,
  testId: '',
  bold: false,
  hasIcon: false,
  noIconFill: false,
  iconOnly: false,
  underlined: false,
  noWrap: false,
};

const Button = styled(HulerButton)<StyledButtonProps>`
  display: inline-block;
  text-align: center;
  transition: ${(props) => props?.theme?.transitions?.default};
  border-radius: 100px;
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;
  font-family: 'sofia-pro';
  font-weight: 500;
  width: auto;
  border: none;
  margin: 0 10px;
  text-decoration: none;
  position: relative;
  vertical-align: middle;

  ${(props) => props.greyedOut && `opacity: 0.25;`}

  ${(props) =>
    props.renderIconFirst &&
    `
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    
    svg {
      margin: 0 0.35rem 0 0;
    }
  `}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }

  ${(props) =>
    props.primary &&
    `
    color: #fff;
    background-color: rgba(var(--primary-color), 1);
    &:hover {
        color: #fff;
    }
    svg {
        * {
            fill: ${props.noIconFill ? 'none' : '#fff'};
        }
    }
  `}

  ${(props) =>
    props.primaryAlt &&
    `
    color: var(--contrastInverse);
    background-color: rgba(var(--primary-color), 1);
    &:hover {
        color: #fff;
        text-shadow: 0 0 5px rgba(white, 0.5);
    }
    svg {
        * {
            fill: ${props.noIconFill ? 'none' : 'var(--contrastInverse)'};
        }
    }
  `}

  ${(props) =>
    props.primaryOutline &&
    `
    position: relative;
    background-color: none;
    color: rgba(var(--primary-color), 1);
    border: 1px solid rgba(var(--primary-color), 1);
    ${
      props.active &&
      `
        color: #fff;
        background-color: rgba(var(--primary-color), 1);
        transform: scale(0.95);
    `
    }
    &:hover {
        color: #fff;
        background-color: rgba(var(--primary-color), 1);
    }
  `}

  ${(props) =>
    props.secondary &&
    `
    color: var(--background-color);
    background-color: var(--highlight-color);
    &:hover {
        color: #fff;
    }
  `}

  ${(props) =>
    props.secondaryOutline &&
    `
    color: var(--grey-7);
    border: 1px solid var(--grey-7);
    background-color: transparent;
    &:hover {
        color: var(--background-color);
        background-color: var(--highlight-color);
        svg * {
            fill: ${props.noIconFill ? 'none' : 'var(--background-color)'};
        }
    }
    
    svg * {
        fill: ${props.noIconFill ? 'none' : 'rgba(var(--highlight-color), 0.5)'};
    }
  `}

  ${(props) =>
    props.white &&
    `
    color: rgba(var(--primary-color), 1);
    border: 1px solid transparent;
    background-color: #fff;
    &:hover {
        background-color: #f4f4f4;
        color: #3c3c3c;
    }
  `}

  ${(props) =>
    props.whiteOutline &&
    `
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    &:hover {
        background-color: #fff;
        color: #3c3c3c;
    }
  `}

  ${(props) =>
    props.dangerOutline &&
    `
    color: #cc5a5a;
    border: 1px solid #cc5a5a;
    background-color: transparent;
    &:hover {
        background-color: #cc5a5a;
        color: #fff;
    }
  `}

  ${(props) =>
    props.muted &&
    `
    color: var(--body-text-color);
    border: 1px solid transparent;
    background-color: var(--grey-4);
    &:hover {
        background-color: rgba(var(--primary-color), 1);
        color: #fff;
    }
  `}

  ${(props) =>
    props.darkText &&
    `
    color: var(--background-color);
    svg,
    svg * {
        fill: ${props.noIconFill ? 'none' : 'var(--background-color)'};
    }
  `}

   ${(props) =>
    props.lightText &&
    `
    color: var(--footer-btn-color);
    svg,
    svg * {
        fill: ${props.noIconFill ? 'none' : 'currentColor'};
    }
  `}


  ${(props) =>
    props.hyperlink &&
    `
    padding: 0 !important;
    border: none;
    border-bottom: 0.5px solid white;
    border-radius: 0;
    padding-bottom: 2px;
    padding-left: 0;
    padding-right: 0;

    &:hover {
        background: rgba(0, 0, 0, 0);
    }
  `}


  ${(props) =>
    props.size === 's' &&
    `
    font-size: 10px;
    padding: 5px 10px;
    svg {
        margin-left: 5px;
        margin-left: initial !important;
        height: 10px !important;
    }
  `}
  ${(props) =>
    props.size === 'm' &&
    `
    font-size: 13px;
    padding: 10px 25px;
    line-height: 20px;
  `}
  ${(props) =>
    props.size === 'l' &&
    `
    font-size: 14px;
    padding: 15px 38px;
  `}
  ${(props) =>
    props.bold &&
    `
    font-weight: 700;
  `}

  ${(props) =>
    props.hasIcon &&
    `
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0.5rem;
      width: 1rem;
      height: 1rem;
    }
  `}

  ${(props) =>
    props.iconOnly &&
    `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px;

    svg {
      width: 1rem;
      height: 1rem;
    }

    ${
      props.size === 'l' &&
      `
      padding: 16px;
    `
    }
  `}

  ${(props) =>
    props.underlined &&
    `
    text-decoration: underline;
  `}

  ${(props) =>
    props.noWrap &&
    `
    white-space: nowrap;
  `}
`;

Button.defaultProps = {
  size: 'm',
};

export default Button;
