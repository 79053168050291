import React from 'react';
import styled from 'styled-components';
import logo from '../../../assets/img/branding/huler-logo-small.svg';

interface Interface {
  className?: string;
  name: string;
}

function FailedImage({ className, name }: Interface) {
  return (
    <div className={className}>
      <img src={logo} alt={name} />
    </div>
  );
}

const StyledFailedImage = styled(FailedImage)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-2);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  img {
    height: 60% !important;
    width: auto !important;
    min-width: initial !important;
    filter: grayscale(100%);
    opacity: 0.2;
  }
`;

export default StyledFailedImage;
