/**
 * Draws the panel that search results sit in.
 */
import styled from 'styled-components';
import Icon from '../../Icons';
import SearchResultsRow from '../SearchResultsRow/SearchResultsRow';
import { Hit } from '../../../api/wrappers/SearchAPI';
import { collectionItemsToVisualComponent, collectionToVisualComponent, itemToVisualComponent } from '../searchMethods';
import SearchResultsPanelTitle from './SearchResultsPanelTitle';

interface SearchResultsPanelProps {
  className?: string;
  title: string;
  icon: string;
  myItems: Hit[];
  items: Hit[];
  collections: Hit[];
  collectionItems: Hit[];
}

const SearchResultPanel = ({ className, title, icon, myItems, items, collections, collectionItems }: SearchResultsPanelProps) => (
  <div className={className}>
    <SearchResultsPanelTitle>
      <Icon name={icon} />
      <span>
        <b>Huler</b>
        {title}
      </span>
    </SearchResultsPanelTitle>
    {myItems.length > 0 && <SearchResultsRow title='My Tiles' hits={myItems} makeChild={itemToVisualComponent} />}
    {items.length > 0 && <SearchResultsRow title='Workplace Tiles' hits={items} makeChild={itemToVisualComponent} />}
    {collections.length > 0 && <SearchResultsRow title='Collections' hits={collections} makeChild={collectionToVisualComponent} isCollections />}
    {collectionItems.length > 0 && (
      <SearchResultsRow
        title='Collection Items'
        hits={collectionItems}
        makeChild={collectionItemsToVisualComponent}
        customHitNumberReducer
        testId='searchTitle'
      />
    )}
  </div>
);

const StyledSearchResultPanel = styled(SearchResultPanel)`
  padding: 0 100px;
  @media (max-width: ${(props) => props.theme.breakpoints.xLarge}) {
    font-size: 1.2vw;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 1.5vw;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    padding-left: 50px;
    font-size: 2vw;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 2.8vw;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 3.5vw;
  }
`;

export default StyledSearchResultPanel;
