import IconType from './type';

const SVG = ({ style = {}, width = '16', height = '16', className = '', viewBox = '0 0 50 50' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g transform='translate(-730 -509)'>
      <rect width='50' height='50' rx='25' transform='translate(730 509)' fill='#35363a' />
      <g transform='translate(136 -7)'>
        <circle cx='2.5' cy='2.5' r='2.5' transform='translate(617 531)' fill='#fff' />
        <circle cx='2.5' cy='2.5' r='2.5' transform='translate(617 539)' fill='#fff' />
        <circle cx='2.5' cy='2.5' r='2.5' transform='translate(617 547)' fill='#fff' />
      </g>
    </g>
  </svg>
);

export default SVG;
