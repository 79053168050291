import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { getData, postData, putData } from '../../APIHandler';

const searchForOrganisation = async (slug: string) => {
  try {
    const response = await getData('OrganisationAPINOORG', `search?OrganisationId=${slug}`, { bypassAuth: true });

    if (response.queryResponse) {
      if (response.queryResponse.organisationId === slug) {
        return response.queryResponse;
      }
    }

    return null;
  } catch (error) {
    Sentry.configureScope((scope) => {
      scope.setLevel(Severity.Fatal);
      scope.setTag('organisationAPI', null);
      scope.setFingerprint(['type:apifailure']);
    });
    Sentry.captureException(error);
    return error;
  }
};

/**
 This was a really wierd bug.  Previously this used the postData api passing bypass auth = true.
 However, if you registered a huler account, logged in, logged out and then registered a second
 account the authorisation field was getting garbage in it which was causing the waf
 to reject the page with a cors error.  After trying to set the headers manually I moved to axios which works every time.
 */

const createOrganisation = async (organisationName: string, organisationId: string, name: string, email: string, phone: string) => {
  const orgAPIendpoint = `${process.env.REACT_APP_ORGANISATION_ENDPOINT}organisation/register`;
  const response = await axios.post(orgAPIendpoint, {
    organisationName,
    organisationId,
    name,
    email,
    phone,
  });

  return response.status === 200;
};

const joinOrganisation = async (organisationId: string, name: string, email: string, phone: string) => {
  const response = await postData(
    'OrganisationAPI',
    `${organisationId}/join`,
    {
      name,
      email,
      phone,
    },
    { bypassAuth: true },
  );

  return response.status === 'ok';
};

type Payload = {
  attributeName: string;
  customName: string;
  operation: string;
  values: string[];
};

const updateOrganisationAttribute = async (initialAttribute: string, payload: Payload) => {
  const response = await putData('OrganisationAPI', `feed/attribute/${initialAttribute}`, payload);

  return response.status === 'ok';
};

const loadOrganisationAttributes = () => {
  return getData('ConfigAPI', '');
};

const updateOrganisationConfig = async (payload: Payload) => {
  await postData('OrganisationAPI', `storehulerconfig`, payload)
    .then((res) => res)
    .catch((err) => err);
};

export {
  searchForOrganisation,
  createOrganisation,
  joinOrganisation,
  updateOrganisationAttribute,
  loadOrganisationAttributes,
  updateOrganisationConfig,
};
