import IconType from './type';

const SVG = ({ fill = '', width = '12', height = '12', className = '', viewBox = '0 0 512 512' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Layer_2' fill={fill} data-name='Layer 2'>
      <g id='Capa_1' data-name='Capa 1'>
        <path d='M248.16,343.22c-14.64,0-26.49,12.2-26.49,26.84,0,14.29,11.5,26.84,26.49,26.84a26.84,26.84,0,1,0,0-53.68Z' />
        <path d='M252.69,140C205.63,140,184,167.89,184,186.71c0,13.59,11.5,19.87,20.92,19.87,18.82,0,11.15-26.84,46.7-26.84,17.43,0,31.38,7.67,31.38,23.7,0,18.83-19.52,29.63-31,39.39-10.1,8.71-23.35,23-23.35,53,0,18.13,4.88,23.36,19.17,23.36,17.08,0,20.57-7.67,20.57-14.29,0-18.13.35-28.59,19.52-43.57,9.41-7.32,39-31,39-63.79S297.31,140,252.69,140Z' />
        <path d='M256,0C114.52,0,0,114.5,0,256V492a20,20,0,0,0,20,20H256c141.48,0,256-114.5,256-256S397.5,0,256,0Zm0,472H40V256A216,216,0,1,1,256,472Z' />
      </g>
    </g>
  </svg>
);

export default SVG;
