import IconType from './type';

const SVG = ({ style = {}, width = '14', height = '11', className = '', viewBox = '0 0 512 384' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path d='M0,272.33,175.33,448,512,111.66,463.67,64,175.33,352,47.66,224.33Z' transform='translate(0 -64)' />
  </svg>
);

export default SVG;
