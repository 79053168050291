import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * A helper function to translate a string.
 */
export const translate = (id, values) => {
  const valuesWithHTML = {
    ...values,
    // Enable html tags inside message strings
    b: (content) => <b>{content}</b>,
  };
  return <FormattedMessage id={id} values={valuesWithHTML} />;
};

export default {
  translate,
};
