import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '13', className = '', viewBox = '0 0 22.745 22.745' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path d='M18.138,22.745H4.607A4.612,4.612,0,0,1,0,18.138V4.607A4.612,4.612,0,0,1,4.607,0H18.138a4.612,4.612,0,0,1,4.607,4.607V18.138A4.612,4.612,0,0,1,18.138,22.745Zm-5.556-2.42h5.556a2.189,2.189,0,0,0,2.187-2.187V12.583H12.583ZM2.42,12.583v5.556a2.189,2.189,0,0,0,2.187,2.187h5.556V12.583Zm0-2.42H20.325V4.607A2.189,2.189,0,0,0,18.138,2.42H4.607A2.189,2.189,0,0,0,2.42,4.607Z' />
  </svg>
);

export default SVG;
