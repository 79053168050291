import * as React from 'react';
import Loader from '../components/UI/Loader/Loader';

import { useAsync } from '../helpers/useAsyncHook';
import bootstrapConfig from '../api/bootstrap-api';

async function bootstrapConfigData() {
  try {
    const config = await bootstrapConfig();
    return config;
  } catch (error) {
    return error;
  }
}

const ConfigContext = React.createContext();

function ConfigProvider(props) {
  const { data: config, status, error, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync();

  React.useEffect(() => {
    const configDataPromise = bootstrapConfigData();
    run(configDataPromise);
  }, [run]);

  const setConfig = React.useCallback(
    async (orgId) => {
      const orgConfig = await bootstrapConfig(orgId);
      setData(orgConfig);
    },
    [setData],
  );

  const value = React.useMemo(() => ({ config, setConfig }), [config, setConfig]);

  if (isLoading || isIdle) {
    return <Loader fullscreen style={{ width: '80px', height: '80px' }} />;
  }

  if (isError) {
    return <div>Error Occurred {error.message} </div>;
  }

  if (isSuccess) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ConfigContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useConfig() {
  const context = React.useContext(ConfigContext);
  if (context === undefined) {
    throw new Error(`useConfig must be used within an Config Provider`);
  }

  return context;
}

export { ConfigProvider, useConfig };
