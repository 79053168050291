import React from 'react';
import { Link } from 'react-router-dom';

export interface HulerLinkToInterface {
  pathname: string;
  create: boolean;
}
export interface HulerLinkInterface {
  to: string | HulerLinkToInterface;
  className?: string;
  label?: string;
  children: React.ReactNode;
  target?: string;
  id?: string;
}

function HulerLink({ to, className = '', label = '', children, target = '', id = '' }: HulerLinkInterface) {
  return (
    <Link className={className} to={to} target={target || ''} id={id || ''}>
      {label ?? ''}
      {children}
    </Link>
  );
}

export default HulerLink;
