import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '12', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''} stroke`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path className='stroke' d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z' />
    <polyline className='stroke' points='17 21 17 13 7 13 7 21' />
    <polyline className='stroke' points='7 3 7 8 15 8' />
  </svg>
);

export default SVG;
