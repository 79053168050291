import IconType from './type';

const SVG = ({ fill = '', width = '16px', height = '16px', className = '', viewBox = '0 0 512 640' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M400,296h32c15.4,0,28.6-10.9,31.4-26l19.1-6.4c4.2-1.4,6.5-5.9,5.1-10.1c-0.2-0.7-0.5-1.3-0.9-1.9l-48-72
	c-1.5-2.2-4-3.6-6.7-3.6h-56v16h5.8l-7.3,8h-24.7L336,158.6c9.6-3.4,16-12.5,16-22.6v-16h16v-16h-16V88c0-4.4-3.6-8-8-8h-32
	c-4.4,0-8,3.6-8,8v16h-16v16h16v16c0,5.9,2.2,11.6,6.1,16h-24.9l-20.2-47.2L293,63c5.1,0.7,10.1,1,15.3,1H404c15.5,0,28-12.5,28-28
	S419.5,8,404,8h-91.2c-17.5,0-34.8,4.1-50.5,11.9l-19.1,9.5l-13.4-5.4c-13.3-5.4-27.6-8.1-42-8.1H136c-13.3,0-24,10.7-24,24
	s10.7,24,24,24h47.2c17.5,0,34.8-4.1,50.5-11.9l10.2-5.1l22.3,8.9c3.3,1.3,6.6,2.4,9.9,3.4l-26.8,40.2c-1.5,2.2-1.8,5.1-0.7,7.6
	l22.9,53.3l-23.2,77.2c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.2-0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1c0,0.1,0,0.2,0,0.2c0,0.3,0,0.5,0,0.8
	c0,0.1,0,0.2,0,0.4c0,0.2,0.1,0.4,0.1,0.7c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.2,0.1,0.4
	c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.3,0.6,0.6,0.9l0,0l0,0L264,264h-80c-16.3,0-31.8,7.1-42.5,19.5
	l-50.7-19c-3.1-1.2-6.6-0.3-8.8,2.2l-64,72c-2.9,3.3-2.6,8.4,0.7,11.3c1.5,1.3,3.4,2,5.3,2c0.3,0,0.7,0,1-0.1l64-8
	c3.3-0.4,6-2.8,6.8-6l6.5-25.9h26.3c-0.4,2.6-0.6,5.3-0.6,8c0,12.4,4.9,24.2,13.7,33l20.7,20.7l19.1,19.1l-19.8,26.4
	c-2.7,3.5-1.9,8.5,1.6,11.2c0.2,0.2,0.5,0.4,0.8,0.5l5.9,3.4l-1,2.1c-1.7,3.3-0.8,7.3,2,9.7l45.1,37.6V496h16v-16
	c0-2.4-1.1-4.6-2.9-6.1l-19.8-16.5l46.6,27.2v3.4h16v-32c0-2.4-1.1-4.6-2.9-6.1l-42-35l27.1-33.9c1.1-1.4,1.8-3.2,1.8-5h63.6
	l0.8,2.5c1.1,3.3,4.1,5.5,7.6,5.5h1.6l20.6,24.8L331.5,440H320v16h32c2.4,0,4.6-1.1,6.1-2.9l40-48c2.5-3,2.5-7.3,0-10.2l-9.8-11.8
	l16.1,8l7.2-14.3L373,357.6l6.8-16.3l50.7,30.4L410,440h-18v16h24c3.5,0,6.6-2.3,7.7-5.7l24-80c1.1-3.5-0.4-7.3-3.5-9.2L386,326.3
	l12.6-30.3C399.1,296,399.6,296,400,296z M183.2,48H136c-4.4,0-8-3.6-8-8s3.6-8,8-8h51.8c12.3,0,24.6,2.3,36,6.9L224,39
	C211.2,44.9,197.3,48,183.2,48z M272.2,41.1l-9.1-3.7l6.3-3.2c13.5-6.7,28.3-10.2,43.4-10.2H404c6.6,0,12,5.4,12,12s-5.4,12-12,12
	h-95.8C295.9,48,283.7,45.7,272.2,41.1z M96,296c-3.7,0-6.9,2.5-7.8,6.1l-6.7,26.7l-37.5,4.7l46.2-52l38.9,14.6H96z M432,280h-26.7
	l1.8-4.4l16.4-27.3l24,19.2C445.9,274.8,439.5,280,432,280z M336.4,210.5c1.1,3.3,4.1,5.5,7.6,5.5h8v8.5l-30.3,33l-9.1-18.3
	l15.8-52.8L336.4,210.5z M320,96h16v8h-16V96z M320,120h16v16c0,4.4-3.6,8-8,8s-8-3.6-8-8V120z M288.5,168h24.2
	c2.3,0,3.9,2.2,3.3,4.4l-17.2,57.2c-0.4,1.4-1.8,2.4-3.3,2.4h-24.2c-2.3,0-3.9-2.2-3.3-4.4l17.2-57.2C285.7,169,287,168,288.5,168z
	 M256,360h-11.4L198,306.7l-12,10.5l51.6,59L209.8,411c-2.7,3.4-2.2,8.4,1.1,11.1l45.1,37.6v6.3l-65.1-38l0.3-0.5l-8-4l-3.4-2
	l18.6-24.8c2.4-3.2,2.1-7.6-0.7-10.5l-24-24L153,341.7c-5.7-5.7-9-13.5-9-21.7c0-22.1,17.9-40,40-40c0,0,0,0,0,0h92l20.8,27.8
	l17.4,52.2H256z M333.8,368l-22.2-66.5c-0.3-0.8-0.7-1.6-1.2-2.3L275.8,253c-1.6-2.1-0.1-5,2.5-5h18.8c1.2,0,2.3,0.7,2.8,1.7
	l28.5,57L342,368H333.8z M381.6,400L355,432l11.9-19.8c1.8-2.9,1.5-6.6-0.7-9.2L350.4,384h1.6c4.4,0,8-3.6,8-8l0,0
	c0.5,0,1-0.1,1.5-0.2L381.6,400z M356,356.9l-12.1-54.6c-0.1-0.6-0.4-1.3-0.7-1.8l-13.8-27.5l22.6-24.7
	c0.1,19.7,12.3,37.4,30.7,44.6L356,356.9z M392.6,268.9l-3.8,9.1c-12.5-4.7-20.8-16.6-20.8-30v-17.3l35.5-38.7h24.2l39.8,59.7
	l-9.9,3.3l-31.2-25c-3.5-2.8-8.5-2.2-11.2,1.2c-0.2,0.3-0.4,0.6-0.6,0.9l-21.4,35.7C392.9,268.2,392.8,268.6,392.6,268.9z'
    />
  </svg>
);

export default SVG;
