import IconType from './type';

const SVG = ({ style = {}, width = '24', height = '24', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={`svg-icon ${className}`}
  >
    <polygon points='5 4 15 12 5 20 5 4' />
    <line x1='19' y1='5' x2='19' y2='19' />
  </svg>
);

export default SVG;
