import IconType from './type';

const SVG = ({ style = {}, width = '13', height = '12', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''} stroke`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <rect className='stroke' x='2' y='7' width='20' height='14' rx='2' ry='2' />
    <path className='stroke' d='M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16' />
  </svg>
);

export default SVG;
