/**
 * Draws the main search results page
 */
import styled from 'styled-components';
import SearchResultPanel from '../../components/Search/SearchResultPanel/SearchResultPanel';
import { Hit } from '../../api/wrappers/SearchAPI';
import SearchResultsPageEmpty from '../../components/Search/SearchResultsPageEmpty/SearchResultsPageEmpty';
import { SearchHandleType } from '../../components/Search/searchMethods';
import SearchResultsHeader from '../../components/Search/SearchResultsHeader';
import { scrollStyling } from '../../theming/mixins';

// These consts represent the different types of data comming back from the search engine.
// They type numbers are defined in crazydiamond lambda/common/types/lambdaResponses.go
const TileTypeId = '17'; // Represents a tile
const EditTileTypeId = '38'; // Represents a tile that has been edited
const CollectionTypeId = '54'; // Represents a collection
const SharedCollectionTypeId = '65'; // Represents a shared collection

const closeSearch = (searchHandle: SearchHandleType) => {
  document.getElementsByTagName('HTML')[0].setAttribute('style.overflow', 'initial');
  searchHandle(null);
};

interface SearchResultsPageProps {
  className?: string;
  query: string;
  searchHandle: SearchHandleType;
  hits: Hit[];
}

const SearchResultsPage = ({ className, query, hits, searchHandle }: SearchResultsPageProps) => {
  const reduceFunction = (partitions: Hit[][], hit: Hit): Hit[][] => {
    const {
      _source: { type = '' },
    } = hit;

    switch (type) {
      case TileTypeId:
        if (hit?._source?.tile?.tileType === 'USER') {
          partitions[0].push(hit);
          break;
        }
        partitions[1].push(hit);
        break;
      case EditTileTypeId:
        partitions[1].push(hit);
        break;
      case CollectionTypeId:
      case SharedCollectionTypeId:
        if (hit.highlight) {
          partitions[3].push(hit);
          if (!hit?._source?.collection?.name.toUpperCase().includes(query.toUpperCase())) {
            break;
          }
        }
        partitions[2].push(hit);
        break;
      default:
        break;
    }

    return partitions;
  };

  if (!hits || hits.length === 0) return <SearchResultsPageEmpty query={query} closeSearch={() => closeSearch(searchHandle)} />;

  const initialValue: Hit[][] = [[], [], [], []];

  const [myItems, items, collections, collectionItems] = hits.reduce(reduceFunction, initialValue);

  return (
    <div className={className}>
      <SearchResultsHeader query={query} close={() => closeSearch(searchHandle)} />
      <SearchResultPanel title='Hub' icon='hub' myItems={myItems} items={items} collections={collections} collectionItems={collectionItems} />
    </div>
  );
};

const StyledSearchResultsPage = styled(SearchResultsPage)`
  ${scrollStyling}
  overflow: auto;
  overflow-x: hidden;
  z-index: 1;
  padding-bottom: 40px;
`;

export default StyledSearchResultsPage;
