import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '16', height = '16', className = '', viewBox = '0 0 469.333 469.333' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path fill={fill} d='M341.333,298.667H384V128c0-23.573-19.093-42.667-42.667-42.667H170.667V128h170.667V298.667z' />
    <path
      fill={fill}
      d='M128,341.333V0H85.333v85.333H0V128h85.333v213.333C85.333,364.907,104.427,384,128,384h213.333v85.333H384V384h85.333
				v-42.667H128z'
    />
  </svg>
);

export default SVG;
