import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 29 39.3' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M5.44,2.68c0.28-0.27,0.73-0.26,1,0.02l1.33,1.38c0.27,0.28,0.26,0.73-0.02,1c-0.28,0.27-0.73,0.26-1-0.02L5.42,3.68
	C5.15,3.4,5.16,2.95,5.44,2.68z M12.1,0c-0.39-0.01-0.71,0.3-0.72,0.69l-0.04,1.98c-0.01,0.39,0.3,0.71,0.69,0.72
	s0.71-0.3,0.72-0.69l0.04-1.98C12.8,0.33,12.49,0.01,12.1,0z M18.16,2.7c-0.19,0-0.37,0.07-0.5,0.2l-1.38,1.33
	c-0.28,0.27-0.29,0.72-0.02,1c0.27,0.28,0.72,0.29,1,0.02l1.38-1.33c0.28-0.27,0.29-0.72,0.02-1C18.52,2.78,18.35,2.71,18.16,2.7z
	 M16.88,33.13c-0.24,0.28-0.21,0.7,0.07,0.94c0.28,0.24,0.7,0.21,0.94-0.07c0.24-0.28,0.21-0.7-0.07-0.94
	C17.54,32.82,17.12,32.85,16.88,33.13z M28.84,26.83l-10.6,12.24c-0.24,0.28-0.66,0.31-0.94,0.07l-4.08-3.53
	c-0.71-0.62-1.01-1.6-0.76-2.52l0.06-0.2l0.21-0.31l-0.78-0.69c-0.73-0.06-2.94-0.37-4.76-1.89l-4.52-3.86l0,0c0,0,0,0,0,0
	c-1.3-1.09-2.52-2.45-1.8-4.31c0.28-0.73,0.82-1.26,1.47-1.47c0.02-0.01,0.03-0.01,0.05-0.01c-0.22-0.63-0.2-1.32,0.08-2
	c0.34-0.83,0.98-1.43,1.7-1.61c0.02,0,0.04-0.01,0.06-0.01c-0.17-0.6-0.11-1.23,0.2-1.85c0.18-0.35,0.4-0.88,1.28-1.34
	c0.06-0.03-4.08-3.33-4.99-4.32C-0.41,8,0.08,7.16,0.27,6.78C0.65,6.01,1.32,5.45,2.06,5.3c0.6-0.13,1.21,0.03,1.7,0.45l9.83,8.05
	c0.21-1.13,0.38-2.37,0.77-3.12c0-0.01,0.19-0.43,0.19-0.44c1.06-1.91,3.15-3.07,5.26-3.15c1.29-0.05,1.8,0.2,2.39,1.08
	c0.46,0.69-0.17,1.13-0.17,1.13c-0.37,0.36-0.68,0.49-1.12,0.85c-0.73,0.6-1.56,1.28-1.72,2.71c-0.18,1.57,0.34,6.58,0.46,7.77
	l1.99,1.72l0.29-0.3c0.9-0.47,1.98-0.35,2.75,0.32l4.08,3.53C29.05,26.13,29.08,26.55,28.84,26.83z M12.59,30.75
	c0.02,0.02,0.55,0.48,0.97,0.85l7.21-8.33l-1.07-0.91l0,0c0,0-0.01-0.01-0.01-0.01c-0.12-0.1-1.01-0.65-1.03-0.82
	c-0.03-0.26-0.78-6.53-0.56-8.45c0.22-1.95,1.36-2.88,2.19-3.55c0.33-0.27,0.53-0.4,0.72-0.59c0.04-0.05,0.35-0.24,0.02-0.64
	c-0.29-0.35-0.63-0.32-1.1-0.3c-1.42,0.06-3.36,1.01-4.32,2.72c-0.41,0.81-0.87,3.34-1.02,4.25c-0.04,0.23-0.19,0.42-0.41,0.5
	c-0.22,0.08-0.46,0.04-0.64-0.11L2.94,6.73c-0.2-0.17-0.4-0.22-0.62-0.18c-0.34,0.07-0.7,0.39-0.91,0.8
	C1.33,7.51,1.15,7.88,1.53,8.27c1.73,1.85,6.22,5.43,6.48,5.64c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.01,0.01,0.01,0.01c0,0,0,0,0,0
	c0.02,0.01,0.03,0.03,0.05,0.04c0,0,0.01,0.01,0.01,0.01C8.09,13.99,8.1,14,8.11,14.01c0,0.01,0.01,0.01,0.01,0.02
	c0.01,0.01,0.02,0.02,0.02,0.03c0,0.01,0.01,0.01,0.01,0.02c0.01,0.01,0.01,0.02,0.02,0.03c0,0.01,0.01,0.02,0.01,0.02
	c0,0.01,0.01,0.02,0.01,0.03c0,0.01,0.01,0.02,0.01,0.03c0,0.01,0.01,0.02,0.01,0.03c0,0.01,0,0.02,0.01,0.03
	c0,0.01,0.01,0.02,0.01,0.03c0,0.01,0,0.02,0,0.03c0,0.01,0,0.02,0,0.03c0,0.01,0,0.02,0,0.03c0,0.01,0,0.02,0,0.03
	c0,0.01,0,0.02,0,0.03c0,0.01,0,0.02,0,0.03c0,0.01,0,0.02,0,0.03c0,0.01,0,0.02,0,0.03c0,0.01,0,0.02-0.01,0.03
	c0,0.01,0,0.02-0.01,0.03c0,0.01-0.01,0.02-0.01,0.03c0,0.01-0.01,0.02-0.01,0.03c0,0.01-0.01,0.02-0.01,0.03
	c0,0.01-0.01,0.02-0.01,0.03c-0.01,0.01-0.01,0.02-0.02,0.03c0,0.01-0.01,0.02-0.01,0.02c-0.01,0.01-0.01,0.02-0.02,0.03
	c-0.01,0.01-0.01,0.01-0.02,0.02c0,0,0,0,0,0c0,0,0,0,0,0c-0.01,0.01-0.01,0.02-0.02,0.02c-0.01,0.01-0.01,0.02-0.02,0.02
	c-0.01,0.01-0.02,0.02-0.03,0.02c-0.01,0.01-0.01,0.01-0.02,0.02C8.02,14.91,8.01,14.92,8,14.92c-0.01,0.01-0.02,0.01-0.02,0.02
	c-0.01,0.01-0.02,0.01-0.03,0.02c-0.01,0-0.02,0.01-0.02,0.01c-0.01,0.01-0.02,0.01-0.03,0.01C7.88,14.99,7.87,15,7.86,15
	c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01
	c-0.01,0-0.02,0-0.03,0.01c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0
	c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03-0.01c-0.01,0-0.02,0-0.03-0.01
	c-0.01,0-0.02-0.01-0.03-0.01c-0.01,0-0.02-0.01-0.03-0.01C7.37,15.01,7.36,15,7.35,15c-0.01,0-0.02-0.01-0.03-0.01
	c-0.01-0.01-0.02-0.01-0.03-0.02c-0.01,0-0.02-0.01-0.03-0.01c-0.01-0.01-0.02-0.01-0.03-0.02c-0.01-0.01-0.01-0.01-0.02-0.02
	c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.24-0.57-0.34-0.8-0.28c-0.36,0.08-0.68,0.49-0.84,0.81c-0.41,0.82,0.08,1.45,0.56,1.83c0,0,0,0,0,0
	c0,0,0.01,0.01,0.01,0.01c0.01,0,0.01,0.01,0.01,0.01c0.01,0.01,0.02,0.02,0.03,0.03c0.01,0.01,0.01,0.01,0.02,0.02
	c0.01,0.01,0.02,0.02,0.03,0.03c0.01,0.01,0.01,0.01,0.01,0.02c0.01,0.01,0.02,0.02,0.02,0.03c0.01,0.01,0.01,0.02,0.01,0.02
	c0.01,0.01,0.01,0.02,0.02,0.03c0,0.01,0.01,0.02,0.01,0.03c0,0.01,0.01,0.02,0.01,0.03c0,0.01,0.01,0.02,0.01,0.03
	c0,0.01,0.01,0.02,0.01,0.03c0,0.01,0.01,0.02,0.01,0.04c0,0.01,0,0.02,0.01,0.02c0,0.01,0,0.03,0.01,0.04c0,0.01,0,0.01,0,0.02
	c0,0.01,0,0.03,0,0.04c0,0.01,0,0.01,0,0.02c0,0.01,0,0.03,0,0.04c0,0.01,0,0.01,0,0.02c0,0.01,0,0.03,0,0.04c0,0.01,0,0.01,0,0.02
	c0,0.01-0.01,0.03-0.01,0.04c0,0.01,0,0.02-0.01,0.02c0,0.01-0.01,0.02-0.01,0.04c0,0.01-0.01,0.02-0.01,0.02
	c0,0.01-0.01,0.02-0.01,0.03c0,0.01-0.01,0.02-0.01,0.03c-0.01,0.01-0.01,0.02-0.02,0.03c0,0.01-0.01,0.02-0.02,0.03
	c-0.01,0.01-0.01,0.02-0.02,0.03c0,0.01-0.01,0.01-0.01,0.02c0,0,0,0-0.01,0.01c0,0,0,0-0.01,0.01c0,0.01-0.01,0.01-0.02,0.02
	c-0.01,0.01-0.02,0.02-0.02,0.03c-0.01,0.01-0.01,0.01-0.02,0.02c-0.01,0.01-0.02,0.02-0.03,0.02c-0.01,0.01-0.02,0.01-0.02,0.02
	C6.11,18.29,6.1,18.3,6.09,18.3c-0.01,0.01-0.02,0.01-0.03,0.02c-0.01,0.01-0.02,0.01-0.03,0.02c-0.01,0-0.02,0.01-0.03,0.01
	c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01
	c-0.01,0-0.02,0-0.03,0.01c-0.01,0-0.02,0-0.03,0.01c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0
	c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03-0.01
	c-0.01,0-0.02,0-0.03-0.01c-0.01,0-0.02-0.01-0.03-0.01c-0.01,0-0.02-0.01-0.03-0.01c-0.01,0-0.02-0.01-0.03-0.01
	c-0.01,0-0.02-0.01-0.03-0.01c-0.01-0.01-0.02-0.01-0.03-0.02c-0.01-0.01-0.02-0.01-0.03-0.02c-0.01-0.01-0.02-0.01-0.03-0.02
	c-0.01,0-0.01-0.01-0.02-0.01c-0.01,0-0.01-0.01-0.01-0.01c-0.3-0.25-0.59-0.35-0.85-0.29c-0.32,0.08-0.65,0.41-0.83,0.85
	c-0.37,0.9,0.09,1.52,0.54,1.87c0,0,0,0,0.01,0.01c0.01,0.01,0.02,0.02,0.03,0.03c0.01,0,0.01,0.01,0.02,0.01
	c0.01,0.01,0.03,0.03,0.04,0.04c0,0,0,0,0,0c0.13,0.16,0.18,0.37,0.13,0.58c0,0,0,0.01,0,0.01c0,0.01-0.01,0.03-0.01,0.04
	c0,0.01,0,0.01-0.01,0.02c0,0.01-0.01,0.02-0.01,0.03c0,0.01-0.01,0.02-0.01,0.03c0,0.01-0.01,0.02-0.02,0.03
	c-0.01,0.01-0.01,0.02-0.02,0.03c0,0,0,0,0,0.01c0,0.01-0.01,0.01-0.01,0.02c-0.01,0.01-0.01,0.01-0.01,0.02c0,0,0,0-0.01,0.01
	c-0.01,0.01-0.01,0.02-0.02,0.03c-0.01,0.01-0.01,0.01-0.02,0.02c-0.01,0.01-0.02,0.02-0.02,0.02c-0.01,0.01-0.01,0.01-0.02,0.02
	c-0.01,0.01-0.02,0.01-0.03,0.02c-0.01,0.01-0.02,0.01-0.03,0.02c-0.01,0.01-0.02,0.01-0.03,0.02c-0.01,0.01-0.02,0.01-0.03,0.02
	c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01
	c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0-0.03,0.01c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0
	c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0
	c-0.01,0-0.02,0-0.03-0.01c-0.01,0-0.02,0-0.03-0.01c-0.01,0-0.02-0.01-0.03-0.01c-0.01,0-0.02-0.01-0.03-0.01
	c-0.01,0-0.02-0.01-0.03-0.01c-0.01-0.01-0.02-0.01-0.03-0.02c-0.01,0-0.02-0.01-0.02-0.01c-0.01-0.01-0.02-0.01-0.03-0.02
	c0,0,0,0-0.01,0c-0.25-0.17-0.49-0.22-0.72-0.15c-0.27,0.09-0.52,0.35-0.66,0.71c-0.25,0.64-0.34,1.39,1.43,2.87c0,0,0,0,0,0
	l4.52,3.86c1.81,1.52,4.19,1.61,4.22,1.61C12.36,30.63,12.49,30.68,12.59,30.75z M27.41,26.46l-3.58-3.1
	c-0.47-0.41-1.19-0.36-1.6,0.12L13.98,33c-0.41,0.47-0.36,1.19,0.12,1.6l3.58,3.1L27.41,26.46z'
    />
  </svg>
);

export default SVG;
