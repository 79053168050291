import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 113.54 115.59' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M113.49,91.23c-0.64-7.8-7.35-12.43-8.1-12.93c-2.13-1.42-4.29-3.03-6.38-4.58c-8.66-6.44-19.43-14.45-28.29-5.59
	c-3.4,3.39-6.15,3.14-7.26,2.83c-1.87-1.58-7.09-6.06-12.52-11.44c-6.43-6.37-7.63-8.7-3.63-15.25c7.6-12.44,3.95-17.66-5.82-31.62
	l-0.43-0.61C35.63,4.3,31.11,0.72,25.94,0.09c-6.14-0.74-11.46,3.08-15.85,6.93C-0.59,16.36-2.99,31.5,3.84,46.52
	c4.61,10.15,18.27,35.71,45.44,53.96c15.98,10.73,26.4,15.11,35.96,15.11c0,0,0,0,0,0c7.47,0,14.26-2.86,20.74-8.75
	C111.41,101.92,113.94,96.66,113.49,91.23z M99.26,99.44c-10.82,9.81-21.61,8.05-44.41-7.26C29.81,75.36,17.2,51.75,12.94,42.38
	c-5-11.01-3.61-21.41,3.72-27.83c3.5-3.06,6.03-4.55,7.74-4.55c0.11,0,0.22,0.01,0.32,0.02c1.96,0.24,4.69,2.85,8.13,7.76l0.43,0.62
	c9.58,13.68,9.63,13.87,5.48,20.66c-7.16,11.71-3.54,18.98,5.13,27.57c6.82,6.76,13.1,11.97,13.8,12.55
	c0.28,0.23,0.59,0.43,0.91,0.6c0.4,0.2,9.78,4.83,19.18-4.57c2.16-2.16,5.44-0.75,15.25,6.54c2.19,1.63,4.46,3.31,6.84,4.9
	c0.03,0.02,3.39,2.33,3.64,5.4C103.75,94.76,101.33,97.56,99.26,99.44z'
    />
  </svg>
);

export default SVG;
