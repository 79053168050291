import IconType from './type';

const SVG = ({ style = {}, fill = '#000', width = '16', height = '16', className = '', viewBox = '0 0 23.49 22.55' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <line fill={fill} className='fill' x1='12.07' y1='21.77' x2='23.49' y2='21.77' />
        <rect
          fill='none'
          stroke={fill}
          className='stroke'
          x='15.02'
          y='1.91'
          width='5.61'
          height='5.61'
          transform='translate(27.1 20.66) rotate(-135)'
        />
        <polygon fill='none' stroke={fill} className='stroke' points='13.86 4.72 17.83 8.69 5.26 21.26 0.75 21.8 1.29 17.29 13.86 4.72' />
      </g>
    </g>
  </svg>
);

export default SVG;
