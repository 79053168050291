import IconType from './type';

const SVG = ({ style = {}, width = '24', height = '24', className = '', viewBox = '0 0 598.4 541.2' }: IconType) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path
      d='M5,0h588.5c2.7,0,5,2.2,5,5v413c0,2.7-2.2,5-5,5c-4.2,0-8.3,0-12.4,0c-100,0-200-0.2-300.1,0.4c-8.5,0.1-18.5,3.6-25.4,8.7
	c-46.6,35.1-92.7,70.9-140.9,108.1c-3.3,2.5-8,0.2-8-3.9V428.2c0-2.7-2.2-5-5-5H5c-2.7,0-5-2.2-5-5V5C0,2.2,2.2,0,5,0z M140.7,465.6
	c0,4.1,4.8,6.5,8,3.9c33.1-25.5,63.4-49.3,94.5-72.2c6.9-5.1,16.9-8.6,25.5-8.7c92.5-0.6,185.1-0.4,277.6-0.4
	c4.1,0,8.3-0.3,12.4-0.5c2.6-0.2,4.7-2.3,4.7-5V40.5c0-2.7-2.2-5-5-5H40.1c-2.7,0-5,2.2-5,5v343.1c0,2.7,2.2,5,5,5h95.6
	c2.7,0,5,2.2,5,5V465.6z'
    />
    <path
      d='M491.9,158.1L491.9,158.1c0,9-7.3,16.3-16.3,16.3H123.6c-9,0-16.3-7.3-16.3-16.3v0c0-9,7.3-16.3,16.3-16.3h351.9
	C484.6,141.8,491.9,149.1,491.9,158.1z'
    />
    <path
      d='M421.9,263.7v0.8c0,9.1-7.4,16.5-16.5,16.5H123.3c-9.1,0-16.5-7.4-16.5-16.5v-0.8c0-9.1,7.4-16.5,16.5-16.5h282.1
	C414.5,247.3,421.9,254.6,421.9,263.7z'
    />
    <path
      d='M475.3,281.1L475.3,281.1c-8.9,0-16.1-7.2-16.1-16.1v-1.7c0-8.9,7.2-16.1,16.1-16.1h0c8.9,0,16.1,7.2,16.1,16.1v1.7
	C491.4,273.9,484.2,281.1,475.3,281.1z'
    />
  </svg>
);

export default SVG;
