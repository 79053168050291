import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path fill={fill} className='st0' d='M12,2.7l5.7,5.7c3.1,3.1,3.1,8.2,0,11.3s-8.2,3.1-11.3,0s-3.1-8.2,0-11.3c0,0,0,0,0,0L12,2.7z' />
    <path fill={fill} className='st0' d='M0.5-4.8' />
    <path fill={fill} className='st0' d='M17,14.5c-0.2,2.6-2.4,4.5-5,4.3' />
  </svg>
);

export default SVG;
