import Icon from 'components/Icons';
import styled, { css } from 'styled-components';

interface TileTitleProps {
  className?: string;
  title?: string;
  bottomTitle?: string;
}

const TileTitle = ({ className, title, bottomTitle }: TileTitleProps) => (
  <div className={className}>
    {title && <h3>{title}</h3>}
    <div>
      <Icon name='arrow-right' /> {bottomTitle || 'View'}
    </div>
  </div>
);

interface StyledTileTitleProps {
  colorMode?: 'light' | 'dark';
  textShadow?: boolean;
}

const StyledTileTitle = styled(TileTitle)<StyledTileTitleProps>`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  > h3,
  > div {
    z-index: ${(props) => props.theme.zLayers.default};
  }
  > h3 {
    color: ${(props) => (props.colorMode === 'light' ? 'white' : 'black')};
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1.5em;
    margin-bottom: 0;
    line-height: 1em;
    font-size: 1em;
    font-weight: bold;
    transition: ${(props) => props.theme.transitions.default};
    text-align: left;
    cursor: pointer;
  }

  > div {
    color: ${(props) => (props.colorMode === 'light' ? 'white' : 'black')};
    position: absolute;
    bottom: 0;
    left: 1.6em;
    font-weight: 400;
    font-size: 1em;
    transition: ${(props) => props.theme.transitions.default};
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    > svg {
      fill: ${(props) => (props.colorMode === 'light' ? 'white' : 'black')};
      margin-right: 0.5em;
      width: 0.9em;
      height: 0.9em;
    }
  }

  ${(props) =>
    props.textShadow &&
    `
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3.75em;
      background: ${
        props.colorMode === 'light'
          ? 'linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))'
          : 'linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))'
      };
      z-index: 0;
      opacity: 0.8;
      transition: ${props.theme.transitions.default};
      pointer-events: none;
    }
  `}
`;

StyledTileTitle.defaultProps = {
  colorMode: 'light',
};

// To be imported in the parent component so we can show hover state on parents hover
export const tileTitleHoverState = css`
  ${StyledTileTitle} {
    > h3 {
      bottom: 1em;
    }

    > div {
      bottom: 1em;
      visibility: visible;
      opacity: 0.8;
    }

    &:after {
      height: 5em;
    }
  }
`;

export default StyledTileTitle;
