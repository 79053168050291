import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 24.99 39.3' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M12.5,6.37c-0.03,0-0.05,0-0.08,0C9.27,6.39,6.26,7.79,4.19,10.2C3.91,10.52,3.95,11,4.27,11.28
	c0.32,0.28,0.81,0.24,1.08-0.08c1.79-2.07,4.37-3.27,7.08-3.29c0.02,0,0.05,0,0.07,0c2.51,0,4.87,0.97,6.65,2.74
	c1.8,1.79,2.79,4.17,2.79,6.7c0,2.42-0.92,4.72-2.58,6.48c-1.74,1.85-2.77,4.18-2.92,6.64h-1.5v-8.14h0.49
	c1.11,0,2.02-0.91,2.02-2.02v-0.1c0-1.11-0.91-2.02-2.02-2.02s-2.02,0.91-2.02,2.02v0.59h-1.84v-0.59c0-1.11-0.91-2.02-2.02-2.02
	H9.42c-1.11,0-2.02,0.91-2.02,2.02v0.1c0,1.11,0.91,2.02,2.02,2.02h0.62v8.14H8.56c-0.15-2.48-1.15-4.8-2.86-6.58
	c-1.77-1.84-2.7-4.26-2.63-6.81c0.01-0.42-0.32-0.78-0.75-0.79c-0.43-0.01-0.78,0.32-0.79,0.75c-0.08,2.97,1.01,5.78,3.06,7.91
	c1.58,1.65,2.45,3.83,2.45,6.16v0.12v2.1c0,1.64,1.25,3,2.84,3.17v0.17c0,1.44,1.17,2.61,2.61,2.61c1.44,0,2.61-1.17,2.61-2.61
	v-0.17c1.6-0.17,2.86-1.53,2.86-3.18v-2.1v-0.12c0-2.3,0.9-4.51,2.52-6.24c1.93-2.05,3-4.72,3-7.54c0-2.95-1.15-5.71-3.24-7.79
	C18.17,7.5,15.42,6.37,12.5,6.37z M14.95,20.21c0-0.27,0.22-0.49,0.49-0.49c0.27,0,0.49,0.22,0.49,0.49v0.1
	c0,0.27-0.22,0.49-0.49,0.49h-0.49V20.21z M9.42,20.8c-0.27,0-0.49-0.22-0.49-0.49v-0.1c0-0.27,0.22-0.49,0.49-0.49h0.13
	c0.27,0,0.49,0.22,0.49,0.49v0.59L9.42,20.8L9.42,20.8z M11.57,22.34h1.84v8.14h-1.84L11.57,22.34L11.57,22.34z M13.57,36.69
	c0,0.59-0.48,1.07-1.07,1.07s-1.07-0.48-1.07-1.07v-0.15h2.15V36.69z M16.43,33.34c0,0.92-0.74,1.66-1.66,1.66h-0.43h-3.68h-0.41
	c-0.91,0-1.66-0.74-1.66-1.66v-1.33h7.85V33.34z M11.73,3.63V0.77c0-0.42,0.34-0.77,0.77-0.77s0.77,0.34,0.77,0.77v2.87
	c0,0.42-0.34,0.77-0.77,0.77C12.07,4.4,11.73,4.06,11.73,3.63z M2.25,8.19L0.22,6.17c-0.3-0.3-0.3-0.79,0-1.09
	c0.3-0.3,0.79-0.3,1.09,0l2.03,2.03c0.3,0.3,0.3,0.79,0,1.09C3.19,8.34,2.99,8.42,2.79,8.42C2.6,8.42,2.4,8.34,2.25,8.19z
	 M24.76,5.08c0.3,0.3,0.3,0.79,0,1.09l-2.03,2.03c-0.15,0.15-0.35,0.22-0.54,0.22c-0.2,0-0.39-0.07-0.54-0.22
	c-0.3-0.3-0.3-0.79,0-1.09l2.03-2.03C23.98,4.78,24.46,4.78,24.76,5.08z M19.68,14.45c0.16,0.39-0.03,0.84-0.43,1
	c-0.09,0.04-0.19,0.06-0.29,0.06c-0.3,0-0.59-0.18-0.71-0.48c-0.96-2.37-3.22-3.9-5.78-3.9c-0.02,0-0.03,0-0.05,0c0,0,0,0-0.01,0
	c-0.42,0-0.76-0.34-0.77-0.76c0-0.42,0.34-0.77,0.76-0.77c0.02,0,0.04,0,0.06,0c1.57,0,3.08,0.47,4.37,1.35
	C18.13,11.81,19.1,13.02,19.68,14.45z M19.47,16.58c0.2,0,0.4,0.08,0.54,0.22c0.14,0.14,0.22,0.34,0.22,0.54
	c0,0.2-0.08,0.4-0.22,0.54c-0.14,0.14-0.34,0.22-0.54,0.22c-0.2,0-0.4-0.08-0.54-0.22c-0.14-0.14-0.22-0.34-0.22-0.54
	c0-0.2,0.08-0.4,0.22-0.54C19.07,16.66,19.27,16.58,19.47,16.58z M3.09,12.63c0.2,0,0.4,0.08,0.54,0.22
	C3.78,13,3.86,13.19,3.86,13.4c0,0.2-0.08,0.4-0.22,0.54c-0.14,0.14-0.34,0.22-0.54,0.22c-0.2,0-0.4-0.08-0.54-0.22
	C2.4,13.8,2.32,13.6,2.32,13.4c0-0.2,0.08-0.4,0.22-0.54C2.69,12.71,2.89,12.63,3.09,12.63z'
    />
  </svg>
);

export default SVG;
