import React from 'react';
import styled from 'styled-components';
import Icon from '../Icons';

// TODO: Remove classNameMenu prop and reference the component directly in the styled
interface CardActionsToggleProps {
  className?: string;
  toggleMenu: (e: React.MouseEvent | React.KeyboardEvent) => void;
  classNameOptions?: string;
  refVal: React.RefObject<HTMLDivElement>;
}

const CardActionsToggle = ({ className, classNameOptions, toggleMenu, refVal }: CardActionsToggleProps) => (
  <div
    className={`${className} ${classNameOptions}`}
    role='button'
    tabIndex={-1}
    onKeyDown={(e) => toggleMenu(e)}
    onClick={(e) => toggleMenu(e)}
    ref={refVal}
    id='chmln-card_actions_toggle'
  >
    <figure>
      <Icon name='close' />
    </figure>
  </div>
);

interface StyledCardActionsToggleProps {
  isMenuOpen: boolean;
  fixedColour?: boolean;
  colorMode?: 'light' | 'dark';
}

const StyledCardActionsToggle = styled(CardActionsToggle)<StyledCardActionsToggleProps>`
  position: absolute;
  top: 15px;
  left: 15px;
  width: 30px;
  height: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  overflow: hidden;
  user-select: none;
  font-weight: 400;
  transition: ${(props) => props.theme.transitions.default};
  outline: none;
  cursor: pointer;
  &.right {
    right: 10px;
    left: initial;
  }

  figure,
  figure:before,
  figure:after {
    position: absolute;
    background-color: ${(props) => (props.fixedColour ? '#fff' : 'var(--contrast)')};
    transition: ${(props) => props.theme.transitions.default};
    border-radius: 100%;
    ${(props) =>
      props.colorMode &&
      `
      background-color: ${props.colorMode === 'dark' ? 'black' : 'white'};
    `}
  }

  figure {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0 auto;
    width: 6px;
    height: 6px;
    opacity: 0.8;
    z-index: ${(props) => props.theme.zLayers.overlay};
    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;

      top: 0;
    }
    &:before {
      left: -9px;
    }
    &:after {
      right: -9px;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      margin: 0 auto;
      width: 1.2em;
      height: auto;
      border-radius: 100%;
      transition: ${(props) => props.theme.transitions.default};
      transform-origin: 0px 2.5px;
      opacity: 0;
      * {
        fill: rgba(var(--primary-color), 1);
      }
    }
  }

  ${(props) =>
    props.isMenuOpen &&
    `
    background-color: transparent;
    figure {
      background-color: transparent;
      height: 0;
      width: 0;
      &:before,
      &:after {
        opacity: 0;
      }
      svg {
        opacity: 1;
      }
    }
  `}
`;

StyledCardActionsToggle.defaultProps = {
  className: '',
  classNameOptions: 'options',
  fixedColour: false,
};

export default StyledCardActionsToggle;
