import styled from 'styled-components';
import Icon from '../Icons';

interface SearchResultsHeaderProps {
  className?: string;
  query: string;
  close?: () => void;
}

const SearchResultsHeader = ({ className, query, close }: SearchResultsHeaderProps) => (
  <div className={className}>
    Search for : <span>{query}</span>
    {close && (
      <button type='button' onClick={() => close()} id='chmln-close_search'>
        <Icon name='close' />
      </button>
    )}
  </div>
);

const StyledSearchResultsHeader = styled(SearchResultsHeader)`
  position: sticky;
  top: 0;
  width: 100%;
  background: var(--background-color);
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 32px;
  line-height: 56px;
  color: var(--foreground-color);
  font-weight: lighter;

  margin-bottom: 24px;
  padding: 11px 100px 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 0 30px;
    font-size: 18px;
    margin-bottom: 10px;
  }

  > span {
    font-weight: bold;
    margin-left: 15px;
  }
  button {
    margin-left: auto;
    color: inherit;
    cursor: pointer;
    svg {
      fill: currentColor;
    }
  }
`;

export default StyledSearchResultsHeader;
