import IconType from './type';

const Bell = ({ width = '32', height = '32', className = '', viewBox = '0 0 20.711 23.533' }: IconType) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    version='1.1'
    id='Capa_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    enableBackground='new 0 0 20 22'
    xmlSpace='preserve'
    className={`svg-icon ${className || ''}`}
  >
    <g transform='translate(-0.058)'>
      <path
        d='M20.233,15.4c-.025-.017-2.5-1.926-2.5-8.077a7.321,7.321,0,1,0-14.643,0c0,6.134-2.465,8.052-2.474,8.06a1.254,1.254,0,0,0,.7,2.3H19.527a1.243,1.243,0,0,0,1.187-.88A1.274,1.274,0,0,0,20.233,15.4Zm-3.3-.224H3.9A16.108,16.108,0,0,0,5.6,7.321a4.815,4.815,0,0,1,9.629,0A16.108,16.108,0,0,0,16.929,15.174Z'
        transform='translate(0)'
      />
      <path
        d='M93.95,241.325A1.239,1.239,0,0,0,93,241.2a1.227,1.227,0,0,0-.764.581.782.782,0,0,1-1.054.282.689.689,0,0,1-.274-.282,1.257,1.257,0,1,0-2.175,1.262,3.286,3.286,0,0,0,5.678-.008A1.252,1.252,0,0,0,93.95,241.325Z'
        transform='translate(-81.155 -221.137)'
      />
    </g>
  </svg>
);

export default Bell;
