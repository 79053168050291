import { getData, postData } from '../../APIHandler';
import { cacheTile } from '../../helpers/cache';
import { prependHTTP } from '../../components/Library/ItemForm/ValidateURL/ValidateURL';
import { b64DecodeUnicode } from '../../helpers/Utils';
import { TilePullFromApiType } from '../../types/tile';
import { ItemOrdering } from '../../components/Grid/GridTypes';

const extractFileName = (url: string) => {
  return url ? url.substring(url.lastIndexOf('/') + 1) : undefined;
};

interface TileAudienceType {
  AudienceId: string;
  AudienceName: string;
  AudienceTiles: TilePullFromApiType[];
}

const getAudienceTiles = (audienceTiles: TileAudienceType[], audienceString: string) => {
  return audienceTiles.filter((audience: TileAudienceType) => {
    const { AudienceId = '' } = audience;
    return AudienceId.startsWith(audienceString);
  });
};

const extractFeaturedTiles = (queryResponse: TileAudienceType[]) => {
  const featuredTiles = getAudienceTiles(queryResponse, 'GLOBAL:FEATURED_AUDIENCE');

  let tiles = featuredTiles.length > 0 ? featuredTiles[0].AudienceTiles || [] : [];

  // We can ony have 5 featured tiles
  if (tiles.length > 5) {
    tiles = tiles.slice(0, 5);
  }
  return tiles;
};

const extractCarouselTiles = (queryResponse: TileAudienceType[]) => {
  const carouselTiles = getAudienceTiles(queryResponse, 'GLOBAL:CAROUSEL_AUDIENCE');

  return carouselTiles.length > 0 ? [...carouselTiles[0].AudienceTiles] : [];
};

const extractAudienceTiles = (queryResponse: TileAudienceType[]) => {
  const audienceTiles = getAudienceTiles(queryResponse, 'AUDIENCE_MANAGED_TILES');

  const pickAudienceTiles = (t: TileAudienceType) => {
    if (Array.isArray(t.AudienceTiles)) return t.AudienceTiles;

    return null;
  };

  return audienceTiles
    .map(pickAudienceTiles)
    .filter((a: TilePullFromApiType[] | null) => a !== null && a.length > 0)
    .flatMap((a: TilePullFromApiType[] | null) => a);
};

const extractUserTiles = (queryResponse: TileAudienceType[]) => {
  const userTiles = getAudienceTiles(queryResponse, 'USER_AUDIENCE:');

  return userTiles.length > 0 ? userTiles[0].AudienceTiles || [] : [];
};

interface UrlData {
  fileName: string;
  identifier: string;
}

interface PresignedIdentifier {
  [id: string]: {
    presignedURLWrite: string;
    presignedURLRead: string;
    s3URL: string;
  };
}

interface PresignedUrlData {
  Identifier: string;
  PresignedURLRead: string;
  PresignedURLWrite: string;
  S3URL: string;
}

export const generateMultiplePresignedUrls = async (organisationId: string, urlData: UrlData[]) => {
  const processedURLData = {
    generatePresigned: urlData.map((data: UrlData) => ({ ...data, fileName: extractFileName(data.fileName) })),
  };
  const presignedResponse = await postData('CrazyDiamondAPI', `/presign/${organisationId}/`, processedURLData);

  return presignedResponse.queryResponse?.PresignedURLS.reduce((acc: PresignedIdentifier, presigned: PresignedUrlData) => {
    acc[presigned.Identifier] = {
      presignedURLWrite: Buffer.from(presigned.PresignedURLWrite, 'base64').toString('ascii'),
      presignedURLRead: Buffer.from(presigned.PresignedURLRead, 'base64').toString('ascii'),
      s3URL: presigned.S3URL,
    };
    return acc;
  }, {});
};

export const getHubTiles = async () => {
  const getTilesRes = await getData('CrazyDiamondViewMyTilesAPI', '', '');

  const { queryResponse = [] } = getTilesRes;

  const featuredTiles = extractFeaturedTiles(queryResponse);
  const carouselTiles = extractCarouselTiles(queryResponse);
  const audienceTiles = extractAudienceTiles(queryResponse);
  const userTiles = extractUserTiles(queryResponse);

  [...featuredTiles, ...carouselTiles, ...audienceTiles, ...userTiles].forEach((tile) => {
    const decoded = [
      tile?.thumbnailUrl ? b64DecodeUnicode(tile.thumbnailUrl) : '',
      tile?.thumbnailUrlCroppedLandscape ? b64DecodeUnicode(tile.thumbnailUrlCroppedLandscape) : '',
      tile?.thumbnailUrlCroppedPortrait ? b64DecodeUnicode(tile.thumbnailUrlCroppedPortrait) : '',
      tile?.thumbnailUrlCroppedSquare ? b64DecodeUnicode(tile.thumbnailUrlCroppedSquare) : '',
    ];
    decoded.forEach((url) => {
      cacheTile(url);
    });
  });

  return {
    featuredTiles,
    carouselTiles,
    audienceTiles,
    userTiles,
  };
};

export const addTile = async (tile: TilePullFromApiType) => {
  const newTile = {
    ...tile,
    clickUrl: encodeURIComponent(prependHTTP(tile.clickUrl)),
    thumbnailUrl: encodeURIComponent(tile.thumbnailUrl),
    thumbnailUrlCroppedLandscape: encodeURIComponent(tile.thumbnailUrlCroppedLandscape),
    thumbnailUrlCroppedPortrait: encodeURIComponent(tile.thumbnailUrlCroppedPortrait),
    thumbnailUrlCroppedSquare: encodeURIComponent(tile.thumbnailUrlCroppedSquare),
    presignedThumbnailUrl: '',
    presignedThumbnailURLSquare: '',
    presignedThumbnailURLLandscape: '',
  };

  return postData('CrazyDiamondAudienceAPIOrgID', `addnewtiletoaudience/`, newTile);
};

export const deleteTile = async (deletedItem: TilePullFromApiType) => {
  const deletion = {
    deleteType: 'TOTAL',
    tileId: deletedItem.tileId,
  };

  const deleteRes = await postData('CrazyDiamondTileDeleteAPI', '', deletion);

  return deleteRes;
};

export const getTile = async (tileId: string) => {
  try {
    const tileRes = await getData('CrazyDiamondLoadTileAPI', `?TileID=${tileId}`);
    const presignedURLs = {
      presignedThumbnailURL: tileRes.queryResponse.presignedThumbnailURL,
      presignedThumbnailURLSquare: tileRes.queryResponse.presignedThumbnailURLSquare,
      presignedThumbnailURLLandscape: tileRes.queryResponse.presignedThumbnailURLLandscape,
      presignedThumbnailURLPortrait: tileRes.queryResponse.presignedThumbnailURLPortrait,
    };

    const tile = {
      ...tileRes?.queryResponse?.tile,
    };

    return {
      tile,
      presignedURLs,
      tileAudiences: tileRes.queryResponse.tileAudiences,
    };
  } catch (err) {
    return err;
  }
};

export const editTile = async (tileData: TilePullFromApiType) => {
  const encodeUrls = {
    clickUrl: encodeURIComponent(tileData.clickUrl),
    thumbnailUrl: encodeURIComponent(tileData.thumbnailUrl),
    thumbnailUrlCroppedLandscape: tileData.thumbnailUrlCroppedLandscape ? encodeURIComponent(tileData.thumbnailUrlCroppedLandscape) : '',
    thumbnailUrlCroppedPortrait: tileData.thumbnailUrlCroppedPortrait ? encodeURIComponent(tileData.thumbnailUrlCroppedPortrait) : '',
    thumbnailUrlCroppedSquare: tileData.thumbnailUrlCroppedSquare ? encodeURIComponent(tileData.thumbnailUrlCroppedSquare) : '',
  };
  const preUpdatedTile = {
    ...tileData,
    ...encodeUrls,
    searchClickUrl: '',
    searchName: '',
  };
  const updatedTile = {
    ...tileData,
    ...encodeUrls,
    presignedThumbnailUrl: '',
    presignedThumbnailUrlCroppedSquare: '',
    presignedThumbnailUrlCroppedLandscape: '',
  };

  const editTileRes = await postData('CrazyDiamondTileAPIEditTile', '', { before: preUpdatedTile, after: updatedTile });
  return editTileRes?.queryResponse;
};

export const postItemOrder = async (itemOrdering: ItemOrdering) => {
  const TileOrderRecord = {
    AudienceID: `USER_AUDIENCE:`,
    TileOrder: [itemOrdering],
  };

  await postData('CrazyDiamondTileOrderAPI', '', TileOrderRecord);
  const tiles = await getHubTiles();

  return tiles;
};

export default { deleteTile, getHubTiles, editTile, getTile, addTile, postItemOrder };
