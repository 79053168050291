import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 13.999 12' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M1898.662,20.446a1,1,0,0,0-.217-1.09l-5-5a1,1,0,0,0-1.414,1.414l3.293,3.293h-9.586a1,1,0,0,0,0,2h9.586l-3.293,3.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,1898.662,20.446Z'
      transform='translate(1898.738 26.064) rotate(180)'
    />
  </svg>
);

export default SVG;
