/* eslint-disable no-param-reassign */
import { useState, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { unixToDate, formatDate } from '../../../helpers/Utils';
import { translate } from '../../../i18n/translate';
import { Collection, Item } from '../../../types/library';
import useIntersectionObserver from '../../../helpers/useIntersectionObserverHook';
import Icon from '../../Icons';
import ItemCardExpandButton from './ItemCardExpandButton';
import ItemCardInfoPanel from './ItemCardInfoPanel';
import ItemCardInner from './ItemCardInner';
import ItemCardThumbnail from './ItemCardThumbnail';
import StyledItemCard from './StyledItemCard';
import ItemCardInfo from './ItemCardInfo';
import CardActions from '../../CardActions/CardActions';

export interface ItemCardProps {
  collection?: Collection;
  item: Item;
  onEdit?: (item: Item) => void;
  onDelete?: (confirmation: string) => void;
  onView?: () => void;
  isSearch?: boolean;
  isShared?: boolean;
}

const ItemCard = ({ collection, item, onEdit, onDelete, onView, isShared = false, isSearch = false }: ItemCardProps) => {
  const history = useHistory();

  if (!item?.link.startsWith('http')) {
    item.link = `https://${item.link}`;
  }

  const itemRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useIntersectionObserver({
    target: itemRef,
    onIntersect: (
      [{ isIntersecting }]: Array<{ isIntersecting: boolean }>,
      observerElement: { unobserve: (arg0: HTMLDivElement | null) => void },
    ) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(itemRef.current);
      }
    },
  });

  const handleDelete = () => {
    if (onDelete) {
      onDelete(item.id);
    }
  };

  const handleEdit = () => {
    if (onEdit) {
      onEdit(item);
    }
  };

  const handleMove = () => {
    history.push({
      pathname: `/library/move`,
      state: { collection, item },
    });
  };

  const actions = [
    {
      onClick: handleEdit,
      text: 'Edit',
      icon: 'edit',
    },
    {
      onClick: handleMove,
      text: 'Move',
      icon: 'move',
    },
    {
      onClick: handleDelete,
      text: 'Delete',
      icon: 'delete',
    },
  ];

  const getAccurateDate = (date: number) => {
    const secondsNow = dayjs().unix();

    if (date > secondsNow) {
      return formatDate(date);
    }

    return unixToDate(date);
  };

  return (
    <StyledItemCard ref={itemRef} onMouseLeave={() => setExpanded(false)} isExpanded={expanded} isSearch={isSearch}>
      {onEdit && onDelete && !isShared && <CardActions actions={actions} position='left' classNameOptions='options' classNameMenu='menu' />}

      <ItemCardInner>
        <a
          href={item.link}
          target='_blank'
          rel='noreferrer'
          onClick={() => {
            if (onView) onView();
          }}
        >
          <span aria-hidden='true'>Open</span>
        </a>
        <ItemCardThumbnail item={item} isVisible={isVisible} />
        <ItemCardInfo>
          <h3>{item.name}</h3>
          <p>
            {translate('Updated')} - <b>{getAccurateDate(item.dateAdded)}</b>
          </p>
        </ItemCardInfo>
      </ItemCardInner>

      <ItemCardExpandButton type='button' onClick={() => setExpanded(!expanded)} active={expanded}>
        <Icon name='circle-arrow-down' />
      </ItemCardExpandButton>

      {expanded && (
        <AnimatePresence>
          <ItemCardInfoPanel>
            <h4>Link</h4>
            <a href={item.link} target='_blank' rel='noreferrer'>
              {item.link}
            </a>

            {item.description && (
              <>
                <h4>Description</h4>
                <p>{item.description}</p>
              </>
            )}
          </ItemCardInfoPanel>
        </AnimatePresence>
      )}
    </StyledItemCard>
  );
};

export default ItemCard;
