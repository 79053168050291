import IconType from './type';

const SVG = ({ style = {}, width = '22', className = '', viewBox = '0 0 163.2 163.2' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      d='M117.5,64.2c-3.6-3.6-9.5-3.6-13.2,0l-24,24l-24-24c-3.6-3.6-9.5-3.6-13.2,0c-1.8,1.8-2.7,4.1-2.7,6.6c0,2.5,1,4.8,2.7,6.6
	l30.6,30.7c1.8,1.8,4.1,2.7,6.6,2.7c2.5,0,4.8-1,6.6-2.8l30.6-30.6c1.7-1.7,2.7-4.1,2.7-6.7C120.1,68.3,119.1,65.9,117.5,64.2z'
    />
    <g>
      <g>
        <path
          d='M0,81.6C0,36.6,36.6,0,81.6,0s81.6,36.6,81.6,81.6s-36.6,81.6-81.6,81.6C36.6,163.2,0,126.6,0,81.6z
			 M147.5,81.6c0-36.3-29.6-65.9-65.9-65.9S15.7,45.3,15.7,81.6c0,36.3,29.6,65.9,65.9,65.9S147.5,117.9,147.5,81.6z'
        />
      </g>
    </g>
  </svg>
);

export default SVG;
