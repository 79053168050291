import styled from 'styled-components';

const ItemCardInfo = styled.div`
  flex-grow: 1;
  h3 {
    font-size: 21px;
    margin: -7px 0px 0.5rem;
    padding: 0px 1rem 0px 0px;
    font-weight: 700;
  }
  p {
    font-size: 15px;
    margin: 0;
    padding: 0;
    color: var(--grey3);
  }
  @media only screen and (max-width: ${(props) => props?.theme?.breakpoints?.mobile}) {
    max-width: unset;

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }
  }
`;

export default ItemCardInfo;
