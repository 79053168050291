import IconType from './type';

const SVG = ({ style = {}, fill = '#000', width = '156', height = '144', className = '', viewBox = '0 0 89.54 71.46' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_2-2' data-name='Layer 2'>
        <path
          fill={fill}
          d='M89.54,71.46h-4A2.46,2.46,0,0,0,83.08,69H6.46A2.46,2.46,0,0,0,4,71.46H0A6.47,6.47,0,0,1,6.46,65H34V60h4v5H51V60h4v5H83.08A6.47,6.47,0,0,1,89.54,71.46Z'
        />
        <path fill={fill} d='M69.1,56a12.26,12.26,0,0,1-2.36.23H22.8A12.26,12.26,0,0,1,20.44,56Z' />
        <path
          fill={fill}
          d='M66.74,0H22.8a12,12,0,0,0-12,12V44.23A12,12,0,0,0,20.44,56a12.26,12.26,0,0,0,2.36.23H66.74A12.26,12.26,0,0,0,69.1,56a12,12,0,0,0,9.64-11.77V12A12,12,0,0,0,66.74,0Zm8,44.23a8,8,0,0,1-8,8H22.8a8,8,0,0,1-8-8V12a8,8,0,0,1,8-8H66.74a8,8,0,0,1,8,8Z'
        />
        <path
          fill={fill}
          d='M44.77,8.85A19.27,19.27,0,1,0,64,28.11,19.29,19.29,0,0,0,44.77,8.85Zm0,36.53A17.27,17.27,0,1,1,62,28.11,17.29,17.29,0,0,1,44.77,45.38Z'
        />
        <path fill={fill} d='M44.77,16.11a12,12,0,1,0,12,12A12,12,0,0,0,44.77,16.11Zm0,22a10,10,0,1,1,10-10A10,10,0,0,1,44.77,38.11Z' />
        <circle fill={fill} cx='44.77' cy='28.11' r='6' />
        <circle fill={fill} cx='66.69' cy='13.04' r='2.74' />
      </g>
    </g>
  </svg>
);

export default SVG;
