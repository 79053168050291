import IconType from './type';

const SVG = ({ style = {}, width = '24', height = '24', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={`svg-icon ${className || ''}`}
  >
    <polygon points='11 5 6 9 2 9 2 15 6 15 11 19 11 5' />
    <path d='M15.54 8.46a5 5 0 0 1 0 7.07' />
  </svg>
);

export default SVG;
