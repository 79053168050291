import React from 'react';
import { ThemeProvider } from 'styled-components';
import baseTheme from '../theming/baseTheme';

interface ThemeWrapperProps {
  children: React.ReactNode;
}

const ThemeWrapper = ({ children }: ThemeWrapperProps) => {
  return <ThemeProvider theme={baseTheme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
