import * as React from 'react';
import Loader from '../components/UI/Loader/Loader';

import { useAsync } from '../helpers/useAsyncHook';
import { updateOrganisationAttribute, loadOrganisationAttributes, updateOrganisationConfig } from '../api/wrappers/OrganisationAPI';
import { generateMultiplePresignedUrls } from '../api/wrappers/TileAPI';

const defaultDarkPrimary = '#fb6664';
const defaultLightPrimary = '#fb6664';

const defaultOrganisationAttributes = {
  band: { Name: 'Band', Type: 'prepopulated' },
  department: { Name: 'Department', Type: 'prepopulated' },
  email: { Name: 'Email', Type: 'string' },
  employeeId: { Name: 'EmployeeID', Type: 'string' },
  gender: { Name: 'Gender', Type: 'prepopulated' },
  geo: { Name: 'Geo', Type: 'prepopulated' },
  isManager: { Name: 'IsManager', Type: 'bool' },
  location: { Name: 'Location', Type: 'prepopulated' },
  name: { Name: 'Full Name', Type: 'string' },
  phone: { Name: 'Phone', Type: 'string' },
  role: { Name: 'Role', Type: 'prepopulated' },
  startDate: { Name: 'Start date', Type: 'date' },
};

const conformAttributeTypes = (attributes) => {
  const updatedAttrTypes = { ...defaultOrganisationAttributes };
  Object.keys(attributes).forEach((key) => {
    if (defaultOrganisationAttributes[key]) updatedAttrTypes[key].Type = defaultOrganisationAttributes[key].Type;
    if (defaultOrganisationAttributes[key].Type === 'prepopulated') {
      updatedAttrTypes[key].PossibleValues = attributes[key].PossibleValues || [];
      updatedAttrTypes[key].PossibleValues.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      updatedAttrTypes[key].Name = attributes[key].Name;
    }
  });
  return updatedAttrTypes;
};

async function bootstrapOrgData() {
  try {
    const config = await loadOrganisationAttributes();

    const organisationAttributes = conformAttributeTypes(config.OrganisationAttributes);

    const batchedPresigns = [];
    if (config.HulerConfigParameters.darkModeLogoURL) {
      batchedPresigns.push(
        {
          identifier: 'DarkModeImage',
          fileName: config.HulerConfigParameters.darkModeLogoURL,
        },
        {
          identifier: 'DarkModeFavicon',
          fileName: config.HulerConfigParameters.darkModeFaviconURL,
        },
        {
          identifier: 'LightModeImage',
          fileName: config.HulerConfigParameters.lightModeLogoURL,
        },
        {
          identifier: 'LightModeFavicon',
          fileName: config.HulerConfigParameters.lightModeFaviconURL,
        },
      );
    }
    const todayPresign = {
      identifier: 'TodayTileThumbnail',
      fileName: config?.HulerConfigParameters?.todayTileURL?.thumbnailUrl ? config.HulerConfigParameters.todayTileURL.thumbnailUrl : '',
    };

    const allPresigns = [...batchedPresigns, todayPresign];

    const batchedResults = await generateMultiplePresignedUrls(config.OrganisationId, allPresigns);

    config.presignedUrls = {
      ...config.presignedUrls,
      presignedDarkModeLogoURL: batchedResults.DarkModeImage?.presignedURLRead,
      presignedDarkModeFaviconURL: batchedResults.DarkModeFavicon?.presignedURLRead,
      presignedLightModeLogoURL: batchedResults.LightModeImage?.presignedURLRead,
      presignedLightModeFaviconURL: batchedResults.LightModeFavicon?.presignedURLRead,
      presignedTodayTileURL: batchedResults.TodayTileThumbnail.presignedURLRead,
    };

    const isDefaultBranding =
      !config.HulerConfigParameters.darkModePrimaryColour ||
      (config.HulerConfigParameters.darkModePrimaryColour === defaultDarkPrimary &&
        config.HulerConfigParameters.lightModePrimaryColour === defaultLightPrimary);

    return {
      ...config,
      organisationAttributes,
      organisationCapabilities: config.OrganisationCapabilities,
      organisationId: config.OrganisationId,
      isDefaultBranding,
    };
  } catch (error) {
    return error;
  }
}

const OrgContext = React.createContext();

function OrgProvider(props) {
  const { data: config, status, error, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync();

  React.useEffect(() => {
    const orgDataPromise = bootstrapOrgData();
    run(orgDataPromise);
  }, [run]);

  const updateOrgAttribute = React.useCallback(
    (initialAttribute, payload) =>
      updateOrganisationAttribute(initialAttribute, payload).then(() => {
        const up = {
          attribute: initialAttribute,
          value: {
            Name: payload.customName,
            PossibleValues: payload.values,
          },
        };

        if (up.value && up.value.PossibleValues) up.value.PossibleValues.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

        const updatedAttributes = {
          ...config.organisationAttributes,
          [initialAttribute]: {
            ...config.organisationAttributes[initialAttribute],
            ...up.value,
          },
        };

        setData({
          ...config,
          organisationAttributes: updatedAttributes,
        });
      }),
    [setData, config],
  );

  // After we perform a csv upload we need to refesh the org attributes
  const refreshOrgAttributes = React.useCallback(
    (updatedOrgAttributes) => {
      setData({
        ...config,
        organisationAttributes: updatedOrgAttributes,
      });
    },
    [setData, config],
  );

  // The huler organisation is used for admin purposes such as changing licenses, deleteing organisaions etc.
  // This function is called to check to see if we should enable organisation admin
  // Capabilities within huler.
  const isHulerOrganisation = React.useCallback(() => {
    return config.OrganisationId === 'huler';
  }, [config]);

  const updateOrgConfig = React.useCallback(
    async (payload, { presignedUrls = {} } = {}) => {
      // should update this to only update whats needed, not everything
      const configUpdates = {
        HulerConfigParameters: {
          ...config.HulerConfigParameters,
          ...payload,
        },
      };

      configUpdates.isDefaultBranding =
        !configUpdates.HulerConfigParameters.darkModePrimaryColour ||
        (configUpdates.HulerConfigParameters.darkModePrimaryColour === defaultDarkPrimary &&
          configUpdates.HulerConfigParameters.lightModePrimaryColour === defaultLightPrimary);

      configUpdates.HulerConfigParameters.darkModeLogoURL = configUpdates.HulerConfigParameters.darkModeLogoURL
        ? encodeURIComponent(configUpdates.HulerConfigParameters.darkModeLogoURL)
        : '';
      configUpdates.HulerConfigParameters.darkModeFaviconURL = configUpdates.HulerConfigParameters.darkModeLogoURL
        ? encodeURIComponent(configUpdates.HulerConfigParameters.darkModeFaviconURL)
        : '';
      configUpdates.HulerConfigParameters.lightModeLogoURL = configUpdates.HulerConfigParameters.darkModeLogoURL
        ? encodeURIComponent(configUpdates.HulerConfigParameters.lightModeLogoURL)
        : '';
      configUpdates.HulerConfigParameters.lightModeFaviconURL = configUpdates.HulerConfigParameters.darkModeLogoURL
        ? encodeURIComponent(configUpdates.HulerConfigParameters.lightModeFaviconURL)
        : '';

      await updateOrganisationConfig(configUpdates);

      let unparsedUpdates = {
        HulerConfigParameters: {
          ...config.HulerConfigParameters,
          ...payload,
        },
      };

      const newPresigned = {
        ...config.presignedUrls,
        ...presignedUrls,
      };

      if (Object.keys(newPresigned).length) {
        unparsedUpdates = { ...unparsedUpdates, presignedUrls: newPresigned };
      }

      setData({
        ...config,
        ...unparsedUpdates,
      });
    },
    [setData, config],
  );

  const value = React.useMemo(
    () => ({ config, updateOrgAttribute, refreshOrgAttributes, updateOrgConfig, isHulerOrganisation }),
    [config, updateOrgAttribute, refreshOrgAttributes, updateOrgConfig, isHulerOrganisation],
  );

  if (isLoading || isIdle) {
    return <Loader fullscreen style={{ width: '80px', height: '80px' }} />;
  }

  if (isError) {
    return <div>Error Occurred {error.message} </div>;
  }

  if (isSuccess) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <OrgContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useOrg() {
  const context = React.useContext(OrgContext);
  if (context === undefined) {
    throw new Error(`useOrg must be used within an Org Provider`);
  }

  return context;
}

export { OrgProvider, useOrg };
