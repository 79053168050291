import IconType from './type';

const SVG = ({ style = {}, width = '21', height = '22', className = '', viewBox = '0 0 21 22' }: IconType) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill='#fff'
      d='M4.5 14a3.51 3.51 0 002.36-.93l6.26 3.58c-.08.276-.12.562-.12.85a3.53 3.53 0 101.14-2.57l-6.26-3.58a2.74 2.74 0 00.12-.76l6.15-3.52A3.49 3.49 0 1013 4.5c.004.287.044.573.12.85L7.43 8.6A3.5 3.5 0 104.5 14zm12 2a1.5 1.5 0 110 2.999 1.5 1.5 0 010-2.999zm0-13a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-12 6a1.5 1.5 0 110 3 1.5 1.5 0 010-3z'
    />
  </svg>
);

export default SVG;
