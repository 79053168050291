import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '13', height = '12', className = '', viewBox = '0 0 33.6126 29.6459' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M28.9493,9.9198c-0.1696-0.1478-0.2719-0.3524-0.2873-0.5771c-0.0322-0.463,0.3181-0.8659,0.7811-0.8981
	c0.02-0.0014,0.0401-0.0021,0.0601-0.0021c0.2029,0,0.3964,0.0723,0.551,0.2065c0.1696,0.1474,0.2719,0.3524,0.2873,0.5768
	c0.0322,0.463-0.3181,0.8659-0.7814,0.8984C29.3422,10.1391,29.1193,10.0672,28.9493,9.9198z M25.2045,4.9381
	c0.6068,0.3159,1.4445,0.9228,1.9876,2.0173c0.1821,0.3675,0.5907,0.5525,0.9686,0.4455l0.0054-0.0014
	c0.2315-0.0666,0.4204-0.2311,0.5181-0.4516c0.1005-0.2268,0.0948-0.4863-0.0157-0.7113c-0.7299-1.4849-1.8717-2.3129-2.7011-2.7462
	c-0.1152-0.0601-0.2408-0.0905-0.3668-0.0905c-0.1124,0-0.2254,0.024-0.332,0.0719c-0.2329,0.1052-0.4015,0.3074-0.4623,0.555
	C24.7161,4.3942,24.8796,4.7688,25.2045,4.9381z M16.2719,29.452l-0.044-0.0386c-1.4287-1.2498-2.7716-2.3944-4.07-3.5015
	l-0.054-0.0462c-3.5219-3.0006-6.5636-5.5921-8.6728-8.1014C1.0902,14.981,0,12.3887,0,9.6067c0-2.5526,0.8641-4.9388,2.4331-6.7196
	C4.074,1.0251,6.3503,0,8.8424,0c3.5018,0,5.7224,2.0964,6.9686,3.855c0.297,0.4193,0.5775,0.8745,0.834,1.3521l0.1614,0.3009
	l0.1614-0.3009c0.2555-0.477,0.536-0.9317,0.8337-1.3521C19.0478,2.0964,21.2683,0,24.7701,0c2.4925,0,4.7685,1.0251,6.409,2.8871
	c1.5693,1.7804,2.4334,4.167,2.4334,6.7196c0,2.7819-1.0902,5.3742-3.431,8.1576c-2.1103,2.5104-5.1513,5.1012-8.6889,8.1154
	c-1.3103,1.1171-2.6653,2.2717-4.108,3.5337l-0.0433,0.0383C17.0451,29.7107,16.5675,29.7104,16.2719,29.452z M16.6863,27.6576
	l0.1202,0.1045l0.1202-0.1045c1.2044-1.0473,2.3483-2.0223,3.46-2.9694l0.0687-0.0587C27.4,18.7114,31.9882,14.8021,31.9882,9.6067
	c0-2.1569-0.7203-4.162-2.0277-5.6458c-1.3278-1.5064-3.1712-2.3361-5.1903-2.3361c-2.8081,0-4.6189,1.7235-5.6429,3.1694
	c-0.911,1.2856-1.3865,2.5819-1.5486,3.0793c-0.1091,0.3353-0.4193,0.5607-0.7721,0.5607c-0.3528,0-0.6634-0.2254-0.7725-0.5607
	c-0.1621-0.4973-0.6376-1.7937-1.5486-3.0793c-1.0244-1.4459-2.8345-3.1694-5.6429-3.1694c-2.0191,0-3.8625,0.8298-5.1903,2.3361
	c-1.3074,1.4838-2.0277,3.489-2.0277,5.6458c0,5.1953,4.5881,9.1047,11.5328,15.0228l0.0698,0.0594
	C14.3383,25.636,15.4819,26.6107,16.6863,27.6576z'
    />
  </svg>
);

export default SVG;
