import IconType from './type';

const SVG = ({ style = {}, width = '24', height = '24', className = '', viewBox = '0 0 24 24' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      d='M22.5 11.3c-.7 0-1.2.6-1.2 1.2v6.1c0 1.4-1.2 2.6-2.6 2.6H5.1c-1.4 0-2.6-1.2-2.6-2.6V5.1c0-1.4 1.2-2.6 2.6-2.6h6.3c.7 0 1.2-.6 1.2-1.2S12.1 0 11.4 0H5.1C2.3 0 0 2.3 0 5.1v13.6c0 2.8 2.3 5.1 5.1 5.1h13.6c2.8 0 5.1-2.3 5.1-5.1v-6.1c-.1-.7-.6-1.3-1.3-1.3z'
      className='st0'
    />
    <path
      d='M22.5 0h-6.7c-.7 0-1.2.6-1.2 1.2s.6 1.2 1.2 1.2h3.5L10.9 11c-.5.5-.5 1.3 0 1.8.2.2.6.4.9.4.3 0 .6-.1.9-.4l8.6-8.7V8c0 .7.6 1.2 1.2 1.2s1.2-.6 1.2-1.2V1.3c0-.7-.5-1.3-1.2-1.3z'
      className='st0'
    />
  </svg>
);

export default SVG;
