/* eslint-disable no-param-reassign */
import { useState, useEffect, MouseEvent } from 'react';
import styled from 'styled-components';
import { useOrg } from 'contexts/OrgContext';
import { TileProps } from 'types/tile';
import { useInView } from 'react-intersection-observer';
import { sendStats } from 'api/handlers/StatsHandler';
import TileWrapper from '../TileWrapper';
import TileTitle from '../TileTitle';
import LinkTileImage from './LinkTileImage';

interface LinkTileProps {
  className?: string;
  tile: TileProps;
  hasLink?: boolean;
  hideShadow?: boolean;
  isHighlighted?: boolean;
  onDelete?: (tile: TileProps) => void;
  onEdit?: (tile: TileProps) => void;
  onClick?: () => void;
  addToCollection?: (tile: TileProps) => void;
  addToUserTiles?: (tile: TileProps) => void;
}

const LinkTile = ({
  className = '',
  tile,
  hasLink = true,
  isHighlighted = false,
  hideShadow = false,
  onDelete,
  onEdit,
  onClick,
  addToCollection,
  addToUserTiles,
}: LinkTileProps) => {
  const {
    config: { organisationId },
  } = useOrg();
  const [ref, inView] = useInView();
  const [isVisible, setIsVisible] = useState(false);
  const colorMode = tile.isLightMode ? 'light' : 'dark';

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  const clickHandler = (e: MouseEvent, url: string) => {
    e.preventDefault();
    if (onClick) {
      onClick();
      return;
    }

    if (!organisationId || !url || !hasLink) return;

    if (!url.startsWith('http')) {
      url = `http://${url}`;
    }

    const decodedUrl = decodeURI(url);

    sendStats(tile, 'tileClick', organisationId);
    window.open(decodedUrl, '_blank');
  };

  const actions = [];

  if (addToCollection) {
    actions.push({
      onClick: () => addToCollection(tile),
      icon: 'add-to-collection',
      text: 'Add To Library',
    });
  }
  if (addToUserTiles) {
    actions.push({
      onClick: () => addToUserTiles(tile),
      icon: 'plus',
      text: 'Add To My Tiles',
    });
  }
  if (onEdit) {
    actions.push({
      onClick: () => onEdit(tile),
      icon: 'edit',
      text: 'Edit Tile',
    });
  }
  if (onDelete) {
    actions.push({
      onClick: () => onDelete(tile),
      icon: 'delete',
      text: 'Delete Tile',
    });
  }

  return (
    <TileWrapper cardActions={actions} colorMode={colorMode} hideShadow={hideShadow} isHighlighted={isHighlighted}>
      <button ref={ref} type='button' className={`${className} tile`} onClick={(e: MouseEvent) => clickHandler(e, tile.clickUrl)}>
        {isVisible && <LinkTileImage tile={tile} />}
        <TileTitle title={tile.name} colorMode={colorMode} />
      </button>
    </TileWrapper>
  );
};

const StyledLinkTile = styled(LinkTile)`
  cursor: pointer;
  height: 100%;
  width: 100%;
  font-size: inherit;

  ${TileTitle} {
    position: relative;
    z-index: ${(props) => props.theme.zLayers.overlay};
  }
`;

export default StyledLinkTile;
