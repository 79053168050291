import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Icon from '../Icons';
import { verticalCenter } from '../../theming/mixins';

export interface CardActionsButtonProps {
  className?: string;
  icon: string;
  text: string;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  i?: number;
}

const CardActionsButton = ({ className, icon, text, onClick, i = 1 }: CardActionsButtonProps) => {
  const textRef = useRef<HTMLElement | null>(null);
  const [width, setWidth] = useState<number>(0);
  const [hover, setHover] = useState<boolean>(false);
  const variants = {
    animate: { y: 0, opacity: 1 },
    initial: { y: -30, opacity: 0 },
    exit: { y: -30, opacity: 0 },
    transition: { duration: 0.2, delay: 0.15 * i },
  };

  useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.getBoundingClientRect().width + 45);
    }
  }, []);
  return (
    <motion.button
      className={className}
      onClick={onClick}
      onKeyDown={onClick}
      animate={variants.animate}
      initial={variants.initial}
      exit={variants.exit}
      transition={variants.transition}
      type='button'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ width: `${hover ? `${width}px` : `2.2em`}` }}
    >
      <Icon name={icon} />
      <span ref={textRef}>{text}</span>
    </motion.button>
  );
};

interface StyledCardActionsButtonProps {
  colorMode?: 'light' | 'dark';
}

const StyledCardActionsButton = styled(CardActionsButton)<StyledCardActionsButtonProps>`
  cursor: pointer;
  width: 2.2em;
  position: relative;
  height: 2.2em;
  line-height: 2.2em;
  margin-bottom: 0.5em;
  border-radius: 2.2em;
  overflow: hidden;
  user-select: none;
  font-weight: 400;
  z-index: 0;
  background: ${(props) => (props.colorMode === 'dark' ? 'black' : 'white')};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    right: 0;
  }

  span {
    ${verticalCenter}
    font-size: 0.8em;
    left: 2.8em;
    font-weight: 600;
    white-space: nowrap;
    opacity: 0;
  }

  svg {
    width: 2.2em;
    height: auto;
    margin: 0;
    background: transparent;
    display: inline-block;
    ${verticalCenter}
    left: 0;
    top: 0;
    transform: scale(0.5);
    * {
      fill: rgba(var(--primary-color), 1);
    }
  }

  &:hover {
    transition: ${(props) => props.theme.transitions.default};
    height: 2.2em;
    line-height: 2.2em;
    margin-bottom: 0.5em;
    border-radius: 2.2em;
    background-color: rgba(var(--primary-color), 1);
    color: #fff;
    span {
      opacity: 1;
    }
    svg {
      * {
        fill: #fff;
      }
    }
  }
`;

export default StyledCardActionsButton;
