import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import { getData, postData, deleteData } from '../../APIHandler';
import { ItemOrdering } from '../../components/Grid/GridTypes';

import { b64DecodeUnicode, b64EncodeUnicode, shortenString, isUrl } from '../../helpers/Utils';
import { Collection, Item } from '../../types/library';

const statusResponseOK = 'success';
const loadLibraryResponse = 'Successfully read collection.';
const LibraryCollectionLoadPhaseLoaded = 'loaded';
const libraryLoadReponse = 'Successfully read library.';

const setSentryScopeFatalLibrary = (error: string) => {
  Sentry.configureScope((scope) => {
    scope.setLevel(Severity.Fatal);
    scope.setTag('libraryAPI', null);
    scope.setFingerprint(['type:apifailure']);
  });
  Sentry.captureException(error);
};

export const scraperMockAPIData = [
  'https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/691034/pexels-photo-691034.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/910307/pexels-photo-910307.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/2132126/pexels-photo-2132126.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/258045/pexels-photo-258045.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/640809/pexels-photo-640809.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/3363359/pexels-photo-3363359.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/753325/pexels-photo-753325.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
];

const getScraperData = async (url: string) => {
  if (isUrl(url)) {
    try {
      const response = await getData('ScrapeAPI', `?URL=${btoa(url)}`);
      if (response.status === 'success' && response.queryResponse) {
        const scrapedImage = response.queryResponse;

        return {
          title: shortenString(scrapedImage.title, 46) || '',
          description: shortenString(scrapedImage.description, 277) || '',
          images: scrapedImage.imageSrc || scraperMockAPIData,
          error:
            scrapedImage.imageSrc === null
              ? 'Hey, looks like we cant retrieve any images from that link, why not choose from stock imagery shown?'
              : '',
        };
      }
    } catch (e) {
      switch (e.response.status) {
        case 500:
          return { error: 'Hey, looks like we cant retrieve any images from that link, why not choose from stock imagery shown?' };
        case 404:
          return { error: "Hey, looks like there aren't any images on that link, why not choose from stock imagery shown?" };
        default:
          return { error: "We couldn't find any images on that URL. You can choose from some stock images below:" };
      }
    }
  }
  return { error: "We couldn't find any images on that URL. You can choose from some stock images below:" };
};

const reformatCollectionItems = (collectionItems: Item[]) => {
  return collectionItems.map((collectionItem: Item) => {
    if (collectionItem.presignedThumbnail) {
      return { ...collectionItem, imageUrl: b64DecodeUnicode(collectionItem.presignedThumbnail) };
    }
    if (collectionItem.thumbnail) {
      return { ...collectionItem, imageUrl: collectionItem.thumbnail };
    }
    return { ...collectionItem, imageUrl: '' };
  });
};

const processItems = (items: Item[]) => {
  return items.map((item: Item) => {
    const localItem = item;
    if (localItem.thumbnail) {
      localItem.thumbnail = b64EncodeUnicode(localItem.thumbnail);
    }
    localItem.link = b64EncodeUnicode(localItem.link);
    localItem.name = b64EncodeUnicode(localItem.name);
    localItem.description = b64EncodeUnicode(localItem.description);

    if (localItem.presignedThumbnail !== '') {
      localItem.presignedThumbnail = '';
    }

    return localItem;
  });
};

const addItem = async (data: { collection: Collection; item: Item }) => {
  // Create a deep copy of the items to avoid mutating the original
  const deepCopy = cloneDeep(data);

  const { collection, item } = deepCopy;

  // If the curent collection has no items set the first one up.
  if (!collection.items) {
    collection.items = [item];
  } else {
    // If the item already exists then we are editing the item so remove the old one.
    collection.items = collection.items.filter((element: Item) => element.id !== item.id);

    // Add the item to the collection
    // Retain items current order if the data is there
    collection.items.unshift({ ...item, order: item.order === 0 ? collection.items.length : item.order });
  }

  // URLEncode all URLS and remove presigned as we don't need it (i.e. stop the WAF blocking everyhting)
  processItems(collection.items);

  // Reformat collection items so new items images work straight away
  const updatedData = await postData('LibraryAPI', 'store', collection).then((res) => {
    return {
      ...res,
      queryResponse: {
        ...res.queryResponse,
        Collection: {
          ...res.queryResponse.Collection,
          items: res.queryResponse.Collection.items ? reformatCollectionItems(res.queryResponse.Collection.items) : [],
        },
      },
    };
  });

  return updatedData;
};

const saveCollection = async (collection: Collection) => {
  processItems(collection.items ?? []);

  const response = await postData('LibraryAPI', 'store', collection);
  return response;
};

const getCollectionData = async (collectionID: string) => {
  try {
    const response = await getData('LibraryAPI', `loadcollection?collectionId=${collectionID}`);
    if (response.status !== statusResponseOK || response.message !== loadLibraryResponse) {
      throw new Error(`response status - ${response.status} or query response type - ${response.queryResponseType} does not match`);
    }
    return response.queryResponse;
  } catch (error) {
    setSentryScopeFatalLibrary(error);
    return error;
  }
};

const reformatCollections = (collections: Collection[]) => {
  return collections.map((collection: Collection) => {
    if (collection.items) {
      const reformattedItems = reformatCollectionItems(collection.items);
      return { ...collection, items: reformattedItems };
    }
    return { ...collection, items: [] };
  });
};

const getCollections = async () => {
  const response = await getData('LibraryAPI', 'loadlibrary');

  if (response.status !== statusResponseOK || response.message !== libraryLoadReponse) {
    throw new Error(`response status - ${response.status} or query response type - ${response.queryResponseType} does not match`);
  }

  return response.queryResponse;
};

const orderLibrary = async (order: ItemOrdering, isShared: boolean) => {
  const response = await postData('LibraryAPI', 'orderlibrary', {
    order,
    isShared,
  });

  if (response.status !== statusResponseOK) {
    throw new Error(`Ordering the library has failed`);
  }
  return response.queryResponse;
};

const addCollection = (collection: Collection, callback: () => void) => {
  return postData('LibraryAPI', 'store', collection)
    .then(() => {
      if (callback) callback();
    })
    .catch((error) => {
      setSentryScopeFatalLibrary(error);
      return error;
    });
};

const filterSharedCollections = (collections: Collection[]) => {
  return {
    shared: collections.filter((collection: Collection) => collection.shared),
    standard: collections.filter((collection: Collection) => !collection.shared),
  };
};

const loadLibrary = async () => {
  try {
    const collections = await getCollections();

    const formattedCollections = reformatCollections(collections);

    return filterSharedCollections(formattedCollections);
  } catch (error) {
    setSentryScopeFatalLibrary(error);
    return error;
  }
};

const loadLibraryCollectionData = async (collectionID: string) => {
  try {
    // Get the collection data using the collectionID
    const res = await getCollectionData(collectionID);
    if (res instanceof Error) {
      throw new Error('getCollectionData has thrown an Error');
    }

    const data = {
      loadPhase: LibraryCollectionLoadPhaseLoaded,
      collection: {
        ...res.collection,
        sharedCollections: res.sharedCollections,
        items: res.collection.items ? reformatCollectionItems(res.collection.items) : [],
      },
    };

    return data;
  } catch (error) {
    setSentryScopeFatalLibrary(error);
    return error;
  }
};

const storeStat = (collectionId: string, item: Item) => {
  const data = {
    collectionId,
    itemId: item.id,
  };

  postData('LibraryAPI', 'storestats', data);
};

// Edit an item
const editItem = async (item: Item) => {
  return postData('LibraryAPI', 'edititem', item);
};

const deleteItem = async (itemID: string, collection: Collection) => {
  const filteredItems = collection.items ? collection.items.filter((element: Item) => element.id !== itemID) : [];

  // Create a deep copy of the items to avoid mutating the original
  const deepCopy = cloneDeep(filteredItems);

  const toSave = {
    ...collection,
    items: processItems(deepCopy),
  };

  const res = await postData('LibraryAPI', 'store', toSave);

  return {
    ...res.queryResponse?.Collection,
    sharedCollections: res.queryResponse?.SharedCollection,
    items: res.queryResponse?.Collection.items ? reformatCollectionItems(res.queryResponse?.Collection.items) : [],
  };
};

// Edit a collection
const editCollection = (collection: Collection, callback: () => void) => {
  // If we are editing a collection then there might be items.  For each item we need to make sure that we URL encode any URLs.
  if (collection.items) processItems(collection.items);

  return addCollection(collection, callback);
};

const shareCollection = (collection: Collection, userIds: string[], callback: () => void) => {
  const shareData = {
    collectionId: collection.id,
    sharedUserIds: userIds,
  };

  postData('LibraryAPI', 'share', shareData)
    .then(() => {
      if (callback) callback();
    })
    .catch((error) => {
      setSentryScopeFatalLibrary(error);
      return error;
    });
};

const deleteCollections = (collectionIDs: string[], callback: () => void) => {
  const safeCollectionIDs = collectionIDs.map((id) => encodeURIComponent(id));
  const collectionParameter = safeCollectionIDs.join('&collectionId=');

  deleteData('LibraryAPI', `delete?collectionId=${collectionParameter}`)
    .then(() => {
      if (callback) callback();
    })
    .catch((error) => {
      setSentryScopeFatalLibrary(error);
      return error;
    });
};

// Create a publicly accessible copy of the collection, visible to everybody outside an organisation.
const generateExternalSharedCollectionURL = (collectionID: string) => {
  return postData('LibraryAPI', `collection/${collectionID}/external`);
};

// Remove the publicly accessible copy of the collection.
// The collection will still exist, but only be visible to those within the organisation.
const disconnectExternalSharedCollection = (collectionID: string) => {
  return deleteData('LibraryAPI', `collection/${collectionID}/external`);
};

export {
  deleteItem,
  storeStat,
  loadLibraryCollectionData,
  addItem,
  editItem,
  getCollectionData,
  loadLibrary,
  addCollection,
  editCollection,
  shareCollection,
  deleteCollections,
  orderLibrary,
  saveCollection,
  generateExternalSharedCollectionURL,
  disconnectExternalSharedCollection,
  getScraperData,
  reformatCollectionItems,
};
