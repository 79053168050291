import IconType from './type';

const SVG = ({ style = {}, fill = '', width = '12', className = '', viewBox = '0 0 270.1 274.7' }: IconType) => (
  <svg
    id='Capa_1'
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <path
        fill={fill}
        d='M248.5,140.7h-97.5c-11.9,0-21.6-9.7-21.6-21.6V21.6C129.4,9.7,139,0,150.9,0h97.5c11.9,0,21.6,9.7,21.6,21.6v97.5
		C270.1,131,260.4,140.7,248.5,140.7z M150.9,20c-0.9,0-1.6,0.7-1.6,1.6v97.5c0,0.9,0.7,1.6,1.6,1.6h97.5c0.9,0,1.6-0.7,1.6-1.6
		V21.6c0-0.9-0.7-1.6-1.6-1.6H150.9z'
      />
    </g>
    <g>
      <path
        fill={fill}
        d='M76,274.7H21.6C9.7,274.7,0,265,0,253.1V21.6C0,9.7,9.7,0,21.6,0H76c11.9,0,21.6,9.7,21.6,21.6v231.5
		C97.6,265,87.9,274.7,76,274.7z M21.6,20c-0.9,0-1.6,0.7-1.6,1.6v231.5c0,0.9,0.7,1.6,1.6,1.6H76c0.9,0,1.6-0.7,1.6-1.6V21.6
		c0-0.9-0.7-1.6-1.6-1.6H21.6z'
      />
    </g>
    <g>
      <path
        fill={fill}
        d='M248.5,274.7h-97.5c-11.9,0-21.6-9.7-21.6-21.6v-60.6c0-11.9,9.7-21.6,21.6-21.6h97.5c11.9,0,21.6,9.7,21.6,21.6v60.6
		C270.1,265,260.4,274.7,248.5,274.7z M150.9,190.9c-0.9,0-1.6,0.7-1.6,1.6v60.6c0,0.9,0.7,1.6,1.6,1.6h97.5c0.9,0,1.6-0.7,1.6-1.6
		v-60.6c0-0.9-0.7-1.6-1.6-1.6H150.9z'
      />
    </g>
  </svg>
);

export default SVG;
