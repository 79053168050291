import styled from 'styled-components';
import { useState } from 'react';
import ImagePlaceholder from '../../UI/ImagePlaceholder/ImagePlaceholder';
import FailedImage from '../../Tiles/CollectionTile/FailedImage';
import { Item } from '../../../types/library';
import { gradientOne } from '../../../theming/mixins';

interface StyledItemCardThumbnailProps {
  isLoaded: boolean;
  item: Item;
}

const StyledItemCardThumbnail = styled.figure<StyledItemCardThumbnailProps>`
  width: 120px;
  height: 120px;
  margin-right: 16.5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  background: var(--grey-6);
  transition: ${(props) => props?.theme?.transitions?.default};
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--grey4);
  @media only screen and (max-width: ${(props) => props?.theme?.breakpoints?.mobile}) {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
  }

  > img {
    max-height: 100%;
    min-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    object-fit: cover;
    transform: translateY(-50%) translateX(-50%);
    opacity: 0;
    filter: blur(5px);
    transition: ${(props) => props?.theme?.transitions?.default};
  }

  ${(props) =>
    props.isLoaded &&
    `
      background: transparent;

      > img {
        opacity: 1;
        filter: blur(0);
      }
  `}

  ${(props) => !props.item.colour && props.item.imageUrl === '' && gradientOne}
  ${(props) => props.item.colour && props.item.imageUrl === '' && `background-color: ${props.item.colour}`}
`;

interface ItemCardThumbnailProps {
  item: Item;
  isVisible: boolean;
}

const ItemCardThumbnail = ({ item, isVisible }: ItemCardThumbnailProps) => {
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const handleError = () => {
    setImageIsLoaded(true);
    setImageLoadFailed(true);
  };

  const handleSuccess = () => {
    setImageIsLoaded(true);
    setImageLoadFailed(false);
  };
  return (
    <StyledItemCardThumbnail isLoaded={imageIsLoaded} item={item}>
      {item.imageUrl && isVisible && (
        <>
          <ImagePlaceholder active={!imageIsLoaded} />
          <img onError={() => handleError()} onLoad={() => handleSuccess()} alt={item.name} src={item.imageUrl} />
          {imageLoadFailed && <FailedImage name={item.name || ''} />}
        </>
      )}
    </StyledItemCardThumbnail>
  );
};

export default ItemCardThumbnail;
