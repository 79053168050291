import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import CardActionsButton from './CardActionsButton';
import CardActionsToggle from './CardActionsToggle';
import CardActionsButtons from './CardActionsButtons';

export interface Action {
  onClick: () => void;
  text: string;
  icon: string;
}

export interface CardActionsProps {
  actions: Action[];
  position?: 'left' | 'right';
  classNameOptions?: string;
  classNameMenu?: string;
  shared?: boolean;
  fixedColour?: boolean;
  colorMode?: 'light' | 'dark';
}

const CardActions = ({ position = 'left', classNameOptions, classNameMenu, actions, fixedColour = false, colorMode = 'light' }: CardActionsProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Closing menus when there's an outside click
  const menuRef = useRef<HTMLDivElement>(null);
  const closeRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (closeRef.current && closeRef.current.contains(e.target as Node)) {
        return;
      }
      if (isMenuOpen && menuRef.current && menuRef.current.contains(e.target as Node)) {
        return;
      }
      // outside click
      setMenuOpen(false);
    },
    [isMenuOpen],
  );

  const toggleMenu = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return (
    <>
      {actions.length > 0 && (
        <CardActionsToggle
          fixedColour={fixedColour}
          toggleMenu={toggleMenu}
          isMenuOpen={isMenuOpen}
          refVal={closeRef}
          classNameOptions={classNameOptions}
          colorMode={colorMode}
        />
      )}

      <AnimatePresence>
        {isMenuOpen && (
          <CardActionsButtons innerRef={menuRef} position={position} classNameMenu={classNameMenu}>
            {actions.map((action: Action, idx: number) => (
              <CardActionsButton onClick={action.onClick} text={action.text} icon={action.icon} i={idx} colorMode={colorMode} key={action.icon} />
            ))}
          </CardActionsButtons>
        )}
      </AnimatePresence>
    </>
  );
};

export default CardActions;
