import IconType from './type';

const SVG = ({ style = {}, width = '32', height = '32', className = '', viewBox = '0 0 21.675 21.672' }: IconType) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      d='M21.344,19.673l-5.025-5.025.261-.384a8.767,8.767,0,0,0,1.6-5.148,8.931,8.931,0,0,0-.715-3.527,9.03,9.03,0,0,0-1.936-2.9,9.151,9.151,0,0,0-12.87,0A9.153,9.153,0,0,0,0,9.116a8.931,8.931,0,0,0,.715,3.527,8.9,8.9,0,0,0,4.841,4.841,8.968,8.968,0,0,0,3.534.715,8.877,8.877,0,0,0,5.148-1.6l.376-.261,5.033,5.025a1.069,1.069,0,0,0,.83.346,1.134,1.134,0,0,0,.845-.361,1.2,1.2,0,0,0,.023-1.675ZM15.782,9.116a6.447,6.447,0,0,1-1.967,4.725,6.459,6.459,0,0,1-4.725,1.967,6.447,6.447,0,0,1-4.725-1.967A6.464,6.464,0,0,1,2.4,9.116,6.482,6.482,0,0,1,4.364,4.391,6.489,6.489,0,0,1,9.089,2.424a6.459,6.459,0,0,1,4.725,1.967A6.472,6.472,0,0,1,15.782,9.116Z'
      transform='translate(0 -0.037)'
    />
  </svg>
);

export default SVG;
